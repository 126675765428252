import React, {useContext, useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import useAPI from 'utils/useAPI';
import {useNavigate, useLocation} from 'react-router-dom';
import {AuthContext} from '../Auth/AuthContext';
import {Permissions} from '../Auth/Permissions';
import {getConfig} from 'config';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';

import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody.js';
import {Snackbar, Alert, TextField, InputLabel, MenuItem, FormControl, Select} from "@mui/material"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DateTimePicker } from '@mui/x-date-pickers';

import LocationService from 'services/LocationService';
import ReservationService from 'services/ReservationService';
import UserService from 'services/UserService';
import UserAutocomplete from '../User/UserAutocomplete';

import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';
import Button from "../../components/CustomButtons/Button"

const customStyles = {
  ...styles,
  title: {
    ...styles.cardIconTitle,
    fontSize: '16pt',
    marginBottom: 25,
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '15px',
  },
  fieldItem: {
    alignSelf: 'flex-start'
  },
  fieldRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  }
}
const useStyles = makeStyles(customStyles);
const externalIdlabel = getConfig().reservationExternalIdLabel;

export default function ReservationForm({id = null}) {
  const api = useAPI();
  const navigate = useNavigate();
  const contextLocation = useLocation();
  const classes = useStyles();
  const { hasPermission } = useContext(AuthContext);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [location, setLocation] = useState(null);
  const [reservation, setReservation] = useState({
    status: 'CONFIRMED',
    startTimeObj: null,
    endTimeObj: null,
    external_id: '',
  });
  const originalReservation = useRef();
  const [saveDisabled, setSaveDisabled] = useState(false);

  useEffect(() => {
    if (!hasPermission(Permissions.VIEW_RESERVATIONS)) {
      navigate('/');
    }
    LocationService.init(api);
    ReservationService.init(api);
    UserService.init(api);
    const location = contextLocation?.state?.location;
    if (!location) {
      navigate('/admin/reservations');
    }
    setLocation(location);
    if (id) {
      fetchReservation();
    }
  }, [api]);

  const fetchReservation = async () => {
    try {
      const res = await ReservationService.fetchReservation(id);
      const transformed = transformReservation(res);
      setReservation(transformed);
      originalReservation.current = {...transformed};
    } catch (err) {
      alert(err);
    }
  }

  const transformReservation = (r) => {
    return {
      ...r,
      startTimeObj: moment(r.start_time),
      endTimeObj: moment(r.end_time),
    }
  }

  const areReservationsEqual = (res1, res2) => {
    return res1.external_id === res2.external_id &&
      res1.status === res2.status &&
      res1.startTimeObj.isSame(res2.startTimeObj) &&
      res1.endTimeObj.isSame(res2.endTimeObj) &&
      res1.carousel_id === res2.carousel_id &&
      res1.user_id === res2.user_id;
  }

  const reservationIsValid = () => {
    return reservation
      && !!reservation.user_id
      && !!reservation.startTimeObj
      && !!reservation.endTimeObj;
  }

  useEffect(() => {
    const originalReservationIsUnchanged = originalReservation.current
      && areReservationsEqual(reservation, originalReservation.current);
    setSaveDisabled(originalReservationIsUnchanged || !reservationIsValid());
  }, [reservation]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarMessage(null);
    setSnackbarSeverity('success');
  };

  const handleCancel = () => {
    navigate('/admin/reservations');
  }

  const handleSave = async () => {
    const { user_id, carousel_id, external_id, startTimeObj, endTimeObj, status } = reservation;
    const reservationRequest = {
      ...(id && { reservationId: id }),
      userId: user_id,
      locationId: location.id,
      carouselId: carousel_id,
      externalId: external_id,
      status,
      checkInDtm: startTimeObj.format(),
      checkOutDtm: endTimeObj.format(),
    }
    console.log('Saving:', reservationRequest);
    try {
      await ReservationService.upsertReservation(reservationRequest);
      setSnackbarMessage( `Reservation ${id ? 'updated' : 'added'}`);
    } catch (err) {
      setSnackbarSeverity('error');
      setSnackbarMessage(err);
    }
  }

  return (location &&
    <GridItem xs={12} sm={12} md={12} style={{maxWidth: '800px'}}>
      <Snackbar open={!!snackbarMessage} autoHideDuration={6000} onClose={handleCloseSnackbar}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{width: '100%'}}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <CalendarMonthIcon/>
          </CardIcon>
          <div className={classes.title}>{id ? 'Edit' : 'Add'} Reservation ({location.name})</div>
        </CardHeader>
        <CardBody>
          <form>
            <div className={classes.fieldContainer}>
              {id &&
                <div className={classes.fieldItem}>
                  <TextField
                    id='reservation-id'
                    label='Reservation ID'
                    variant='outlined'
                    margin='normal'
                    disabled
                    value={id}
                  />
                </div>
              }
              {reservation &&
                <div className={classes.fieldRow}>
                  <div className={classes.fieldItem}>
                    <DateTimePicker
                      id='reservation-start-time'
                      label='Start Time*'
                      value={reservation.startTimeObj}
                      maxDateTime={reservation.endTimeObj}
                      onChange={(value) => {
                        setReservation((current) => {
                          return {
                            ...current,
                            startTimeObj: value,
                          }
                        })
                      }}
                    />
                  </div>
                  <div className={classes.fieldItem}>
                    <DateTimePicker
                      id='reservation-end-time'
                      label='End Time*'
                      value={reservation.endTimeObj}
                      minDateTime={reservation.startTimeObj}
                      onChange={(value) => {
                        setReservation((current) => {
                          return {
                            ...current,
                            endTimeObj: value,
                          }
                        })
                      }}
                    />
                  </div>
                </div>
              }
              <div className={classes.fieldItem} style={{width: '100%'}}>
                <UserAutocomplete
                  initialUser={reservation?.user || null}
                  onUserSelected={(user) => {
                    setReservation((current) => {
                      return {
                        ...current,
                        user_id: user.id,
                      }
                    })
                  }}
                  onUserCleared={() => {
                    setReservation((current) => {
                      return {
                        ...current,
                        user_id: null,
                      }
                    })
                  }}
                />
              </div>
              {location.carousels && location.carousels.length > 0 &&
                <FormControl className={classes.fieldItem} style={{minWidth: 400}}>
                  <InputLabel id="reservation-carousel-label">Carousel</InputLabel>
                  <Select
                    labelId="reservation-carousel-label"
                    id="reservation-carousel-select"
                    value={reservation.carousel_id || ''}
                    label="Carousel"
                    onChange={(event) => {
                      setReservation((current) => {
                        return {
                          ...current,
                          carousel_id: event.target.value,
                        }
                      })
                    }}
                  >
                    {location.carousels.filter(c => !!c.is_reservation_only).map(c => {
                      return <MenuItem key={c.id} value={c.id}>{c.nickname}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              }
              <FormControl className={classes.fieldItem} style={{minWidth: 200}}>
                <InputLabel id="reservation-status-label">Status</InputLabel>
                <Select
                  labelId="reservation-status-label"
                  id="reservation-status-select"
                  value={reservation.status}
                  label="Status"
                  onChange={(event) => {
                    setReservation((current) => {
                      return {
                        ...current,
                        status: event.target.value,
                      }
                    })
                  }}
                >
                  <MenuItem key={1} value={'CONFIRMED'}>Confirmed</MenuItem>
                  <MenuItem key={2} value={'CANCELED'}>Canceled</MenuItem>
                </Select>
              </FormControl>
              <div className={classes.fieldItem} style={{minWidth: 400}}>
                <TextField
                  id='reservation-external-id'
                  className={classes.formField}
                  label={externalIdlabel}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  value={reservation?.external_id || ''}
                  onChange={(event) => {
                    setReservation((current) => {
                      return {
                        ...current,
                        external_id: event.target.value,
                      }
                    })
                  }}
                />
              </div>
            </div>
            <div className={classes.formCategory}>
              <small>*</small> Required fields
            </div>
            <Button color='danger' onClick={handleCancel} className={classes.registerButton}>
              Cancel
            </Button>
            <Button color='success' onClick={handleSave} className={classes.registerButton} disabled={saveDisabled}>
              {id ? 'Update' : 'Add'} Reservation
            </Button>

          </form>
        </CardBody>
      </Card>
    </GridItem>
  );
}

ReservationForm.propTypes = {
  id: PropTypes.number,
};
