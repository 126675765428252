import React from 'react';
import MUIDataTable from 'mui-datatables';

import makeStyles from '@mui/styles/makeStyles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
import SubscriptionBookingService from 'services/SubscriptionBookingService';
import useAPI from 'utils/useAPI';
import buttons from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';
import tableStyles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import BookIcon from '@mui/icons-material/Book';
import Typography from '@mui/material/Typography';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const api = useAPI();
  let params = useParams();
  const [data, setData] = React.useState(null);
  const [subscriptionBooking, setSubscriptionBooking] = React.useState(null);
  const fetchSubscriptionHistory = async () => {
    let details = await SubscriptionBookingService.getSubscriptionBookingDetail(params.id);
    setSubscriptionBooking(details);

    SubscriptionBookingService.getBookingHistory(params.id)
      .then((res) => {
        let d = transform(res.results);
        setData(d);
      })
      .catch();
  };
  React.useEffect(() => {
    SubscriptionBookingService.init(api);
    fetchSubscriptionHistory();
  }, [api]);

  const classes = useStyles();

  function transform(session) {
    let bookingHistory = session;
    console.log('sesese', session);
    bookingHistory = bookingHistory.map((ses) => {
      return {
        id: ses.id,
        start_time: (ses.start_time && moment(ses.start_time).format('MM/DD/YYYY hh:mm A')) || 'NA',
        end_time: (ses.end_time && moment(ses.end_time).format('MM/DD/YYYY hh:mm A')) || 'NA',
        duration: ses.duration != null ? ses.duration : 'NA',
        status: String(ses.status).toUpperCase() + ' ',
      };
    });
    return bookingHistory;
  }

  const columns = [
    {
      label: 'Start Time',
      name: 'start_time',
    },
    {
      label: 'End Time',
      name: 'end_time',
    },
    {
      label: 'Duration (Minutes)',
      name: 'duration',
    },
    {
      label: 'Status',
      name: 'status',
    },
  ];
  const options = {
    filter: false, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
  };
  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <h4 className={classes.cardIconTitle}>
              {' '}
              <b>
                User:{' '}
                {subscriptionBooking && subscriptionBooking.user && subscriptionBooking.user.name}
              </b>
            </h4>
            <h4 className={classes.cardIconTitle}>
              <b>Vehicle: {subscriptionBooking && subscriptionBooking.vehicle_name}</b>
            </h4>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color='rose'>
                    <BookIcon style={{ color: 'white' }} />{' '}
                  </CardIcon>
                  <Typography variant='h6'>SUBSCRIPTION BOOKINGS</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
            />
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
}
