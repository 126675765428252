/*eslint-disable*/
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
// core components
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import { useNavigate } from 'react-router-dom';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import UserService from 'services/UserService';
import CarouselService from 'services/CarouselService';
import VehicleService from 'services/VehicleService';
import PinService from 'services/PinService';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// style for this view
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';
import useAPI from 'utils/useAPI';
const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const numberRegex = new RegExp('^[0-9]+$');

function PinForm({ id = null }) {
  const [pinLabel] = React.useState(id ? 'Update PIN' : 'Add PIN');
  const [pin, setPin] = React.useState({
    carousel_id: '',
    plc_pin: '',
  });

  const api = useAPI();
  const navigate = useNavigate();
  const [carousel, setCarousel] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [errors, setErrors] = React.useState({
    carousel_id: '',
    plc_pin: '',
  });

  const fetchPin = async () => {
    await PinService.getPin(id).then((res) => {
      const respPin = [];
      Object.keys(res).forEach((key) => {
        respPin[key] = res[key] ? res[key] : '';
      });
      setPin(respPin);
    });
  };

  const fetchCarousels = async () => {
    await CarouselService.getCarousels(10, 1, '').then((res) => {
      setCarousel(res.results);
    });
  };

  React.useEffect(() => {
    UserService.init(api);
    VehicleService.init(api);
    PinService.init(api);
    CarouselService.init(api);
    fetchCarousels().then(() => {
      // Waiting for carousel before fetching pin so that select component options fetched before its value
      if (id) {
        fetchPin();
      }
    });
  }, [api]);

  const verifyNumber = (value) => {
    return numberRegex.test(value);
  };

  const handleChange = (prop, val) => {
    setPin({ ...pin, [prop]: val });
    if (verifyLength(val, 1)) {
      setErrors({ ...errors, [prop]: 'success' });
    } else {
      setErrors({ ...errors, [prop]: 'error' });
    }
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    return value.length >= length;
  };

  const handleExistPin = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const registerClick = async () => {
    let error = 0;
    let newErrors = errors;
    ['carousel_id', 'plc_pin'].forEach((field) => {
      if (pin[field] === '') {
        newErrors[field] = 'error';
        error = 1;
      }
    });

    ['plc_pin'].forEach((field) => {
      if (!verifyNumber(pin[field])) {
        newErrors[field] = 'error';
        error = 1;
      }
    });

    setErrors({ ...newErrors });
    if (error) return;

    let res;
    if (id) {
      res = await PinService.updatePin(pin);
    } else {
      res = await PinService.storePin(pin);
    }
    if (!res) handleExistPin();
    else navigate('/admin/pins');
  };

  const handleCancel = async () => {
    navigate('/admin/pins');
  };
  const classes = useStyles();
  return (
    <GridContainer>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='warning'>
          PLC PIN is already registered , please choose another
        </Alert>
      </Snackbar>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <RoomOutlinedIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{pinLabel}</h4>
          </CardHeader>
          <CardBody>
            <form>
              <FormControl fullWidth margin='dense' className={classes.selectFormControl}>
                <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                  Choose carousel *
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={pin.carousel_id}
                  onChange={(e) => {
                    handleChange('carousel_id', e.target.value);
                  }}
                  inputProps={{
                    name: 'simpleSelect',
                    id: 'simple-select',
                  }}
                  label='Choose carousel'
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose carousel *
                  </MenuItem>

                  {carousel &&
                    carousel.map((carousel, index) => (
                      <MenuItem
                        value={`${carousel.id}`}
                        key={`${carousel.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {`${carousel.nickname} - ${carousel.ip_address}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                error={errors.plc_pin === 'error'}
                margin='dense'
                id='plcpin'
                label='PLC PIN *'
                type='text'
                disabled={!!id}
                fullWidth
                value={pin.plc_pin}
                onChange={(event) => {
                  if (verifyNumber(event.target.value)) {
                    setErrors({ ...errors, plc_pin: 'success' });
                  } else {
                    setErrors({ ...errors, plc_pin: 'error' });
                  }
                  handleChange('plc_pin', event.target.value);
                }}
              />

              <div className={classes.formCategory}>
                <small>*</small> Required fields
              </div>

              <Button color='danger' onClick={handleCancel} className={classes.registerButton}>
                Cancel
              </Button>
              <Button color='success' onClick={registerClick} className={classes.registerButton}>
                {pinLabel}
              </Button>

            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

PinForm.propTypes = {
  id: PropTypes.string,
};

export default PinForm;
