import React, {useContext, useState, useEffect} from 'react';
import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react';
import {Routes, Route} from 'react-router-dom';
import {useIdleTimer} from 'react-idle-timer'
import {getConfig} from './config';
import Loading from './components/Loading';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AdminLayout from './layouts/Admin.js';
import {ConfirmProvider} from "material-ui-confirm";
import {AuthProvider, AuthContext} from './views/Auth/AuthContext';
import Screensaver from './views/screensaver/Screensaver';

import PinLogin from './components/PinLogin/PinLogin';
import {PinContext} from './components/PinLogin/PinContext';
import ErrorPage from "./views/Pages/ErrorPage"
import PropTypes from "prop-types"

const {kioskMode, valetScreensaverOnIdleSecs} = getConfig();

const ProtectedPage = ({component}) => {
  ProtectedPage.propTypes = {
    component: PropTypes.any.isRequired,
  };

  const {loading, hasPermission} = useContext(AuthContext);
  return loading ? <Loading/> : hasPermission('access_admin_app') ? component : <ErrorPage/>;
};


const ProtectedAdminLayout =
  kioskMode ? () => <ProtectedPage component={<AdminLayout/>}/>
    : withAuthenticationRequired(() => (<ProtectedPage component={<AdminLayout/>}/>
    ));

const App = () => {
  let {isLoading, error} = useAuth0();
  const {isAuthorized} = useContext(PinContext);
  const [showScreensaver, setShowScreensaver] = useState(false);

  useEffect(() => {
    if (kioskMode) {
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        console.log('Disabling zoom on kiosk');
        viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, shrink-to-fit=no');
      }
    }
  }, []);

  const onIdle = () => {
    if (valetScreensaverOnIdleSecs > 0) {
      setShowScreensaver(true);
    }
  }

  const onActive = () => {
    if (showScreensaver) {
      setShowScreensaver(false);
    }
  }

  if (kioskMode && valetScreensaverOnIdleSecs > 0) {
    useIdleTimer({onIdle, onActive, timeout: valetScreensaverOnIdleSecs * 1000});
  }

  if (showScreensaver) {
    return <div style={{width: '100%', height: '100%', backgroundColor: 'black', position: 'fixed'}}>
      <Screensaver/>
    </div>
  }

  if (kioskMode && !isAuthorized) {
    return <AuthProvider>
      <PinLogin/>
    </AuthProvider>
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (!kioskMode && isLoading) {
    return <Loading/>;
  }

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: orange,
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <AuthProvider>
              <Routes>
                <Route path='*' element={<ProtectedAdminLayout/>}/>
              </Routes>
            </AuthProvider>
          </LocalizationProvider>
        </ConfirmProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
