import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Alert, Autocomplete, FormControl, Grid, Snackbar, TextField, Typography} from '@mui/material';
import CardIcon from "components/Card/CardIcon"
import Card from 'components/Card/Card';
import CardHeader from "components/Card/CardHeader"
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button"
import Loading from 'components/Loading';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import UserService from '../../services/UserService';
import LocationService from '../../services/LocationService';
import MembershipService from '../../services/MembershipService';
import ParkingQueueService from '../../services/ParkingQueueService';
import VehicleService from '../../services/VehicleService';
import {getVehicleLabelText} from '../Vehicle/VehicleUtils';
import {itemCount, toUSNationalPhoneNumber} from '../../utils/Utils';
import useAPI from 'utils/useAPI';
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';
import makeStyles from '@mui/styles/makeStyles';
import { AuthContext } from '../Auth/AuthContext';
import { Permissions } from '../Auth/Permissions';
import { useMediaQuery, useTheme } from '@mui/material';

const useStyles = makeStyles(styles);

function ParkingQueueForm({locationId = null}) {
  const classes = useStyles();
  const api = useAPI();
  const { hasPermission } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [queueLocation, setQueueLocation] = useState(null);

  const [userFirstMode, setUserFirstMode] = useState(true);
  const [userInput, setUserInput] = useState('');
  const [userOptions, setUserOptions] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userInputLabel, setUserInputLabel] = useState('Search');

  const [planData, setPlanData] = useState(null);
  const [vehicleInput, setVehicleInput] = useState('');
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [vehiclesLoading, setVehiclesLoading] = useState(false);
  const [vehicleError, setVehicleError] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [vehicleInputLabel, setVehicleInputLabel] = useState('Search');

  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [saveEnabled, setSaveEnabled] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!hasPermission(Permissions.QUEUE_VEHICLE)) {
      navigate('/');
    }
  }, [])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarMessage(null);
  };

  const getLocation = async () => {
    try {
      const loc = await LocationService.getLocation(locationId)
      setQueueLocation(loc);
    } catch (err) {
      alert(err);
    }
  }

  useEffect(() => {
    if (queueLocation) {
      setLoading(false);
    }
  }, [queueLocation]);

  useEffect(() => {
    UserService.init(api);
    LocationService.init(api);
    MembershipService.init(api);
    ParkingQueueService.init(api);
    VehicleService.init(api);
    if (location?.state?.location) {
      if (locationId !== location.state.location.id) {
        console.error('locationId does not match location from navigation state!', locationId, location.state.location);
        locationId = location.state.location.id;
      }
      setQueueLocation(location.state.location);
    } else {
      getLocation();
    }
  }, [api]);

  const userTransformer = (user) => {
    let label = `${user.name} | ${user.email}`;
    if (user.phone) {
      label += ` | ${toUSNationalPhoneNumber(user.phone)}`;
    }
    return {
      ...user,
      label,
    }
  }

  const vehicleTransformer = (v) => {
    let available = true;
    let label = getVehicleLabelText(v);//` ${v.color} ${v.year || ''} ${v.make} ${v.model}`;
    if (!v.is_approved) {
      available = false;
      label += ' (not approved)';
    }
    const isParked = v.parking_sessions.length > 0;
    if (v.parking_queue?.location_id) {
      available = false;
      if (locationId === v.parking_queue?.location_id) {
        label = label + ` (already queued)`;
      } else {
        label = label + ` (queued at ${v.parking_queue?.location.name})`;
      }
    } else if (isParked) {
      const parkingSession = v.parking_sessions[0];
      const vehicleLocationId = parkingSession.location_id;
      const vehicleLocationName = parkingSession.location.name;
      if (locationId !== vehicleLocationId) {
        available = false;
        label = label + ` (parked at ${vehicleLocationName})`
      } else if (parkingSession?.carousel_id) {
        const carouselName = parkingSession.carousel.nickname;
        label = 'RETRIEVE: ' + label + `from ${carouselName}`;
      } else {
        const spotName = parkingSession.parking_spot.name;
        const zoneName = parkingSession.parking_spot?.parking_zone?.name;
        const lotName = parkingSession.parking_spot.parking_lot.name;
        label = 'RETRIEVE: ' + label + ` from ${lotName}${zoneName ? `:${zoneName}` : ''}:${spotName}`;
      }
    } else {
      label = 'PARK: ' + label;
    }
    return {
      ...v,
      label,
      isParked,
      available,
    }
  }

  const fetchUserOptionsForUserInput = async () => {
    const response = await UserService.getUsers({
      search: userInput.trim(),
      filters: [],
      orderBy: null,
      orderDirection: null,
      page: 1,
      pageSize: 50,
    });
    setUserOptions(response.results.map(userTransformer));
    setUsersLoading(false);
  };

  const fetchVehicleOptionsForSelectedUser = async () => {
    const response = await ParkingQueueService.getGroupVehiclesWithSessions(selectedUser.group_id);
    setVehicleOptions(response.map(vehicleTransformer));
    setVehicleInputLabel('Select');
  }

  const fetchVehicleOptionsForVehicleInput = async () => {
    const response = await VehicleService.searchVehiclesWithSessions(50, 1, vehicleInput.trim());
    let transformed = response.results.map(vehicleTransformer);
    setVehicleOptions(transformed);
    setVehiclesLoading(false);
  };

  const fetchUserOptionsForSelectedVehicle = async () => {
    const users = selectedVehicle.user_group?.users || [];
    let userOpts = users.map(userTransformer)
    setUserOptions(userOpts);
    setUserInputLabel('Select');
    if (userOpts.length === 1) {
      setSelectedUser(userOpts[0]);
    }
  }

  const clearForms = () => {
    setSelectedUser(null);
    setUserOptions([]);
    setPlanData(null);
    setSelectedVehicle(null);
    setVehicleOptions([]);
    setVehicleError(null);
    setUserInputLabel('Search');
    setVehicleInputLabel('Search');
  }

  const handleClearUser = () => {
    setSelectedUser(null);
  }

  const handleClearVehicle = () => {
    setSelectedVehicle(null);
  }

  useEffect(() => {
    if (!userFirstMode) {
      setUserFirstMode(true);
    }
    if (userInput.trim().length > 2) {
      setUsersLoading(true);
      fetchUserOptionsForUserInput();
    } else {
      clearForms();
    }
  }, [userInput]);

  useEffect(() => {
    if (userFirstMode) {
      setUserFirstMode(false);
    }
    if (vehicleInput.trim().length > 2) {
      setVehiclesLoading(true);
      fetchVehicleOptionsForVehicleInput();
    } else {
      clearForms();
    }
  }, [vehicleInput]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const planData = await MembershipService.getPlansForLocation(selectedUser.group_id, locationId);
        setPlanData(planData);
      } catch (err) {
        alert(err);
      }
    }

    if (selectedUser) {
      fetchPlans();
    }
  }, [selectedUser]);

  useEffect(() => {
    if (userFirstMode && selectedUser) {
      fetchVehicleOptionsForSelectedUser();
    }
  }, [planData]);

  useEffect(() => {
    if (selectedVehicle && !userFirstMode) {
      fetchUserOptionsForSelectedVehicle();
    }
  }, [selectedVehicle]);

  useEffect(() => {
    setSaveEnabled(selectedUser && selectedVehicle);
  }, [selectedUser, selectedVehicle]);

  const handleCancel = async () => {
    navigate('/admin/parking-queue', { state: { locationId } });
  };

  const handleAddToQueue = async () => {
    try {
      const res = await ParkingQueueService.queueVehicle(queueLocation.id, selectedVehicle.id, selectedUser.id);
      if (!res.success) {
        setSnackbarMessage(res.message);
      } else {
        navigate('/admin/parking-queue', {state: {locationId}});
      }
    } catch (err) {
      alert(err);
    }
  };

  const handleAddUser = () => {
    navigate('/users/add');
  }

  const handleEditPlan = () => {
    if (selectedUser) {
      navigate(`/groups/edit/${selectedUser.group_id}`);
    }
  }

  if (loading) {
    return <Loading/>
  }

  return (
    <GridItem xs={12} sm={12} md={12} maxWidth={'1000px'}>
      <Snackbar open={!!snackbarMessage} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <FormatListNumberedIcon />
          </CardIcon>
          <div className={classes.cardIconTitle} style={{fontSize: 20}}>Queue Vehicle ({queueLocation.name})</div>
        </CardHeader>
        <CardBody>
          <form>
            <Grid container alignItems='center' spacing={2} marginTop={1} marginBottom={1} maxWidth={'800px'}>
              <Grid item xs={2} sm={2} style={{maxWidth: '100%', flexBasis: 'unset'}}>
                <Typography variant='body1' style={{fontSize: '16pt', fontWeight: 400, flexBasis: 'unset'}}>Select User:</Typography>
              </Grid>
              <Grid item xs={12} sm={10} style={{paddingTop: isMobile ? 0 : '16px'}}>
                <Autocomplete
                  value={selectedUser}
                  onChange={(event, newValue, reason) => {
                    if ('clear' === reason) {
                      handleClearUser();
                    } else {
                      setSelectedUser(newValue);
                    }
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(user) => user.label}
                  options={userOptions}
                  loading={usersLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={userInputLabel}
                      variant="outlined"
                      onChange={(e) => setUserInput(e.target.value)}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {planData ?
              <>
                <Grid container alignItems="center" spacing={2} maxWidth="800px">
                  <Grid item xs={2}></Grid>
                  <Grid item xs>
                    <Typography variant='body1' style={{fontSize: '14pt', fontWeight: 400}}>
                      {itemCount(planData.numVehiclesQueuedOrParked, 'vehicle', 'vehicles', true)} queued or parked
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} maxWidth="800px">
                  <Grid item xs>
                    <Button onClick={handleEditPlan} className={classes.registerButton}>
                      Edit User
                    </Button>
                  </Grid>
                </Grid>
              </> :
              <>
                <Grid container alignItems="center" spacing={2} maxWidth="800px">
                  <Grid item xs>
                    <Button onClick={handleAddUser} className={classes.registerButton}>
                      Add User
                    </Button>
                  </Grid>
                </Grid>
              </>
            }
            <Grid container alignItems='center' spacing={2} marginTop={1} maxWidth={'800px'}>
              <Grid item xs={2} sm={2} style={{maxWidth: '100%', flexBasis: 'unset'}}>
                <Typography variant='body1' style={{fontSize: '16pt', fontWeight: 400, flexBasis: 'unset'}}>Select Vehicle:</Typography>
              </Grid>
              <Grid item xs={12} sm={10} style={{paddingTop: isMobile ? 0 : '16px'}}>
                <FormControl fullWidth margin='dense' className={classes.formControl}>
                  <Autocomplete
                      value={selectedVehicle}
                      onChange={(event, newValue, reason) => {
                        if ('clear' === reason) {
                          handleClearVehicle();
                        } else {
                          setSelectedVehicle(newValue);
                        }
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(vehicle) => vehicle?.label || ''}
                      options={vehicleOptions}
                      getOptionDisabled={(v => !v.available)}
                      loading={vehiclesLoading}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              label={vehicleInputLabel}
                              variant="outlined"
                              error={Boolean(vehicleError)}
                              helperText={vehicleError || null}
                              onChange={(e) => setVehicleInput(e.target.value)}
                          />
                      )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2} marginTop={2} maxWidth="800px">
              <Grid item xs>
                <Button color='danger' onClick={handleCancel} className={classes.registerButton}>
                  Cancel
                </Button>
                <Button color='success' onClick={handleAddToQueue} className={classes.registerButton}
                        disabled={!saveEnabled}>
                  Add to Queue
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardBody>
      </Card>
    </GridItem>
  );
}

ParkingQueueForm.propTypes = {
  locationId: PropTypes.number,
};

export default ParkingQueueForm;
