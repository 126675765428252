import PropTypes from 'prop-types'
import {Dialog, DialogActions, DialogContent, DialogTitle, Slide} from '@mui/material'
import Button from 'components/CustomButtons/Button'
import React, {forwardRef, memo} from 'react'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle.js';
import makeStyles from '@mui/styles/makeStyles'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const modalStyles = makeStyles(modalStyle);

function DialogTemplate(
  {
    isOpen,
    closeFn,
    title,
    content,
    actionText,
    actionFn,
    actionsDisabled,
    noTransition = false,
    hideBackdrop = false,
    showCancel = true,
    cancelFn,
  }) {
  DialogTemplate.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeFn: PropTypes.func,
    title: PropTypes.any,
    content: PropTypes.any,
    actionText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    actionFn: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.arrayOf(PropTypes.func),
    ]),
    actionsDisabled: PropTypes.bool,
    noTransition: PropTypes.bool,
    hideBackdrop: PropTypes.bool,
    showCancel: PropTypes.bool,
    cancelFn: PropTypes.func,
  }

  cancelFn = cancelFn || closeFn;
  if (actionText && typeof actionText === 'string') {
    actionText = [actionText];
    actionFn = [actionFn];
  }

  const modalClasses = modalStyles();

  return <Dialog
    classes={{
      root: modalClasses.left,
      paper: modalClasses.modal,
    }}
    open={isOpen}
    TransitionComponent={noTransition ? undefined : Transition}
    hideBackdrop={hideBackdrop}
    onClose={closeFn}
    aria-labelledby='modal-slide-title'
    aria-describedby='modal-slide-description'
  >
    <DialogTitle id='classic-modal-slide-title'>
      <span style={{display: 'block', textAlign: 'center', lineHeight: '1.5em'}}>{title}</span>
    </DialogTitle>
    <DialogContent id='modal-slide-description' className={modalClasses.modalBody} style={{fontSize: 20, textAlign: 'center', lineHeight: '1.5em'}}>
      {content}
    </DialogContent>
    <DialogActions className={modalClasses.modalFooter + ' ' + modalClasses.modalFooterCenter}>
      {
        actionText && actionFn && actionText.map((text, index) => (
          <Button
            key={index}
            onClick={actionFn[index]}
            disabled={actionsDisabled}
            color='primary'
            style={{marginLeft: '5px'}}
          >
            {text}
          </Button>
        ))
      }
      {showCancel &&
        <Button
          onClick={cancelFn}
        >
          Cancel
        </Button>
      }
    </DialogActions>
  </Dialog>
}

export default memo(DialogTemplate);
