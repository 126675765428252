/* eslint-disable react/display-name */

import React from 'react';

import useAPI from 'utils/useAPI';
import NotificationService from 'services/NotificationService';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import Loading from 'components/Loading';
import moment from 'moment';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';

export const Notifications = () => {
  const api = useAPI();
  const [unread, setUnread] = React.useState([]);
  const [read, setRead] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const fetchNotifications = async () => {
    NotificationService.getNotifications().then((res) => {
      const read = res.filter((notification) => notification.read);
      const unRead = res.filter((notification) => !notification.read);
      // setNotifications(res.data);
      setLoading(false);
      setUnread(unRead);
      setRead(read);
    });
  };

  const getTime = (notificationDateTime) => {
    const dtm = moment(notificationDateTime);
    return `${dtm.format('hh:mm A')} on ${dtm.format('MM/DD/YYYY')} (${dtm.fromNow()})`;
  };

  const handleOnDelete = async (id) => {
    const readFiltered = read.filter((notification) => notification.id !== id);
    setRead(readFiltered);
    NotificationService.deleteNotification(id).then(() => {
      fetchNotifications();
    });
  };

  const handleMarkAsRead = async (id) => {
    const notificationItem = unread.filter((notification) => notification.id === id);
    if (notificationItem.length) {
      const readedItems = notificationItem.map((item) => {
        item.read = 1;
        return item;
      });
      read.push(readedItems[0]);
      setRead(read);
    }
    const unReadFiltered = unread.filter((notification) => notification.id !== id);
    setUnread(unReadFiltered);
    await NotificationService.markAsRead(id).then(() => {
      fetchNotifications();
    });
  };

  React.useEffect(() => {
    NotificationService.init(api);
    fetchNotifications();
  }, [api]);

  const renderUnRead = (notification) => {
    return (
      <ListItem
        divider
        key={notification.id}
        style={{
          backgroundColor: '#d5ddeb',
          borderRadius: 5,
          marginBottom: 10,
        }}
      >
        <ListItemText
          primary={notification.title}
          primaryTypographyProps={{ style: { color: '#111', fontSize: 12 } }}
          secondary={
            <React.Fragment>
              <p style={{ fontSize: 11 }}>{notification.description}</p>
              <p style={{ fontSize: 10 }}>{getTime(notification.created_at)}</p>
            </React.Fragment>
          }
          secondaryTypographyProps={{ component: 'div' }}
        />
        <ListItemSecondaryAction>
          <IconButton
            edge='end'
            aria-label='show'
            onClick={() => handleMarkAsRead(notification.id)}
            size='large'
          >
            <VisibilityOffIcon fontSize='small' />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderRead = (notification) => {
    return (
      <ListItem
        divider
        key={notification.id}
        // onClick={() => handleAction(notification.action)}
        style={{
          backgroundColor: '#ffffff',
          borderRadius: 5,
          marginBottom: 10,
        }}
      >
        <ListItemText
          primary={notification.title}
          primaryTypographyProps={{ style: { color: '#111', fontSize: 12 } }}
          secondary={
            <React.Fragment>
              <p style={{ fontSize: 11 }}>{notification.description}</p>
              <p style={{ fontSize: 10 }}>{getTime(notification.created_at)}</p>
            </React.Fragment>
          }
          secondaryTypographyProps={{ component: 'div' }}
        />
        <ListItemSecondaryAction>
          <IconButton
            edge='end'
            aria-label='show'
            onClick={() => handleOnDelete(notification.id)}
            size='large'
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <GridContainer justify='center'>
      <GridItem>
        <GridItem align='center' className='mb-2'>
          <Typography variant='h5' color='primary' style={{ color: '#394253' }}>
            Notifications
          </Typography>
        </GridItem>
        <GridItem align='center' className='mb-2'>
          <Typography variant='caption' color='primary' style={{ color: '#394253' }}>
            Unread ({unread.length})
          </Typography>
        </GridItem>
        <GridItem align='center' className='mb-2'>
          <List style={{ maxWidth: 450 }}>
            {unread.map((notification) => {
              return renderUnRead(notification);
            })}
          </List>
        </GridItem>
        <GridItem align='center' className='mb-2'>
          <Typography variant='caption' color='primary' style={{ color: '#394253' }}>
            Read ({read.length})
          </Typography>
        </GridItem>
        {(loading && <Loading />) || null}
        <GridItem align='center' className='mb-2'>
          <List style={{ maxWidth: 450 }}>
            {read.map((notification) => {
              return renderRead(notification);
            })}
          </List>
        </GridItem>
      </GridItem>
    </GridContainer>
  );
};

export default Notifications;
