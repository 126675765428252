import React, {useContext} from 'react';

import makeStyles from '@mui/styles/makeStyles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
import CameraIcon from '@mui/icons-material/Camera';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.js';
import CameraService from 'services/CameraService';
import useAPI from 'utils/useAPI';
import buttons from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';
import {useNavigate} from 'react-router-dom';
import tableStyles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import MaterialTable, {MTableToolbar} from 'material-table';
import {IconButton, Paper, Typography} from "@mui/material"
import { Rnd } from "react-rnd";
import CloseIcon from '@mui/icons-material/Close';
import { Modules, isModuleDisabled } from 'config';
import {AuthContext} from '../Auth/AuthContext';
import {Permissions} from '../Auth/Permissions';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const api = useAPI();
  const classes = useStyles();
  const navigate = useNavigate();
  const tableRef = React.createRef();
  const [data, setData] = React.useState([]);
  const [openCameraId, setOpenCameraId] = React.useState(null);
  const [openCameraName, setOpenCameraName] = React.useState('');
  const [cameraStreamToken, setCameraStreamToken] = React.useState(null);
  const { hasPermission } = useContext(AuthContext);

  function transformCameras(data) {
    return data.map((camera) => {
      return {
        id: camera.id,
        name: camera.name,
        url: camera.url,
        location: camera.location.name,
        carousel: camera.carousel?.nickname,
      };
    });
  }

  async function fetchData() {
    const res = await CameraService.getCameras();
    if (res) {
      setData(transformCameras(res));
    }
  }

  React.useEffect(() => {
    if (!hasPermission(Permissions.VIEW_CAMERAS) || isModuleDisabled(Modules.Cameras)) {
      navigate('/');
    }
    CameraService.init(api);
    fetchData();
  }, [api]);

  const closeCameraWindow = () => {
    if (openCameraId) {
      CameraService.closeCamera();
      setOpenCameraId(null);
      setOpenCameraName('');
      setCameraStreamToken(null);
    }
  }

  const toggleCameraWindow = (cameraId, name) => {
    if (openCameraId) {
      closeCameraWindow();
    } else {
      CameraService.getCameraStreamToken().then((token) => {
        console.log('Camera stream token:', token);
        setCameraStreamToken(token);
        setOpenCameraName(name);
        setOpenCameraId(cameraId);
      }).catch((err) => {
        console.error(err);
      })
    }
  }

  const actions = [
    (rowData) => ({
      icon: () => (openCameraId === rowData.id) ? <CloseIcon /> :
        (openCameraId ? <SmartDisplayIcon style={{cursor: 'not-allowed'}} color={'disabled'}/> : <SmartDisplayIcon color={'primary'}/>),
      iconProps: { fontSize: 'large' },
      tooltip: (openCameraId === rowData.id) ? 'Close Camera' : (openCameraId ? '' : 'Open Camera'),
      onClick: (openCameraId && openCameraId !== rowData.id) ? null : (event, rowData) => {
        toggleCameraWindow(rowData.id, rowData.name);
      },
    }),
  ]

  if (hasPermission(Permissions.SYSTEM_ADMINISTRATION)) {
    actions.push(    {
        icon: 'add',
        tooltip: 'Add Camera',
        isFreeAction: true,
        onClick: () => {
          navigate('/cameras/add');
        },
      },
      {
        icon: 'edit',
        iconProps: { fontSize: 'small' },
        tooltip: 'Update Camera',
        onClick: (event, rowData) => {
          navigate(`/cameras/edit/${rowData.id}`);
        },
      },
      (rowData) => ({
        icon: 'delete',
        iconProps: { fontSize: 'small', style: { color: 'red' } },
        tooltip: 'Delete Camera',
        onClick: async () => {
          if (confirm(`Are you sure you want to remove ${rowData.name}?`)) {
            await CameraService.deleteCamera(rowData.id);
            fetchData();
          }
        }
      })
    )
  }

  const aspectRatio = 0.65;
  const cameraDefaultWidth = 1100;
  const cameraDefaultHeight = cameraDefaultWidth * aspectRatio;
  const maxShrink = 0.4;
  const maxGrow = 1;

  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      {openCameraId && (
        <Rnd
          style={{ zIndex: 100 }}
          default={{
            width: cameraDefaultWidth,
            height: cameraDefaultHeight,
            x: 100,
            y: 0,
          }}
          minWidth={cameraDefaultWidth * maxShrink}
          minHeight={cameraDefaultHeight * maxShrink}
          maxWidth={cameraDefaultWidth * maxGrow}
          maxHeight={cameraDefaultHeight * maxGrow}
          enableResizing={{
            top: false,
            right: false,
            bottom: false,
            left: false,
            topRight: true,
            bottomRight: true,
            bottomLeft: true,
            topLeft: true
          }}
          lockAspectRatio={true}
        >
          <Paper style={{ padding: "0.2em", position: "relative", backgroundColor: 'lightgray', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="h6"
              component="h2"
              align="center"
              style={{ marginBottom: '-20px' }}
            >
              {openCameraName}
            </Typography>
            <IconButton onClick={closeCameraWindow} onTouchEnd={closeCameraWindow} style={{ position: 'absolute', right: 0 }}>
              <CloseIcon />
            </IconButton>
            <img src={CameraService.getCameraUrl(openCameraId, cameraStreamToken)} alt="camera"
                 style={{ maxWidth: '100%', flexGrow: 1, width: '100%', objectFit: 'contain' }}
                 draggable="false"
                 onDragStart={e => e.preventDefault()}
            />
          </Paper>
        </Rnd>
      )}
      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title='Cameras'
            tableRef={tableRef}
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'URL', field: 'url' },
              { title: 'Location', field: 'location' },
              { title: 'Carousel', field: 'carousel' },
            ]}
            data={data}
            actions={actions}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: 'center', paddingRight: '0%' }}>
                  <CardHeader color='primary' icon>
                    <CardIcon color='rose'>
                      <CameraIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              actionsColumnIndex: 0,
              debounceInterval: 500,
              sorting: true,
              pageSize: 10,
              headerStyle: { padding: '16px' },
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
