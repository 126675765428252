import Axios from 'axios';
import {convertApiError} from '../utils/Utils';

let api = Axios;

const init = (axiosApi) => {
  api = axiosApi;
};

const getUsers = async (query) => {
  try {
    // POST needed to send query in body
    const response = await api.post('/admin/users', query);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const getUser = async (id) => {
  try {
    const response = await api.get(`/admin/users/${id}`);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const updateUser = async (data) => {
  try {
    const response = await api.put('/admin/user', data);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const getGroups = async (query) => {
  try {
    // POST needed to send query in body
    const response = await api.post('/admin/groups', query);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const getGroupWithVehicles = async (id) => {
  try {
    const response = await api.get(`/admin/groups/${id}`);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const getGroupWithUsers = async (id) => {
  try {
    const response = await api.get(`/admin/groups/${id}/users`);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const updateGroupName = async (data) => {
  try {
    const response = await api.put(`/admin/groups/${data.id}`, {name: data.name});
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const addUserToGroup = async (userId, groupId) => {
  try {
    const response = await api.put(`/admin/groups/${groupId}/users/${userId}`);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const deleteUserFromGroup = async (userId, groupId) => {
  try {
    const response = await api.delete(`/admin/groups/${groupId}/users/${userId}`);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const getUserSubscribedPlans = async (id) => {
  try {
    const response = await api.get(`/admin/user-plans/${id}`);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const getRoles = async () => {
  try {
    const response = await api.get(`/admin/roles`);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const clearFlag = async (userId) => {
  try {
    const response = await api.put(`/admin/user/${userId}/clear-flag`, {});
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const UserService = {
  init,
  getUsers,
  getUser,
  updateUser,
  getRoles,
  getGroups,
  getGroupWithVehicles,
  getGroupWithUsers,
  updateGroupName,
  addUserToGroup,
  deleteUserFromGroup,
  getUserSubscribedPlans,
  clearFlag,
};

export default UserService;
