import Axios from 'axios';
const Utils = require('../utils/Utils');

let api = Axios;
const init = async (axiosApi) => {
  api = axiosApi;
};

const uploadVehiclePhoto = async (blob, vehicleId, description) => {
  try {
    const formData = new FormData();
    // Create YYYYMMDD_HHMMSSmmm timestamp for filename
    const timestamp = Utils.getReadableNumericTimestamp();
    const filename = `vehicle.${vehicleId}.${timestamp}.jpg`;
    formData.append('file', blob, filename);
    formData.append('description', description);
    const response = await api.post(`/admin/photos/vehicles/${vehicleId}`, formData);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getVehiclePhotos = async (vehicleId) => {
  try {
    const response = await api.get(`/photos/vehicles/${vehicleId}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const PhotoService = {
  init,
  uploadVehiclePhoto,
  getVehiclePhotos,
};

export default PhotoService;
