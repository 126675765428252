import Axios from 'axios';
const Utils = require('../utils/Utils');

let api = Axios;
const init = async (axiosApi) => {
  api = axiosApi;
};

const getQRReaders = async () => {
  try {
    const response = await api.get('/admin/qr-reader');
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getQRReader = async (id) => {
  try {
    const response = await api.get(`/admin/qr-reader/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const addQRReader = async (data) => {
  try {
    const response = await api.post(`/admin/qr-reader`, data);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const deleteQRReader = async (id) => {
  try {
    const response = await api.delete(`/admin/qr-reader/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const updateQRReader = async (data) => {
  try {
    const response = await api.put(`/admin/qr-reader/${data.id}`, data);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const QRReaderService = {
  init,
  getQRReaders,
  getQRReader,
  addQRReader,
  updateQRReader,
  deleteQRReader,
};

export default QRReaderService;
