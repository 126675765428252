import PropTypes from "prop-types"
import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from '@mui/material';
import { ParkingSpot } from '../SurfaceParking/ParkingSpot';

function PlanOption({ planOption, initialData, onDataChange, errors, disabled }) {
  const [data, setData] = useState(null);
  const [assignableSpots, setAssignableSpots] = useState(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const theData = cloneDeep(initialData);
    const spots = [];
    const assignments = [];
    if (planOption.allow_spot_assignments) {
      for (const parkingSpot of planOption.allSpots) {
        const aSpot = ParkingSpot(parkingSpot);
        const assignedSpot = initialData.assignedSpots ? initialData.assignedSpots.find((s) => s.id === aSpot.id) : null;
        if (assignedSpot) {
          aSpot.assigned = false; // assigned to this group, so allow it to be deselected/selected
          assignments.push(aSpot);
        }
        spots.push(aSpot);
      }
    }
    theData.assignedSpots = assignments;
    if (!('inclEV' in theData)) {
      theData.inclEV = false;
    }
    if (!('numVehicles' in theData)) {
      theData.numVehicles = '';
    }
    setAssignableSpots(spots);
    setData(theData);
  }, []);

  useEffect(() => {
    if (data && assignableSpots) {
      setInitialized(true);
    }
  }, [data, assignableSpots])

  useEffect(() => {
    if (initialized) {
      onDataChange(data);
    }
  }, [data]);

  const handleDelete = (itemToDelete, event) => {
    event.stopPropagation(); // Prevents the Select dropdown from opening
    setData((prev) => {
      return {
        ...prev,
        assignedSpots: prev.assignedSpots.filter((item) => item !== itemToDelete),
      }
    })
  };

  const updateAssignedSpots = (e) => {
    const spots = e.target.value;
    const idsOnly = spots.map((s) => {
      return {
        id: s.id,
        label: s.label,
      };
    });
    setData({
      ...data,
      assignedSpots: idsOnly,
    })
  }

  const setBoolean = (prop, b) => {
    setData((prev) => {
      if (prev[prop] === b) {
        return prev;
      }
      return {
        ...prev,
        [prop]: b,
      }
    })
  }

  const labelColor = data?.planSelected ? 'black' : 'rgba(0, 0, 0, 0.38)';
  if (!initialized) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" width="100%" marginTop={2} marginBottom={2}>

      {/* Checkbox with label */}
      <FormControlLabel
        control={
          <Checkbox
            checked={!!data.planSelected}
            onChange={(e) => setBoolean('planSelected', e.target.checked)}
            color="primary"
            disabled={!!disabled}
          />
        }
        label={
          <span style={{ color: labelColor }}>
            {planOption.name}
          </span>
        }
      />

      {`${planOption.availablePlans.ev} EV, ${planOption.availablePlans.nonEV} non-EV plans available`}
      <Box marginLeft="auto" display="flex" alignItems="center">
        {planOption.allow_spot_assignments ?
          <>
            <InputLabel style={{ marginRight: 10, color: labelColor }}>Assigned spaces (opt.) :</InputLabel>
            <Select
              multiple
              value={data.assignedSpots}
              disabled={!data.planSelected || !!disabled}
              style={{marginRight: 40, minWidth: 200}}
              onChange={updateAssignedSpots}
              renderValue={(selected) => (
                <Box display="flex" flexWrap="wrap">
                  {selected.map((value) => (
                    <Chip
                      key={value.id}
                      label={value.label}
                      onDelete={(event) => handleDelete(value, event)}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      style={{margin: 2}}
                    />
                  ))}
                </Box>
              )}
            >
              {assignableSpots.map((spot) => (
                <MenuItem key={spot.id} value={spot} disabled={spot.assigned || data.assignedSpots.some(as => as.id === spot.id)}>
                  {spot.label}&#160;
                  {spot.icons && spot.icons.map(icon => icon)}
                </MenuItem>
              ))}
            </Select>
          </> :
          <div> </div>
        }
        <FormControlLabel
          control={
            <Checkbox
              checked={!!data.inclEV}
              disabled={!data.planSelected || !!disabled}
              onChange={(e) => setBoolean('inclEV', e.target.checked)}
              color="primary"
            />
          }
          label={
            <span style={{ color: labelColor }}>
              Include EV charging
            </span>
          }
        />

        <Box display="flex" alignItems="center" px={2}>
          <InputLabel style={{ color: labelColor, marginRight: '10px' }}># Vehicles* :</InputLabel>
          <TextField
            variant="outlined"
            size="small"
            type="text"
            style={{width: 70}}
            value={data.numVehicles}
            aria-disabled={!data.planSelected}
            error={!!errors?.numVehicles}
            onChange={(e) => {
              if (/^[1-9][0-9]?$/.test(e.target.value) || e.target.value === '') {
                setData((prev) => {
                  return {
                    ...prev,
                    numVehicles: e.target.value,
                  }
                })
              }
            }}
            inputProps={{
              pattern: "^[1-9][0-9]?$",
              inputMode: "numeric"
            }}
            disabled={!data.planSelected || !!disabled}
          />
        </Box>

      </Box>
    </Box>
  );
}

PlanOption.propTypes = {
  planOption: PropTypes.object.isRequired,
  initialData: PropTypes.object.isRequired,
  onDataChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
};
export default PlanOption;
