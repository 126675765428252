/*eslint-disable*/
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import { Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RadioGroup from '@mui/material/RadioGroup';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
// core components
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import SubscriptionService from 'services/SubscriptionService';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LocationService from 'services/LocationService';
// style for this view
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';
import useAPI from 'utils/useAPI';
const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const vehicleSizeList = require('../../assets/json/VehicleTypes');
function SubscriptionForm({ id = null }) {
  const [isEdit] = React.useState(id ? true : false);
  const navigate = useNavigate();
  const [planLabel] = React.useState(id ? 'Update Subscription Plans' : 'Add Subscription Plans');
  const [render, setRender] = React.useState(null);
  const [plan, setPlan] = React.useState({
    plan_name: '',
    amount: '',
    duration: '1',
    notes: '',
    vehicle_type: vehicleSizeList.length === 1 ? vehicleSizeList[0].name : '',
    location_id: null,
    offer: null,
    status: null,
  });

  const api = useAPI();
  const [locationList, setLocationList] = React.useState([]);
  // const offer = [{ name: "Yes" }, { name: "No" }];
  const status = [{ name: 'Active' }, { name: 'Inactive' }];
  const offer = [
    { id: 1, name: 'Yes' },
    { id: 0, name: 'No' },
  ];

  const [errors, setErrors] = React.useState({
    plan_name: '',
    amount: '',
    duration: '',
    vehicle_type: '',
  });

  const fetchPlan = async () => {
    await SubscriptionService.getPlan(id).then((res) => {
      const respPin = [];
      Object.keys(res).forEach((key) => {
        respPin[key] = res[key] ? res[key] : '';
      });
      console.log('RRREEEESSSSSSSSS', respPin);
      setPlan(respPin);
      setRender(true);
    });
  };

  const fetchLocationList = async () => {
    await LocationService.getLocations().then((res) => {
      setLocationList(
        res.map((item) => {
          return {
            id: item.id,
            name: item.name + ' - ' + item.zipcode,
            value: item.id,
          };
        }),
      );
      if (res.length == 1) {
        setPlan({ ...plan, location_id: res[0].id });
      }
    });
  };

  React.useEffect(() => {
    SubscriptionService.init(api);
    LocationService.init(api);
    fetchLocationList();
    if (id) {
      fetchPlan();
    }
  }, [api]);

  const verifyNumber = (value) => {
    var numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  const handleChange = (prop, val) => {
    setPlan({ ...plan, [prop]: val });
    if (verifyLength(val, 1)) {
      setErrors({ ...errors, [prop]: 'success' });
    } else {
      setErrors({ ...errors, [prop]: 'error' });
    }
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const registerClick = async () => {
    let error = 0;
    let newErrors = errors;
    ['plan_name', 'amount', 'duration', 'vehicle_type'].forEach((field) => {
      if (plan[field] === '') {
        newErrors[field] = 'error';
        error = 1;
      }
    });

    setErrors({ ...newErrors });

    if (error) return;

    let res;
    if (id) {
      res = await SubscriptionService.updatePlan(plan);
    } else {
      res = await SubscriptionService.storePlan(plan);
    }
    navigate('/admin/subscription-plans');
  };
  const classes = useStyles();
  return (
    <GridContainer>
      {((id && render) || !id) && (
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='rose' icon>
              <CardIcon color='rose'>
                <UnsubscribeIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{planLabel}</h4>
            </CardHeader>
            <CardBody>
              <form>
                <TextField
                  error={errors.plan_name === 'error'}
                  margin='dense'
                  id='planName'
                  label='Plan Name *'
                  type='text'
                  fullWidth
                  value={plan.plan_name}
                  onChange={(event) => {
                    handleChange('plan_name', event.target.value);
                  }}
                />

                <TextField
                  error={errors.amount === 'error'}
                  margin='dense'
                  id='amount'
                  label='Amount *'
                  type='text'
                  fullWidth
                  value={plan.amount}
                  onChange={(event) => {
                    if (verifyNumber(event.target.value)) {
                      setErrors({ ...errors, amount: 'success' });
                    } else {
                      setErrors({ ...errors, amount: 'error' });
                    }
                    handleChange('amount', event.target.value);
                  }}
                />

                <TextField
                  error={errors.duration === 'error'}
                  margin='dense'
                  id='duration'
                  label='Duration In Month *'
                  type='text'
                  fullWidth
                  value={plan.duration}
                  onChange={(event) => {
                    if (verifyNumber(event.target.value)) {
                      setErrors({ ...errors, duration: 'success' });
                    } else {
                      setErrors({ ...errors, duration: 'error' });
                    }
                    handleChange('duration', event.target.value);
                  }}
                />

                <TextField
                  margin='dense'
                  id='notes'
                  label='Notes'
                  type='text'
                  fullWidth
                  multiline
                  rows={4}
                  value={plan.notes}
                  onChange={(event) => {
                    handleChange('notes', event.target.value);
                  }}
                />

                <FormControl fullWidth margin='dense' className={classes.formControl}>
                  <InputLabel id='vehicle_type'>Vehicle Type *</InputLabel>
                  <Select
                    error={errors.vehicle_type === 'error'}
                    onChange={(e) => {
                      handleChange('vehicle_type', e.target.value);
                    }}
                    labelId='vehicle_type'
                    id='vehicle_type'
                    fullWidth
                    value={plan.vehicle_type}
                    label='Vehicle Type *'
                  >
                    <MenuItem
                      disabled
                      value=''
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Vehicle Type
                    </MenuItem>
                    {vehicleSizeList &&
                      vehicleSizeList.map((item) => (
                        <MenuItem
                          value={`${item.name}`}
                          key={`${item.id}`}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                        >
                          {`${item.name}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <TextField
                  margin='dense'
                  id='notes'
                  label='Notes'
                  type='text'
                  fullWidth
                  value={plan.notes}
                  onChange={(event) => {
                    handleChange('notes', event.target.value);
                  }}
                />

                <FormControl fullWidth margin='dense' className={classes.selectFormControl}>
                  <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                    Choose Location
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={plan.location_id || ''}
                    onChange={(e) => {
                      handleChange('location_id', e.target.value);
                    }}
                    inputProps={{
                      name: 'simpleSelect',
                      id: 'simple-select',
                    }}
                    label='Choose Location'
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Location
                    </MenuItem>

                    {locationList.map((location, index) => (
                      <MenuItem
                        value={`${location.id}`}
                        key={`${location.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {`${location.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth margin='dense' className={classes.selectFormControl}>
                  <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                    Offer
                  </InputLabel>
                  <Select
                    value={plan.offer || ''}
                    onChange={(e) => {
                      handleChange('offer', e.target.value);
                    }}
                    error={errors.type === 'error'}
                    inputProps={{
                      name: 'simpleSelect',
                      id: 'simple-select',
                    }}
                    label='offer'
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose offer
                    </MenuItem>

                    {offer.map((offer) => (
                      <MenuItem
                        value={`${offer.id}`}
                        key={`${offer.name}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {`${offer.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth margin='dense' className={classes.selectFormControl}>
                  <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                    Status
                  </InputLabel>
                  <Select
                    value={plan.status || ''}
                    onChange={(e) => {
                      handleChange('status', e.target.value);
                    }}
                    error={errors.type === 'error'}
                    inputProps={{
                      name: 'simpleSelect',
                      id: 'simple-select',
                    }}
                    label='status'
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose status
                    </MenuItem>

                    {status.map((status) => (
                      <MenuItem
                        value={`${status.name}`}
                        key={`${status.name}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {`${status.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className={classes.formCategory}>
                  <small>*</small> Required fields
                </div>

                <Button color='rose' onClick={registerClick} className={classes.registerButton}>
                  {planLabel}
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      )}
    </GridContainer>
  );
}

SubscriptionForm.propTypes = {
  id: PropTypes.string,
};

export default SubscriptionForm;
