import Axios from 'axios';

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getTransactions = async (pageSize, page, search, orderBy, orderDirection) => {
  try {
    let cancel;
    const response = await api.get(
      '/admin/transactions',
      { params: { pageSize, page, search, orderBy, orderDirection } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getInvoiceLink = async (stripe_payment_intent_id) => {
  try {
    console.log('1111', stripe_payment_intent_id);
    let cancel;
    const response = await api.get(`/admin/stripe-invoice/${stripe_payment_intent_id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const UserService = {
  init,
  getTransactions,
  getInvoiceLink,
};

export default UserService;
