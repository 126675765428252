import React from "react"
import PropTypes from "prop-types";
import AccessibleSharpIcon from '@mui/icons-material/AccessibleSharp';
import BoltSharpIcon from '@mui/icons-material/BoltSharp';
import AirportShuttleSharpIcon from '@mui/icons-material/AirportShuttleSharp';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import DirectionsBikeSharpIcon from '@mui/icons-material/DirectionsBikeSharp';
import makeStyles from "@mui/styles/makeStyles"
const useStyles = makeStyles(() => ({
  horizAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  horizAlignCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export function ParkingSpotLabel({aSpot, centered = false}) {
  const classes = useStyles();
  if (!aSpot) {
    return null;
  }
  const spot = ParkingSpot(aSpot);
  return <div className={centered ? classes.horizAlignCentered : classes.horizAlign}>
    {spot.label}&#160;
    {spot.icons && spot.icons.map(icon => icon)}
  </div>;
}

ParkingSpotLabel.propTypes = {
  aSpot: PropTypes.object,
  centered: PropTypes.bool,
};

/**
 * Spots can have lot/zone or parking_lot/parking_zone keys
 */
const constructLabel = (spot) => {
  if (spot?.lot) {
    if (spot.lot?.name) {
      return spot?.zone ? `${spot.lot.name}: ${spot.zone.name}: ${spot.name} ` : `${spot.lot.name}: ${spot.name} `
    } else {
      return spot?.zone ? `${spot.lot}: ${spot.zone}: ${spot.name} ` : `${spot.lot}: ${spot.name} `
    }
  } else {
    return spot?.parking_zone ? `${spot.parking_lot.name}: ${spot.parking_zone.name}: ${spot.name} ` : `${spot.parking_lot.name}: ${spot.name} `
  }
}

export function ParkingSpot(spot) {
  const spotIcons = [];
  let iconId = 0;
  if (spot.is_accessible) {
    spotIcons.push(<AccessibleSharpIcon key={++iconId}/>);
  }
  if (spot.is_ev) {
    spotIcons.push(<BoltSharpIcon key={++iconId}/>);
  }
  if (spot.is_extra_wide) {
    spotIcons.push(<AirportShuttleSharpIcon key={++iconId}/>);
  }
  if (spot.is_covered) {
    spotIcons.push(<HomeSharpIcon key={++iconId}/>);
  }
  if (spot.is_bicycle) {
    spotIcons.push(<DirectionsBikeSharpIcon key={++iconId}/>);
  }
  return {
    ...spot,
    label: constructLabel(spot),
    icons: spotIcons,
  }
}

PropTypes.ParkingSpot = {
  spot: PropTypes.object.isRequired,
}

