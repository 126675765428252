import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import ParkingDisplayService from 'services/ParkingDisplayService';
import TvIcon from '@mui/icons-material/Tv';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import {useNavigate} from 'react-router-dom';
import useAPI from 'utils/useAPI';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import LocationService from 'services/LocationService';
import CarouselService from 'services/CarouselService';
import PropTypes from 'prop-types';

const useStyles = makeStyles(styles);

function ParkingDisplayForm({ id = null }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const api = useAPI();
  const [locationList, setLocationList] = React.useState([]);
  const [carouselList, setCarouselList] = React.useState([]);
  const [formData, setFormData] = React.useState({
    name: '',
    locationId: '',
    carouselId: '',
    ipAddr: '',
  });
  const [formErrors, setFormErrors] = React.useState({});

  const fetchParkingDisplay = async () => {
    const res = await ParkingDisplayService.getParkingDisplay(id);
    await fetchCarouselList(res.location_id);
    setFormData({
      id: res.id,
      name: res.name,
      locationId: res.location_id,
      carouselId: res.carousel_id,
      ipAddr: res.device_key,
    });
  };

  useEffect(() => {
    LocationService.init(api);
    CarouselService.init(api);
    ParkingDisplayService.init(api);
    fetchLocationList().then(() => {
      if (id) fetchParkingDisplay();
    });
  }, [api]);

  const fetchLocationList = async () => {
    const res = await LocationService.getLocations();
    setLocationList(
      res.map((item) => {
        return {
          id: item.id,
          name: item.name + ' - ' + item.zipcode,
          value: item.id,
        };
      }),
    );
    if (res.length === 1) {
      await setLocationId(res[0].id);
    }
  };

  const fetchCarouselList = async (location_id) => {
    await CarouselService.getLocationCarousels(location_id).then((res) => {
      setCarouselList(
        res.map((item) => {
          return {
            id: item.id,
            name: item.nickname,
            value: item.id,
          };
        }),
      );
    });
  };

  function validate() {
    const errors = {};
    if (!formData.name || formData.name === '') {
      errors.name = 'Name is required';
    }
    if (!formData.locationId || formData.locationId === '') {
      errors.locationId = 'Location is required';
    }
    if (!formData.carouselId || formData.carouselId === '') {
      errors.carouselId = 'Carousel is required';
    }
    if (!formData.ipAddr || formData.ipAddr === '') {
      errors.ipAddr = 'IP address is required';
    }
    return errors;
  }

  const handleChange = (prop, val) => {
    setFormData({ ...formData, [prop]: val });
  };

  const setLocationId = async (locationId) => {
    setFormData({ ...formData, locationId: Number(locationId) });
    await fetchCarouselList(locationId);
  };

  const handleSave = async () => {
    let errors = validate();
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (id) {
        await ParkingDisplayService.updateParkingDisplay(formData);
      } else {
        await ParkingDisplayService.addParkingDisplay(formData);
      }
      navigate(`/admin/parking-display`);
    }
  };

  const cancelRoute = () => {
    navigate(`/admin/parking-display`);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <TvIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{id ? 'Edit' : 'Add'} Parking Display</h4>
          </CardHeader>
          <CardBody>
            <form>
              <TextField
                type='text'
                id='name'
                label='Name'
                value={formData.name}
                error={!!formErrors.name}
                helperText={formErrors.name}
                autoFocus
                margin='dense'
                fullWidth
                onChange={(event) => {
                  handleChange('name', event.target.value);
                }}
              />
              <FormControl fullWidth margin='dense' className={classes.formControl} error={!!formErrors.locationId}>
                <InputLabel>Location</InputLabel>
                <Select
                  id='locationId'
                  label='Location'
                  value={formData.locationId}
                  fullWidth
                  onChange={(e) => {
                    setLocationId(e.target.value);
                  }}
                >
                  {locationList.map((location) => (
                    <MenuItem
                      value={`${location.id}`}
                      key={`${location.id}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${location.name}`}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.locationId && <FormHelperText>{formErrors.locationId}</FormHelperText>}
              </FormControl>
              <FormControl fullWidth margin='dense' className={classes.selectFormControl} error={!!formErrors.carouselId}>
                <InputLabel>Carousel</InputLabel>
                <Select
                  id='carouselId'
                  label='Carousel'
                  value={formData.carouselId}
                  fullWidth
                  onChange={(e) => {
                    handleChange('carouselId', e.target.value);
                  }}
                >
                  {carouselList.map((carousel) => (
                    <MenuItem
                      value={carousel.id}
                      key={carousel.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${carousel.name}`}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.carouselId && <FormHelperText>{formErrors.carouselId}</FormHelperText>}
              </FormControl>
              <FormControl fullWidth margin='dense' className={classes.selectFormControl} error={!!formErrors.ipAddr}>
                <TextField
                  type='text'
                  id='ipAddr'
                  label='IP Address'
                  value={formData.ipAddr}
                  margin='dense'
                  fullWidth
                  onChange={(event) => {
                    handleChange('ipAddr', event.target.value);
                  }}
                />
                {formErrors.ipAddr && <FormHelperText>{formErrors.ipAddr}</FormHelperText>}
              </FormControl>
              <Button
                color='success'
                onClick={handleSave}
              >
                Submit
              </Button>
              <Button color='danger' onClick={cancelRoute}>
                Cancel
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

ParkingDisplayForm.propTypes = {
  id: PropTypes.string,
};

export default ParkingDisplayForm;
