import React from 'react';
import ReactDOM from 'react-dom/client';
import PropTypes from 'prop-types';
import App from './App';
import { getConfig } from './config';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { PinProvider } from './components/PinLogin/PinContext';
import 'assets/scss/material-dashboard-pro-react.scss';
const config = getConfig();

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return config.kioskMode ? children : (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithRedirectCallback.propTypes = {
  children: PropTypes.any.isRequired,
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <PinProvider>
          <Auth0ProviderWithRedirectCallback
            domain={config.domain}
            clientId={config.clientId}
            cacheLocation='localstorage'
            authorizationParams={{
              audience: config.audience,
              scope: 'read:users',
              redirect_uri: window.location.origin,
            }}
          >
            <App />
          </Auth0ProviderWithRedirectCallback>
        </PinProvider>
    </BrowserRouter>
);
