import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
// @mui/icons-material
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.js';
import VehicleTable from "./VehicleTable"
import buttons from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';
import tableStyles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import PropTypes from "prop-types"

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function Vehicles({ user = null }) {
  const classes = useStyles();

  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <VehicleTable user={user}/>
    </GridContainer>
  );
}

Vehicles.propTypes = {
  user: PropTypes.object,
};
