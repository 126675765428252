import { title } from 'assets/jss/material-dashboard-pro-react.js';

const errorPageStyles = () => ({
  contentCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    padding: '0 15px',
  },
  title: {
    ...title,
    fontSize: '22vw',
    letterSpacing: '14px',
    fontWeight: '700',
  },
  subTitle: {
    fontSize: '5vw',
    marginTop: '0',
    marginBottom: '8px',
  },
  description: {
    fontSize: '1.125rem',
    marginTop: '0',
    marginBottom: '8px',
  },
});

export default errorPageStyles;
