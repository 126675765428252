import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types'
import Loading from '../../components/Loading';
import DialogTemplate from './DialogTemplate';
import WebcamCapture from '../Components/WebcamCapture';
import {getVehicleLabelText} from "../Vehicle/VehicleUtils"
import {Typography} from "@mui/material"
import PhotoService from "../../services/PhotoService"
import useAPI from '../../utils/useAPI';

export default function VehiclePhotoCaptureDialog({vehicle, onDone}) {

  const api = useAPI();
  const [photoUploading, setPhotoUploading] = useState(false);

  useEffect(() => {
    PhotoService.init(api);
  }, [api]);

  const photoUploadFn = (vehicle) => async ({blob, description}) => {
    try {
      setPhotoUploading(true);
      await PhotoService.uploadVehiclePhoto(blob, vehicle.id, description);
      setPhotoUploading(false);
      onDone();
    } catch (err) {
      setPhotoUploading(false);
      alert(err);
    }
  }

  return (<DialogTemplate
    isOpen={!!vehicle}
    closeFn={onDone}
    title={vehicle ? 'Photo of ' + getVehicleLabelText(vehicle) : ''}
    content={
      photoUploading ? <>
          <Loading/>
          <Typography variant='subtitle1'>Photo is uploading</Typography>
        </>
        : <WebcamCapture isOpen={true} uploadFn={photoUploadFn(vehicle)}/>
    }
    actionsDisabled={false} />);
}

VehiclePhotoCaptureDialog.propTypes = {
  vehicle: PropTypes.object,
  onDone: PropTypes.func.isRequired,
}
