import React, { useContext } from 'react';
import { PinContext } from '../../components/PinLogin/PinContext';
import {getConfig} from "config";
import { useNavigate } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/CustomButtons/Button.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/errorPageStyles.js';

const useStyles = makeStyles(styles);
const { kioskMode } = getConfig();

export default function ErrorPage() {
  const classes = useStyles();
  const { logout } = useAuth0();
  const  { logout: pinLogout } = useContext(PinContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    if (kioskMode) {
      pinLogout();
      navigate('/');
    } else {
      logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      });
    }
  };
  return (
    <div className={classes.contentCenter}>
      <h1 className={classes.title}>oops</h1>
      <h2 className={classes.subTitle}>Seems you do not have permission :(</h2>
      <Button color='warning' onClick={handleLogout}>
        Login Again
      </Button>
    </div>
  );
}
