import Axios from 'axios';

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getDiscounts = async (pageSize, page, search, orderBy, orderDirection) => {
  try {
    let cancel;
    const response = await api.get(
      '/admin/discount',
      { params: { pageSize, page, search, orderBy, orderDirection } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getDiscount = async (id) => {
  try {
    let cancel;
    const response = await api.get(`/admin/discount/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const addDiscount = async (data) => {
  const response = await api.post(`/admin/discount`, data, {
    cancelToken: new CancelToken((c) => {
      requests.push(c);
    }),
  });
  return response.data;
};

const updateDiscount = async (data) => {
  try {
    let cancel;
    const response = await api.put('/admin/discount', data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const DiscountService = {
  init,
  getDiscounts,
  getDiscount,
  addDiscount,
  updateDiscount,
};

export default DiscountService;
