import React, { useState, useEffect, useRef } from 'react';
import { getConfig } from 'config';
import Icon from './Icon';

export default function Screensaver() {
  const { screensaverText, logoColor,  } = getConfig();
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [velocity, setVelocity] = useState({ top: 0.5, left: 0.5 });
  const animationRef = useRef();

  const screensaverStyle = {
    position: 'absolute',
    width: '500px',
    height: 'auto',
    fontSize: '28pt',
    color: '#DDDDDD',
  };

  const adjustToBounds = (newTop, newLeft) => {
    const viewportWidth = window.innerWidth - 500;
    const viewportHeight = window.innerHeight - 250;

    if (newTop < 0) {
      newTop = 0;
      setVelocity(v => ({ ...v, top: Math.abs(v.top) }));
    } else if (newTop > viewportHeight) {
      newTop = viewportHeight;
      setVelocity(v => ({ ...v, top: -Math.abs(v.top) }));
    }

    if (newLeft < 0) {
      newLeft = 0;
      setVelocity(v => ({ ...v, left: Math.abs(v.left) }));
    } else if (newLeft > viewportWidth) {
      newLeft = viewportWidth;
      setVelocity(v => ({ ...v, left: -Math.abs(v.left) }));
    }

    return { top: newTop, left: newLeft };
  };

  const updatePosition = () => {
    let newTop = position.top + velocity.top;
    let newLeft = position.left + velocity.left;

    const adjustedPosition = adjustToBounds(newTop, newLeft);
    setPosition(adjustedPosition);
    animationRef.current = requestAnimationFrame(updatePosition);
  };

  useEffect(() => {
    const handleResize = () => {
      const adjustedPosition = adjustToBounds(position.top, position.left);
      setPosition(adjustedPosition);
    };

    window.addEventListener('resize', handleResize);
    animationRef.current = requestAnimationFrame(updatePosition);

    return () => {
      cancelAnimationFrame(animationRef.current);
      window.removeEventListener('resize', handleResize);
    };
  }, [position, velocity]);

  return (
    <div
      style={{
        ...screensaverStyle,
        top: `${position.top}px`,
        left: `${position.left}px`,
        textAlign: 'center'  // To center the text below the image
      }}
    >
      <Icon color={logoColor} />
      <div>{screensaverText}</div>
    </div>
  );
}
