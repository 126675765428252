import React, { useState, useEffect, useContext } from 'react';
import MUIDataTable from 'mui-datatables';
import makeStyles from '@mui/styles/makeStyles';
import useAPI from 'utils/useAPI';
import {useNavigate, useLocation} from 'react-router-dom';
import {useConfirm} from "material-ui-confirm";
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import {PatternFormat} from "react-number-format"
import moment from 'moment';
import {AuthContext} from '../Auth/AuthContext';
import { Permissions } from '../Auth/Permissions';
import UserService from 'services/UserService';
import { toUSNationalPhoneNumber } from '../../utils/Utils';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Grid from '@mui/material/Grid';
import GroupIcon from '@mui/icons-material/Group';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Block';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import WarningIcon from '@mui/icons-material/Warning';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CardIcon from 'components/Card/CardIcon.js';
import {Chip, IconButton, Tooltip, Typography} from '@mui/material';
import AddIcon from "@mui/icons-material/Add"
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  ...styles,
  chip: {
    margin: theme.spacing(0.5),
  },
  noUnderline: {
    textDecoration: 'none',
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 600,
      color: 'black',
    },
  },
}));

function wordCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)?.toLowerCase();
}

export default function Users() {
  const navigate = useNavigate();
  const location = useLocation();
  const api = useAPI();
  const confirm = useConfirm();
  const classes = useStyles();
  const [roles, setRoles] = useState([]);
  const [data, setData] = useState([]);
  const [showFlaggedModal, setShowFlaggedModal] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  const INITIAL_PARAMS = {
    pageSize: 50,
    page: 1,
    search: null,
    filters: null,
    orderBy: null,
    orderDirection: null
  }

  const [params, setParams] = useState(INITIAL_PARAMS);
  const [showGroups, setShowGroups] = useState(false);
  const { hasPermission, hasAnyPermission, isSameUser, isSameGroup, hasHigherPrivilegeLevel, isSuperuser } = useContext(AuthContext);

  const onClickVehicle = (vehicle, userId) => {
    if (userId) {
      navigate(`/users/${userId}/vehicles/edit/${vehicle.id}`);
    } else {
      navigate(`/groups/${vehicle.user_group_id}/vehicles/edit/${vehicle.id}`);
    }
  }

  const vehicleChip = (vehicle, idx, allowVehicleEdit, userId = null) => {
    if (vehicle.is_approved) {
      return allowVehicleEdit ?
        <Chip className={classes.chip} key={`${vehicle.make}-${idx}`} label={vehicle.make} m={2} size={'small'} color={'success'} onClick={() => onClickVehicle(vehicle, userId)}/> :
        <Chip className={classes.chip} key={`${vehicle.make}-${idx}`} label={vehicle.make} m={2} size={'small'} color={'success'}/>;
    } else {
      return allowVehicleEdit ?
        <Chip className={classes.chip} key={`${vehicle.make}-${idx}`} label={vehicle.make} m={2} size={'small'} icon={<WarningIcon/>} color={'warning'} onClick={() => onClickVehicle(vehicle, userId)}/> :
        <Chip className={classes.chip} key={`${vehicle.make}-${idx}`} label={vehicle.make} m={2} size={'small'} icon={<WarningIcon/>} color={'warning'}/>
    }
  }

  function transformGroups(groups) {
    return groups.map((group) => {
      const allowVehicleEdit = (isSameGroup(group.id) && hasPermission(Permissions.ADD_UPDATE_OWN_VEHICLE)) || hasPermission(Permissions.ADD_UPDATE_VEHICLES);
      const vehicleList = (
        <>
          {group.vehicles.map((v, idx) => vehicleChip(v, idx, allowVehicleEdit))}
        </>
      )
      const pVal = group.membership[0]?.plans;
      const plans = (pVal ?
        <>
          {group.membership[0].plans.map((p, idx) => <span key={idx}>
                 {p.plan_option.name} ({p.num_vehicles}, {p.incl_ev_charging ? 'EV' : 'non-EV'})
            {idx !== pVal.length - 1 && <br />}
               </span>)}
        </> : null
      )
      return {
        id: group.id,
        name: group.name,
        users: group.users.map(user => {
          return {
            id: user.id,
            name: user.name,
          }
        }),
        vehicles: vehicleList,
        plans,
      }
    });
  }

  function transformUsers(users) {
    return users.map((user) => {
      const allowVehicleEdit = (isSameUser(user.id) && hasPermission(Permissions.ADD_UPDATE_OWN_VEHICLE)) || hasPermission(Permissions.ADD_UPDATE_VEHICLES);
      const vehicleList = (
        <>
          {user.group.vehicles.map((v, idx) => vehicleChip(v, idx, allowVehicleEdit, user.id))}
        </>
      )
      return {
        id: user.id,
        groupId: user.group.id,
        name: user.name,
        phone: toUSNationalPhoneNumber(user.phone),
        email: (
          <>
            {user.email}
            {user.status !== 'ACTIVE' && (
              <Tooltip
                title='User not activated, Activate user to begin park session.'
                aria-label=''
              >
                {(hasPermission(Permissions.ADD_UPDATE_USERS) && (isSuperuser() || hasHigherPrivilegeLevel(user))) ?
                  (<IconButton
                    onClick={() => {
                      navigate('/users/edit/' + user.id);
                    }}
                    size='large'
                  >
                    <InfoIcon/>
                  </IconButton>) :
                  (<InfoIcon/>)
                }
              </Tooltip>
            )}
          </>
        ),
        role: user?.roles[0] ? user.roles[0].name : '',
        status: wordCase(user.status),
        picture: user.picture,
        vehicles: vehicleList,
        is_agree: user.is_agree ? 'Accepted' : 'Not Accepted',
        is_flagged: user.is_flagged,
        flagged_date: user.flagged_date,
        flagged_reason: user.flagged_reason,
        is_admin_sms_notifications: user.is_admin_sms_notifications,
        phone_verified: user.phone_verified,
      };
    });
  }

  const transformSortCol = (col) => col ? { field : col } : null

  const transformFilterCols = (tableState) => {
    return tableState.filterList.map(
      (filter, index) => filter.length > 0 ?
        { column: { field: tableState.columns[index].name }, value: filter }
        : null
    ).filter((filter) => filter);
  }

  useEffect(() => {
    UserService.init(api);
    fetchRoles();
    if (location.pathname.includes('groups')) {
      setShowGroups(true);
    }
  }, [api]);

  useEffect(() => {
    setParams(INITIAL_PARAMS);
    if (showGroups) {
      fetchGroups(INITIAL_PARAMS);
    } else {
      fetchUsers(INITIAL_PARAMS);
    }
  }, [showGroups]);

  const fetchRoles = async () => {
    try {
      const roles = await UserService.getRoles();
      setRoles(roles.map(r => r.name).sort());
    } catch (err) {
      alert(err);
    }
  }

  const fetchUsers = (params) => {
    UserService.getUsers(params)
      .then((res) => {
        setParams({
          ...params,
          page: res.page,
          count: res.total
        })
        let d = transformUsers(res.results);
        setData(d);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const fetchGroups = (params) => {
    UserService.getGroups(params)
      .then((res) => {
        setParams({
          ...params,
          page: res.page,
          count: res.total
        })
        let d = transformGroups(res.results);
        setData(d);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const canViewUser = () => {
    return hasPermission(Permissions.VIEW_USERS);
  }

  const canEditUser = (row) => {
    return isSuperuser() ||
      (hasPermission(Permissions.ADD_UPDATE_USERS) && hasHigherPrivilegeLevel(row)) ||
      (isSameUser(row.id) && (hasPermission(Permissions.UPDATE_OWN_USER) || hasPermission(Permissions.ADD_UPDATE_USERS))) ||
        hasPermission(Permissions.ADD_UPDATE_VEHICLES);
  }

  const canEditGroups = () => {
    return hasPermission(Permissions.ADD_UPDATE_USERS) || hasPermission(Permissions.ADD_UPDATE_VEHICLES);
  }

  const canAddUser = () => {
    return hasPermission(Permissions.ADD_UPDATE_USERS);
  }

  const userColumns = [
    {
      label: 'Actions',
      name: 'actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const row =  tableMeta.tableData[tableMeta.rowIndex];
          return (
            <div>
              {(canEditUser(row) || canViewUser()) &&
                <Tooltip title={canEditUser(row) ? 'Edit User' : 'View User'} aria-label=''>
                  <IconButton
                    onClick={() => {
                      navigate('/users/edit/' + row.id);
                    }}
                    size='large'
                  >
                    {canEditUser(row) ?
                    <EditIcon color='primary' /> :
                      <VisibilityIcon color={'primary'}/>}
                  </IconButton>
                </Tooltip>
              }
              {canEditGroups() &&
                <Tooltip title='Edit Group' aria-label=''>
                  <IconButton
                    onClick={() => {
                      navigate('/groups/edit/' + row.groupId);
                    }}
                    size='large'
                  >
                    <GroupIcon color='primary' />
                  </IconButton>
                </Tooltip>
              }
              {
                !!row.is_flagged && (
                  <IconButton onClick={()=> {
                    setCurrentUser(row);
                    setShowFlaggedModal(true);
                  }}>
                    <NotificationsIcon style={{ color: 'red' }} fontSize='small' />
                  </IconButton>
                )
              }
              {
                !!row.is_admin_sms_notifications && (
                  <Tooltip title='Receive Admin SMS Notifications'>
                  <IconButton>
                    <CrisisAlertIcon style={{ color: 'red' }} fontSize='medium' />
                  </IconButton>
                  </Tooltip>
                )
              }
              {
                !row.phone_verified && (
                  <Tooltip title='Phone Not Verified'>
                    <IconButton>
                      <WarningIcon style={{ color: 'red' }} fontSize='medium' />
                    </IconButton>
                  </Tooltip>
                )
              }
            </div>
          )
        }
      },
    },
    {
      label: 'Name',
      name: 'name',
      options: {
        filter: false,
      },
    },
    {
      label: 'Phone',
      name: 'phone',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const row = tableMeta.tableData[tableMeta.rowIndex];
          return (
            <PatternFormat
              id='phone'
              displayType={'text'}
              format={'(###) ###-####'}
              mask={' '}
              value={row.phone}
            />
          );
        }
      }
    },
    {
      label: 'Email',
      name: 'email',
      options: {
        filter: false
      },
    },
    {
      label: 'Status',
      name: 'status',
      options: {
        filter: true,
        sort: false,
        filterOptions: {
          names: ['Active', 'Inactive'],
        }
      },
    },
    {
      label: 'Vehicles',
      name: 'vehicles',
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      label: 'Role',
      name: 'role',
      options: {
        filterOptions: {
          names: roles,
        }
      }
    },
    {
      label: 'Terms',
      name: 'is_agree',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    }
  ];

  const groupColumns = [
    {
      label: 'Actions',
      name: 'actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const row =  tableMeta.tableData[tableMeta.rowIndex];
          return (
            <div>
              {hasAnyPermission([Permissions.ADD_UPDATE_USERS, Permissions.ADD_UPDATE_VEHICLES]) &&
                <Tooltip title='Edit Group' aria-label=''>
                  <IconButton
                    onClick={() => {
                      navigate('/groups/edit/' + row.id);
                    }}
                    size='large'
                  >
                    <EditIcon color='primary' />
                  </IconButton>
                </Tooltip>
              }
            </div>
          )
        }
      },
    },
    {
      label: 'Name',
      name: 'name',
      options: {
        filter: false,
      },
    },
    {
      label: 'Users',
      name: 'users',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value && value.map((item, idx) => (
            <span key={idx}>
              <a
                href="#"
                onClick={() => {
                  navigate(`/users/edit/${item.id}`);
                }}
                className={classes.noUnderline}
              >
                {item.name}
              </a>
              {idx !== value.length - 1 && <br/>}
            </span>
          ));
        }
      },
    },
    {
      label: 'Vehicles',
      name: 'vehicles',
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      label: 'Plan',
      name: 'plans',
      options: {
        sort: false,
        filter: false,
      },
    },
  ]

  const options = {
    serverSide: true,
    filter: !showGroups,
    filterType: 'checkbox',
    print: false,
    download: false,
    rowsPerPage: params.pageSize,
    rowsPerPageOptions: [10, 50, 100],
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    onTableChange: (action, tableState) => {
      const sortCol = transformSortCol(tableState.sortOrder?.name);
      const newParams = {
        pageSize: tableState.rowsPerPage,
        page: tableState.page + 1,
        search: tableState.searchText,
        filters: transformFilterCols(tableState),
        orderBy: sortCol,
        orderDirection: sortCol ? tableState.sortOrder?.direction : null
      };
      if (["changePage", "search", "filterChange", "sort", "changeRowsPerPage", "resetFilters"].includes(action)){
        showGroups ? fetchGroups(newParams) : fetchUsers(newParams);
      }
    },
    customToolbar: () => {
      return (canAddUser() ?
        <Tooltip title='Add User' aria-label=''>
          <IconButton
            onClick={() => {
              navigate('/users/add');
            }}
            size='large'
          >
            <AddIcon />
          </IconButton>
        </Tooltip> : null
      );
    },
  };

  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          {data && (
            <>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal,
              }}
              open={showFlaggedModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => {
                setShowFlaggedModal(false);
                setCurrentUser(null);
              }}
              aria-labelledby='modal-slide-title'
              aria-describedby='modal-slide-description'
              >
              <DialogTitle id='classic-modal-slide-title' className={classes.modalHeader}>
                User Flagged
              </DialogTitle>
              <DialogContent id='modal-slide-description' className={classes.modalBody}>
                <Typography align='left'>
                  <b>Date</b> : {moment(currentUser?.flagged_date).format('MM/DD/YYYY hh:mm A')}
                </Typography>
                <Typography align='left'>
                  <b>Reason</b> : {currentUser?.flagged_reason}
                </Typography>
              </DialogContent>
              <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
                <Button
                  onClick={() => {
                    setShowFlaggedModal(false);
                    setCurrentUser(null);
                  }}
                >
                  Close
                </Button>
                <Button
                  onClick={() => {
                    confirm({ description: "Are you sure you want to clear the flag?" , confirmationText: "Confirm"})
                    .then(async() => {
                      await UserService.clearFlag(currentUser.id);
                      setShowFlaggedModal(false);
                      setCurrentUser(null);
                      fetchUsers(params);
                    }).catch(() => {});
                  }}
                >
                  Clear Flag
                </Button>
              </DialogActions>
            </Dialog>

            <MUIDataTable
              title={
                <div>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item>
                      <CardIcon color='rose'>
                        {showGroups ? <GroupIcon style={{ color: 'white' }} /> : <PersonIcon style={{ color: 'white' }} />}
                      </CardIcon>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant='h6'
                        style={{ cursor: showGroups ? 'pointer' : 'initial', color: showGroups ? 'lightgray' : 'inherit' }}
                        onClick={() => {
                          navigate('/admin/users');
                          setShowGroups(false);
                          setParams(INITIAL_PARAMS);
                        }}
                      >
                        Users
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant='h6'
                        style={{ cursor: !showGroups ? 'pointer' : 'initial', color: !showGroups ? 'lightgray' : 'inherit' }}
                        onClick={() => {
                          navigate('/admin/groups');
                          setShowGroups(true);
                          setParams(INITIAL_PARAMS);
                        }}
                      >
                        Groups
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              }
              data={data}
              columns={showGroups ? groupColumns : userColumns}
              options={{...options, count: params.count, page: params.page - 1, rowsPerPage: params.pageSize}}
            />
           </>
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
}
