import React, {useContext, useEffect} from 'react';

import makeStyles from '@mui/styles/makeStyles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
import TvIcon from '@mui/icons-material/Tv';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.js';
import ParkingDisplayService from 'services/ParkingDisplayService';
import useAPI from 'utils/useAPI';
import buttons from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';
import {useNavigate} from 'react-router-dom';
import tableStyles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import MaterialTable, {MTableToolbar} from 'material-table';
import { Modules, isModuleDisabled } from 'config';
import {AuthContext} from '../Auth/AuthContext';
import {Permissions} from '../Auth/Permissions';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ParkingDisplays() {
  const api = useAPI();
  const navigate = useNavigate();
  const tableRef = React.createRef();
  const { hasPermission } = useContext(AuthContext);

  const [data, setData] = React.useState([]);

  function transformParkingDisplays(data) {
    return data.map((display) => {
      return {
        id: display.id,
        name: display.name,
        location: display.location.name,
        carousel: display.carousel.nickname,
        ip_addr: display.device_key,
      };
    });
  }

  async function fetchData() {
    const res = await ParkingDisplayService.getParkingDisplays();
    if (res) {
      setData(transformParkingDisplays(res));
    }
  }

  useEffect(() => {
    if (!hasPermission(Permissions.SYSTEM_ADMINISTRATION) || isModuleDisabled(Modules.ParkingDisplays)) {
      navigate('/');
    }
    ParkingDisplayService.init(api);
    fetchData();
  }, [api]);

  const classes = useStyles();
  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title='Parking Displays'
            tableRef={tableRef}
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'Location', field: 'location' },
              { title: 'Carousel', field: 'carousel' },
              { title: 'IP Address', field: 'ip_addr' },
            ]}
            data={data}
            actions={[
              {
                icon: 'add',
                tooltip: 'Add Parking Display',
                isFreeAction: true,
                onClick: () => {
                  navigate('/parking-display/add');
                },
              },
              {
                icon: 'edit',
                iconProps: { fontSize: 'small' },
                tooltip: 'Update Parking Display',
                onClick: (event, rowData) => {
                  navigate(`/parking-display/edit/${rowData.id}`);
                },
              },
              (rowData) => ({
                icon: 'delete',
                iconProps: { fontSize: 'small', style: { color: 'red' } },
                tooltip: 'Delete Parking Display',
                onClick: async () => {
                  if (confirm(`Are you sure you want to remove ${rowData.name}?`)) {
                    await ParkingDisplayService.deleteParkingDisplay(rowData.id);
                    fetchData();
                  }
                },
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: 'center', paddingRight: '0%' }}>
                  <CardHeader color='primary' icon>
                    <CardIcon color='rose'>
                      <TvIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              actionsColumnIndex: 0,
              debounceInterval: 500,
              sorting: true,
              pageSize: 10,
              headerStyle: { padding: '16px' },
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
