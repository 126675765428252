import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';
import {Autocomplete, Checkbox, FormControlLabel, Paper, Typography} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import UserService from 'services/UserService';
import VehicleService from 'services/VehicleService';
import { VehicleType } from './VehicleUtils';
import CarouselService from 'services/CarouselService';
import useAPI from 'utils/useAPI';
import {getConfig} from 'config';
import { States } from '../../utils/Utils';

const useStyles = makeStyles(styles);

function VehicleForm({ id = null, userId = null, groupId = null }) {
  const api = useAPI();
  const config = getConfig();
  const navigate = useNavigate();
  const classes = useStyles();

  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [vehicleYears, setVehicleYears] = useState([]);
  const [vehicleMake, setVehicleMake] = useState(null);
  const [vehicleModel, setVehicleModel] = useState(null);
  const [vehicleYear, setVehicleYear] = useState(null);
  const [specificModel, setSpecificModel] = useState(null);
  const [otherData, setOtherData] = useState({
    color: null,
    plate: null,
    state: null,
    is_default: false,
    is_ev: false,
    is_approved: true,
    carousel_id: null,
    addl_vehicle_id: null,
    user_id: null,
  })
  const [vehicle, setVehicle] = useState(null);
  const vehicleInitializationFlags = useRef({
    make: false,
    model: false,
    year: false,
  });

  const [timerRef, setTimerRef] = useState(null);
  const [users, setUsers] = useState([]);
  const [carousels, setCarousels] = useState([]);
  const [userOwner, setUserOwner] = useState(null); // if vehicle added with userId, show user's name and get group ID for submission
  const [groupOwner, setGroupOwner] = useState(null); // if vehicle added with groupId, show group name
  const [carouselData, setCarouselData] = useState({});

  const vehicleLabel = id ? 'Update Vehicle' : 'Add Vehicle';
  const addlVehicleId = config.addlVehicleId;

  useEffect(() => {
    UserService.init(api);
    VehicleService.init(api);
    CarouselService.init(api);
    if (!userId && !groupId) {
      fetchUsers();
    }
    if (id) {
      fetchVehicle()
    }
    if (userId) {
      fetchUser();
    }
    if (groupId) {
      fetchGroup();
    }
    fetchCarousels();
  }, [api]);

  const fetchUsers = async () => {
    try {
      const res = await UserService.getUsers()
      setUsers(res.results);
    } catch (err) {
      alert(err);
    }
  };

  const fetchVehicle = async () => {
    try {
      const res = await VehicleService.getVehicle(id);
      setVehicle(res);
    } catch (err) {
      alert(err);
    }
  };

  const fetchUser = async () => {
    try {
      const user = await UserService.getUser(userId);
      setUserOwner(user);
    } catch (err) {
      alert(err);
    }
  }

  const fetchGroup = async () => {
    try {
      const group = await UserService.getGroupWithVehicles(groupId);
      setGroupOwner(group);
    } catch (err) {
      alert(err);
    }
  }

  const analyzeCarousel = (carousel) => {
    const totalSpaces = carousel.total_spaces;
    const assignedSpaces = carousel.assigned_spaces;
    const remainingSpaces = totalSpaces - assignedSpaces;
    const description = `${carousel.nickname} (${carousel.location.name}) - `;
    const availability =
      remainingSpaces === 0 ? 'no spaces available' :
      remainingSpaces === 1 ? '1 space available' : `${remainingSpaces} spaces available`;
    let descriptionSuffix = availability;
    let disabled = remainingSpaces < 1;
    const vehicleIsTooBig = (specificModel.type === VehicleType.SUV && !carousel.is_suv) ||
      specificModel.type === VehicleType.Oversize;
    let warning = '';
    if (carousel.is_reservation_only) {
      disabled = true;
      descriptionSuffix = 'Reservations only';
    } else if (vehicleIsTooBig) {
      disabled = true;
      descriptionSuffix = 'Vehicle is too large'
    } else if (carousel.is_suv && specificModel.type !== VehicleType.SUV) {
      warning = `Warning - Assigning ${specificModel.type} to SUV-sized carousel`;
    } else if (carousel.is_ev && !otherData.is_ev) {
      warning = 'Warning - Assigning non-EV to an EV carousel';
    } else if (!carousel.is_ev && otherData.is_ev) {
      warning = 'Warning - Assigning EV to a non-EV carousel';
    }
    if (otherData.carousel_id === carousel.id && vehicleIsTooBig) {
      setOtherData({
        ...otherData,
        carousel_id: null,
      })
    }
    return {
      description: description + descriptionSuffix,
      warning,
      ...(disabled && {disabled}),
    }
  }

  const updateCarouselData = () => {
    if (specificModel) {
      const target = {};
      for (const carousel of carousels) {
        target[carousel.id] = analyzeCarousel(carousel);
      }
      setCarouselData(target);
    }
  }

  const fetchCarousels = async () => {
    const res = await CarouselService.getCarousels(100, 1)
    setCarousels(res.results);
  };

  const updatingMakesFlag = useRef(false);
  const fetchVehicleMakes = async (search) => {
    if (search.length > 2) {
      const res = await VehicleService.getVehicleMakes(search);
      updatingMakesFlag.current = true;
      setVehicleMakes(res);
    }
  };

  const fetchVehicleModelsByMake = async (make) => {
    try {
      const res = await VehicleService.getVehicleModelsByMake(make)
      setVehicleModel(null);
      setVehicleModels(res);
    } catch (err) {
      alert(err);
    }
  }

  const fetchVehicleYearsByModel = async (modelId) => {
    try {
      const res = await VehicleService.getVehicleYearsByModel(modelId);
      setVehicleYears(res);
    } catch (err) {
      alert(err);
    }
  }

  const getVehicleModelByYear = async (modelId, year) => {
    try {
      const res = await VehicleService.getVehicleModel(modelId, year);
      setSpecificModel(res);
    } catch (err) {
      alert(err);
    }
  }

  /**
   * Handle initial vehicle loading
   */
  useEffect(() => {
    if (vehicle) {
      setOtherData({
        ...otherData,
        color: vehicle.color,
        plate: vehicle.plate,
        state: vehicle.state,
        is_default: !!vehicle.is_default,
        is_ev: !!vehicle.is_ev,
        is_approved: !!vehicle.is_approved,
        carousel_id: vehicle.carousel_id,
        addl_vehicle_id: vehicle.addl_vehicle_id,
      });
      fetchVehicleMakes(vehicle.make);
      fetchVehicleModelsByMake(vehicle.make);
      fetchVehicleYearsByModel(vehicle.model_id);
      getVehicleModelByYear(vehicle.model_id, vehicle.year);
    }
  }, [vehicle]);

  useEffect(() => {
    if (vehicleMakes.length > 0) {
      if (vehicle && !vehicleInitializationFlags.current.make) {
        const matchedMake = vehicleMakes.find(make => make.id === vehicle.make_id);
        vehicleInitializationFlags.current.make = true;
        setVehicleMake(matchedMake);
      }
      updatingMakesFlag.current = false;
    }
  }, [vehicleMakes]);

  useEffect(() => {
    if (vehicleModels.length > 0 && vehicle && !vehicleInitializationFlags.current.model) {
      const model = vehicleModels.find(model => model.id === vehicle.model_id)
      vehicleInitializationFlags.current.model = true;
      setVehicleModel(model);
    }
  }, [vehicleModels]);

  useEffect(() => {
    if (vehicleYears.length > 0 && vehicle && !vehicleInitializationFlags.current.year) {
      const year = vehicleYears.find(year => year === vehicle.year);
      vehicleInitializationFlags.current.year = true;
      setVehicleYear(year);
    }
  }, [vehicleYears]);

  const handleMakeChange = (make) => {
    setSpecificModel(null);
    setVehicleModel(null);
    setVehicleModels([]);
    setVehicleYear(null);
    setVehicleYears([]);
    if (make) {
      setVehicleMake(make);
      fetchVehicleModelsByMake(make.name);
    } else {
      setVehicleMake(null);
      setVehicleMakes([]);
    }
  }

  const handleModelChange = (modelId) => {
    setSpecificModel(null);
    setVehicleYear(null);
    setVehicleYears([]);
    setVehicleModel(vehicleModels.find(model => model.id === modelId));
    fetchVehicleYearsByModel(modelId);
  }

  const handleYearChange = (year) => {
    setVehicleYear(year);
    if (year === '') {
      setSpecificModel(null);
    } else if (vehicleModel) {
      getVehicleModelByYear(vehicleModel.id, year);
    }
  }

  useEffect(() => {
    if (specificModel) {
      const update = {
        ...otherData,
      }
      if (specificModel.is_ev) {
        update.is_ev = true;
      } else if (specificModel.is_ev === 0) { // if NULL then don't change is_ev value
        update.is_ev = false;
      }

      if (!vehicle) {
        const autoApproval = specificModel.type !== VehicleType.Oversize &&
          specificModel.fits_sedan !== null && specificModel.fits_suv !== null;
        update.is_approved = autoApproval;
      }
      setOtherData(update);
      updateCarouselData();
    } else {
      setOtherData({
        ...otherData,
        is_approved: false,
      })
    }
  }, [specificModel]);

  useEffect(() => {
    updateCarouselData();
  }, [carousels]);

  const handleChange = (prop, val) => {
    setOtherData({
      ...otherData,
      [prop]: val,
    })
  };

  const handleCancel = async () => {
    navigate(userId ? `/users/edit/${userId}` : groupId ? `/groups/edit/${groupId}` : '/admin/users');
  };

  const handleUpdate = async () => {
    if (!specificModel) {
      console.log('Should not be updating without a specific model!');
      return;
    }

    const addlData = {...otherData};
    if (!addlData.user_group_id) {
      if (groupId) {
        addlData.user_group_id = groupId
      } else {
        addlData.user_group_id = userOwner.group_id;
      }
    }

    try {
      await VehicleService.upsertVehicle({
        id,
        vehicle_identifier: specificModel.vehicle_identifier,
        ...addlData,
      });
      navigate(userId ? `/users/edit/${userId}` : groupId ? `/groups/edit/${groupId}` : '/admin/vehicles');
    } catch (err) {
      if (err.includes('ER_DUP_ENTRY')) {
        alert(`${addlVehicleId} ${vehicle.addl_vehicle_id} is already in use.`);
      } else {
        alert(err);
      }
    }
  };

  const arrayBasedSelect = (valuesArr, inputLabel, vehicleProp, required = false) => {
    return <FormControl fullWidth margin='dense' className={classes.formControl}>
      <InputLabel>{inputLabel}</InputLabel>
      <Select
        value={otherData[vehicleProp] || ''}
        onChange={(e) => {
          handleChange(vehicleProp, e.target.value);
        }}
        label={inputLabel}
      >
        <MenuItem
          disabled={required}
          classes={{
            root: classes.selectMenuItem,
          }}
        >
          {inputLabel}
        </MenuItem>

        {valuesArr.map((val) => (
          <MenuItem
            value={`${val}`}
            key={`${val}`}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
          >
            {`${val}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  }

  const getHeaderText = () => {
    return `${vehicleLabel} (${userOwner?.name ? userOwner.name : groupOwner?.name ? groupOwner.name : ''})`;
  }

  const isApprovalDisabled = !specificModel;

  const isEVDisabled = () => {
    return specificModel && specificModel.is_ev !== null;
  }

  const getApprovalLabel = () => {
    if (!specificModel) {
      return <>
        Is approved?&#160;
        <span className={classes.danger}>(Must select make, model and year)</span>
      </>
    }
    if (specificModel.type === VehicleType.Oversize) {
      return <>
        Is approved?&#160;
        <span className={classes.danger}>(NOTE: Vehicle is oversize and will not fit in any carousel)</span>
      </>
    }
    if (specificModel.fits_sedan == null || specificModel.fits_suv == null) {
      return <>
        Is approved?&#160;
        <span className={classes.danger}>(NOTE: Vehicle data is incomplete -- please review)</span>
      </>
    }
    return 'Is approved?';
  }

  const getTypeLabel = () => {
    if (specificModel?.type) {
      if (specificModel.is_ev) {
        return `${specificModel.type}, EV`;
      } else {
        return specificModel.type;
      }
    }
    return 'Unrecognized';
  }

  const isSaveDisabled = () => {
    return !!(!specificModel ||
      (otherData.carousel_id && !!carouselData[otherData.carousel_id]?.disabled));
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <LocalShippingIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{getHeaderText()}</h4>
          </CardHeader>
          <CardBody>
            <form>

              {/* Make */}
              <Autocomplete
                id='vehicle-make'
                fullWidth
                margin='dense'
                value={vehicleMake || ''}
                options={vehicleMakes}
                getOptionLabel={(option) => (option.name || '')}
                isOptionEqualToValue={(option, value) => value === '' || option.id === value.id || updatingMakesFlag.current}
                onInputChange={(event, val) => {
                  if (timerRef) {
                    clearTimeout(timerRef);
                  }
                  if (val.length > 2) {
                    setTimerRef(
                      setTimeout(() => {
                        fetchVehicleMakes(val);
                      }, 500),
                    );
                  }
                }}
                onChange={(event, newValue) => {
                  handleMakeChange(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Vehicle Manufacturer (ex. Honda, BMW)'
                    margin='dense'
                    label='Make *'
                    autoFocus={true}
                  />
                )}
              />

              <FormControl fullWidth margin='dense' className={classes.formControl}>
                <InputLabel>Model *</InputLabel>
                <Select
                  id='vehicle-model'
                  value={vehicleModel?.id || ''}
                  onChange={(e) => {
                    handleModelChange(e.target.value);
                  }}
                  label='Select model'
                  >
                  <MenuItem
                    disabled={true}
                    value={''}
                    key={'select-model'}
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Select model
                  </MenuItem>
                  {vehicleModels.map(val => (
                      <MenuItem
                        value={val.id}
                        key={`${val.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {`${val.name}`}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>

              {/* Year */}
              <FormControl fullWidth margin='dense'>
                <InputLabel>Year *</InputLabel>
                <Select
                  id='vehicle-year'
                  value={vehicleYear || ''}
                  onChange={(e) => {
                    handleYearChange(e.target.value);
                  }}
                  label='Select year'
                  classes={{
                    root: classes.selectField,
                  }}
                >
                  <MenuItem
                    disabled={true}
                    value={''}
                    key={'select-year'}
                  >
                    Select year
                  </MenuItem>
                  <MenuItem
                    value={''} // Set to empty string or another value that indicates no selection
                    key={'clear-selection'}
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Clear selection
                  </MenuItem>
                  {vehicleYears.map(val => (
                    <MenuItem
                      value={val}
                      key={`${val}`}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {`${val}`}
                    </MenuItem>
                  ))
                  }
                </Select>
              </FormControl>

              <Paper style={{ backgroundColor: 'WhiteSmoke' }}>
                <Typography
                  align="left"
                  style={{ marginTop: 5, marginBottom: 5, marginLeft: 14, paddingTop: 10, paddingBottom: 10 }}
                >
                  Type: {getTypeLabel()}
                </Typography>
              </Paper>

              {arrayBasedSelect(config.vehicleColors, 'Color', 'color', true)}

              <TextField
                margin='dense'
                id='registerplate'
                label='Plate'
                type='text'
                fullWidth
                value={otherData.plate || ''}
                onChange={(event) => {
                  handleChange('plate', event.target.value);
                }}
              />

              {arrayBasedSelect(States, 'State', 'state')}

              {addlVehicleId &&
                  <TextField
                      margin='dense'
                      id='registerAddlVehicleId'
                      label={addlVehicleId}
                      type='text'
                      fullWidth
                      value={otherData.addl_vehicle_id || ''}
                      onChange={(event) => {
                        handleChange('addl_vehicle_id', event.target.value);
                      }}
                  />
              }

              <FormControl fullWidth margin='dense' className={classes.formControl}>
                <InputLabel>Carousel assignment</InputLabel>
                <Select
                  value={(carousels[0] && otherData.carousel_id) ? otherData.carousel_id : ''}
                  onChange={(e) => {
                    handleChange('carousel_id', e.target.value);
                  }}
                  inputProps={{
                    name: 'simpleSelect',
                    id: 'simple-select',
                  }}
                  label='Carousel assignment'
                >
                  <MenuItem
                    value={''}
                    key={''}
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Leave unassigned
                  </MenuItem>

                  {carousels.map((carousel) => (
                    <MenuItem
                      disabled={carouselData[carousel.id] && carouselData[carousel.id].disabled}
                      value={carousel.id}
                      key={carousel.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {carouselData[carousel.id] ? carouselData[carousel.id].description : carousel.nickname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <h5 className={classes.danger}>{otherData.carousel_id && carouselData[otherData.carousel_id] ? carouselData[otherData.carousel_id].warning : '' }</h5>

              {!userId && !groupId &&
                <FormControl fullWidth margin='dense' className={classes.formControl}>
                  <InputLabel>Choose User *</InputLabel>
                  <Select
                    value={otherData.user_id || ''}
                    onChange={(e) => {
                      handleChange('user_id', e.target.value);
                    }}
                    inputProps={{
                      name: 'simpleSelect',
                      id: 'simple-select',
                    }}
                    label='Choose User *'
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose User *
                    </MenuItem>

                    {users.map((user) => (
                      <MenuItem
                        value={`${user?.id}`}
                        key={`${user?.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {`${user.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>}

              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      checked={!!otherData.is_ev}
                      disabled={!!isEVDisabled()}
                      onChange={(event) => {
                        handleChange('is_ev', event.target.checked);
                      }}
                    />
                  }
                  label='Electric vehicle (EV)?'
                />
              </FormControl>

              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      checked={!!otherData.is_default}
                      onChange={(event) => {
                        handleChange('is_default', event.target.checked);
                      }}
                    />
                  }
                  label='Default vehicle?'
                />
              </FormControl>

              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!!isApprovalDisabled}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                      checked={!!otherData.is_approved}
                      onChange={(event) => {
                        handleChange('is_approved', event.target.checked);
                      }}
                    />
                  }
                  label={getApprovalLabel()}
                />
              </FormControl>

              <div className={classes.formCategory}>
                <small>*</small> Required fields
              </div>

              <Button color='danger' onClick={handleCancel} className={classes.registerButton}>
                Cancel
              </Button>
              <Button color='success' onClick={handleUpdate} className={classes.registerButton}
                      disabled={isSaveDisabled()}>
                {vehicleLabel}
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

VehicleForm.propTypes = {
  id: PropTypes.number,
  userId: PropTypes.number,
  groupId: PropTypes.number,
};

export default VehicleForm;
