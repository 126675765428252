import Axios from 'axios';
const Utils = require('../utils/Utils');
const baseUrl = require('../utils/useAPI').baseUrl;

let api = Axios;
const init = (axiosApi) => {
  api = axiosApi;
};

const getCameras = async () => {
  try {
    const response = await api.get('/admin/cameras');
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getCamera = async (id) => {
  try {
    const response = await api.get(`/admin/cameras/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const addCamera = async (data) => {
  try {
    const response = await api.post(`/admin/cameras`, data);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const updateCamera = async (data) => {
  try {
    const response = await api.put(`/admin/cameras/${data.id}`, data);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const deleteCamera = async (id) => {
  try {
    const response = await api.delete(`/admin/cameras/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getCameraStreamToken = async () => {
  try {
    const response = await api.get(`/admin/camera-stream-token`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getCameraUrl = (cameraId, token) => {
  return baseUrl + `admin/camera-stream/${cameraId}?t=${token}`;
}

const closeCamera = async () => {
  try {
    const response = await api.get(`/admin/close-camera-stream`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }

}

const CameraService = {
  init,
  getCameras,
  getCamera,
  addCamera,
  updateCamera,
  deleteCamera,
  getCameraStreamToken,
  getCameraUrl,
  closeCamera,
};

export default CameraService;
