import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import UserService from 'services/UserService';
import useAPI from 'utils/useAPI';
import GroupForm from './GroupForm';
import ParkingSessions from 'views/Tables/ParkingSessions';
import VehicleTable from "../Vehicle/VehicleTable"
import Loading from '../../components/Loading';
import Memberships from "../Membership/Memberships"
import {AuthContext} from '../Auth/AuthContext';
import {Permissions} from '../Auth/Permissions';

export default function EditGroup() {
  const api = useAPI();
  const { hasPermission } = useContext(AuthContext);
  let { id } = useParams();
  const [group, setGroup] = useState('');
  const [triggerChange, setTriggerChange] = useState(false);
  const [refreshIncludedData, setRefreshIncludedData] = useState(false);

  React.useEffect(() => {
    UserService.init(api);
    fetchGroup();
  }, [api]);

  React.useEffect(() => {
    fetchGroup();
  }, [triggerChange]);

  React.useEffect(() => {
    setRefreshIncludedData(!refreshIncludedData);
  }, [group]);

  const fetchGroup = async () => {
    await UserService.getGroupWithVehicles(id).then((res) => {
      setGroup(res);
    });
  };

  if (group === '') {
    return <Loading />;
  }

  return (
    <GridContainer>
      <GroupForm id={Number(id)} onFormChange={() => setTriggerChange(!triggerChange)}/>
      <Memberships userGroupId={Number(id)} refreshData={refreshIncludedData}/>
      <VehicleTable group={group} refreshData={refreshIncludedData} />
      {hasPermission(Permissions.VIEW_PARKING_SESSIONS) &&
        <GridItem xs={12}>
          <ParkingSessions groupId={Number(id)} refreshData={refreshIncludedData} pageSize={5} />
        </GridItem>
      }
    </GridContainer>
  );
}
