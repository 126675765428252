import React, {useContext} from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
import PinService from 'services/PinService';
import CarouselService from 'services/CarouselService';
import useAPI from 'utils/useAPI';
import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
import FiberPinIcon from '@mui/icons-material/FiberPin';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import MaterialTable, { MTableToolbar } from 'material-table';
import Link from "@mui/material/Link";
import { Modules, isModuleDisabled } from 'config';
import {AuthContext} from '../Auth/AuthContext';
import {Permissions} from '../Auth/Permissions';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function ReactTables() {
  const api = useAPI();
  const navigate = useNavigate();
  const location = useLocation();
  const searchText = location.state?.searchText;
  const tableRef = React.createRef();
  const { hasPermission } = useContext(AuthContext);

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (!hasPermission(Permissions.SYSTEM_ADMINISTRATION) || isModuleDisabled(Modules.UserVehiclePins)) {
      navigate('/');
    }
    PinService.init(api);
    CarouselService.init(api);
  }, [api]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setError('');
  };

  function transformPin(pins) {
    let transformed_pin = pins;
    transformed_pin = transformed_pin.map((pin) => {
      return {
        id: pin.id,
        in_use: !!pin.parking_sessions,
        carousel: pin.carousels.nickname,
        plc_pin: pin.plc_pin,
        user_vehicle_pin: pin.parking_sessions ? pin.parking_sessions.user_vehicle_pin : 'NA',
        pallet: pin.pallet_number || 'NA',
        user: pin.parking_sessions ? pin.parking_sessions.user.name : 'NA',
      };
    });
    return transformed_pin;
  }

  async function resyncCarousels() {
    const res = await CarouselService.resyncCarousels();
    refreshData();
    setError(res.success ? 'Pins resynced': res.message);
    setOpen(true);
  }

  const refreshData = () => {
    tableRef.current && tableRef.current.onQueryChange();
  }

  const deletePin = async (id, pin) => {
    try {
      if (confirm(`Are you sure you want to delete PIN ${pin}?`)) {
        await PinService.deletePin(id);
      }
    } catch (err) {
      if (err.includes('ROW_IS_REFERENCED')) {
        alert('PIN cannot be deleted as it is in use by a parking session.')
      } else {
        alert(err);
      }
    }
    refreshData();
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Snackbar open={open} anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity='info'>
            {error}
          </Alert>
        </Snackbar>
        <Card>
          <MaterialTable
            title='User Vehicle Pins'
            tableRef={tableRef}
            columns={[
              { title: 'User Vehicle PIN', field: 'user_vehicle_pin', sorting: false, render: (rowData) =>
                  <>{rowData.user_vehicle_pin === 'NA' ? rowData.user_vehicle_pin :
                    <Link color={'black'} underline={'hover'} component={'button'} onClick={() => {navigate('/admin/parking-sessions', {state:{searchText: rowData.user_vehicle_pin}})}}>
                      {rowData.user_vehicle_pin}
                    </Link>
                }</>
              },
              { title: 'User', field: 'user', sorting: false },
              { title: 'Carousel', field: 'carousel', sorting: false },
              { title: 'Pallet', field: 'pallet', sorting: false },
              { title: 'PLC PIN', field: 'plc_pin' },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                PinService.getPins(
                  query.pageSize,
                  query.page + 1,
                  query.search,
                  query.orderBy ? query.orderBy.field : null,
                  query.orderDirection,
                )
                  .then((res) => {
                    resolve({
                      data: transformPin(res.results),
                      page: res.page - 1,
                      totalCount: res.total,
                    });
                  })
                  .catch(reject);
              })
            }
            actions={[
              {
                icon: 'add',
                tooltip: 'Add PIN',
                position: 'toolbar',
                onClick: () => {
                  navigate('/pins/add');
                },
              },
              {
                icon: 'autorenew',
                tooltip: 'Resync Pins',
                position: 'toolbar',
                onClick: resyncCarousels,
              },
              (rowData) => ({
                disabled: !!rowData.in_use,
                icon: 'delete',
                iconProps: { style: { color: rowData.in_use ? 'grey' : 'red' }, fontSize: 'small' },
                tooltip: 'Delete PIN',
                onClick: () => deletePin(rowData.id, rowData.plc_pin),
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: 'center', paddingRight: '0%' }}>
                  <CardHeader color='primary' icon>
                    <CardIcon color='rose'>
                      <FiberPinIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: 0,
              debounceInterval: 500,
              sorting: true,
              pageSize: 100,
              pageSizeOptions: [10, 25, 50, 100],
              ...(searchText && {searchText})
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
