import Axios from 'axios';
const Utils = require('../utils/Utils');

let api = Axios;

const init = async (axiosApi) => {
  api = axiosApi;
};

const list = async (pageSize, page, search, orderBy, orderDirection, userId, groupId, activeSessionsOnly) => {
  try {
    const response = await api.get(
      '/admin/parking-sessions',
      { params: { pageSize, page, search, orderBy, orderDirection, userId, groupId, activeSessionsOnly } },
    );
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getSessionDetailAndPaymentSummary = async (id) => {
  try {
    const response = await api.get(`/admin/session-paymentsummary/${id}`);
    return response.data;
  } catch (e) {
    throw Utils.convertApiError(e);
  }
};

const getSessionDetail = async (id) => {
  try {
    const response = await api.get(`/admin/session-detail/${id}`);
    return response.data;
  } catch (e) {
    throw Utils.convertApiError(e);
  }
};

const applyDiscount = async (data) => {
  try {
    const response = await api.put(`/admin/parking-session/${data.parking_session_id}`, data);
    return response.data;
  } catch (e) {
    throw Utils.convertApiError(e);
  }
};

const discountList = async () => {
  const response = await api.get(`/admin/discount`);
  return response.data;
};

const retrieveParkingSession = async (sessionId, paymentSummary) => {
  try {
    const response = await api.post(
      `/admin/retrieve/${sessionId}`,
      {sessionId, paymentSummary},
    );
    return response.data;
  } catch (e) {
    throw Utils.convertApiError(e);
  }
};

const retrieveAndCancelSession = async (sessionId, cancelReason) => {
  try {
    const response = await api.post(
      `/admin/retrieveandcancel/${sessionId}`,
      {sessionId, cancelReason},
    );
    return response.data;
  } catch (e) {
    throw Utils.convertApiError(e);
  }
};

const readyToLeaveAndCancelSession = async (sessionId, cancelReason) => {
  try {
    const response = await api.post(
      `/admin/readytoleaveandcancel/${sessionId}`,
      {sessionId, cancelReason},
    );
    return response.data;
  } catch (e) {
    throw Utils.convertApiError(e);
  }
};


const processPaymentAndUpdateToRetrievingReadyToLeave = async (sessionId, paymentSummary) => {
  try {
    const response = await api.post(
      `/admin/payandleave/${sessionId}`,
      {sessionId, paymentSummary},
    );
    return response.data;
  } catch (e) {
    throw Utils.convertApiError(e);
  }
};

const reparkRetrieveParkingSession = async (vehicleId, userId) => {
  try {
    const response = await api.post(
      `/admin/repark/retrieve`,
      {vehicleId, userId},
    );
    return response.data;
  } catch (e) {
    throw Utils.convertApiError(e);
  }
};

const reparkRestowParkingSession = async (vehicleId, userId) => {
  try {
    const response = await api.post(
      `/admin/repark/restow`,
      {vehicleId, userId},
    );
    return response.data;
  } catch (e) {
    throw Utils.convertApiError(e);
  }
};


const sendSMS = async (id, message) => {
  try {
    const response = await api.post(
      `/admin/parking-session/${id}/send-sms`,
      {message: message},
    );
    return response.data;
  } catch (e) {
    throw Utils.convertApiError(e);
  }
};

const cancelSession = async (sessionId, cancelReason) => {
  try {
    const response = await api.post(
      `/cancel-session/${sessionId}`,
      {sessionId, cancelReason},
    );
    return response.data;
  } catch (e) {
    throw Utils.convertApiError(e);
  }
}

const changeSessionStatus = async (sessionId, newStatus) => {
  try {
    const response = await api.post(
      `/admin/parking-session/${sessionId}/change-status`,
      { newStatus },
    );
    return response.data;
  } catch (e) {
    throw Utils.convertApiError(e);
  }
};

const isMatchingStatus = (session, statuses) => statuses.includes(session.status);

/**
 * Pre-stowing, sessions can be canceled
 */
const isSessionPreStowing = (session) => {
  return isMatchingStatus(session, ['CUST_QUEUED_TO_PARK', 'PARKING_WAITING_FOR_GATE_TO_OPEN', 'PARKING_READY_TO_ENTER', 'PARKING_ON_PALLET']);
}

const isSessionParked = (session) => {
  return session.status === 'PARKED';
}

const isSessionAvailableForReparking = (session) => {
  return session.status === 'REPARKING_CAR_AVAILABLE';
}

const ParkingSessionService = {
  init,
  list,
  sendSMS,
  getSessionDetail,
  applyDiscount,
  discountList,
  retrieveParkingSession,
  cancelSession,
  getSessionDetailAndPaymentSummary,
  isSessionPreStowing,
  isSessionParked,
  isSessionAvailableForReparking,
  reparkRetrieveParkingSession,
  reparkRestowParkingSession,
  retrieveAndCancelSession,
  readyToLeaveAndCancelSession,
  processPaymentAndUpdateToRetrievingReadyToLeave,
  changeSessionStatus,
};

export default ParkingSessionService;
