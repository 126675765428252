import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import useAPI from '../../utils/useAPI';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import Button from '../../components/CustomButtons/Button.js';
import Card from '../../components/Card/Card.js';
import CardHeader from '../../components/Card/CardHeader.js';
import CardIcon from '../../components/Card/CardIcon.js';
import CardBody from '../../components/Card/CardBody.js';
import FeeStructureService from '../../services/FeeStructureService';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocationService from '../../services/LocationService';
import TextField from '@mui/material/TextField';

import makeStyles from '@mui/styles/makeStyles';
import styles from '../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';

const useStyles = makeStyles(styles);

function FeeForm({ id = null }) {
  const [locationList, setLocationList] = React.useState([]);
  const [FeeLabel] = React.useState(id ? 'Update Fee Structure' : 'Add Fee Structure');
  const type = [{ name: 'Hour', title: 'Hour(s)' }, { name: 'Minutes' }];
  const [fee, setFee] = React.useState({
    amount: '',
    duration: '',
    type: 'Hour',
    enter_before_hour: '',
    enter_before_minute: '',
    enter_before_ampm: '',
    leave_before_hour: '',
    leave_before_minute: '',
    leave_before_ampm: '',
    to_all_locations: true,
    location: [],
  });
  const api = useAPI();
  const navigate = useNavigate();

  const [errors, setErrors] = React.useState({
    amount: '',
    duration: '',
    type: '',
    enter_before: '',
    leave_before: '',
    time_comparison: '',
  });

  const handleLocationChange = (event) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const locationIds = typeof value === 'string' ? value.split(',') : value;
    setFee({
      ...fee,
      location: locationIds.map(id => parseInt(id, 10)),
    });
    if (locationIds.length > 0) {
      setErrors({ ...errors, location: 'success' });
    } else {
      setErrors({ ...errors, location: 'error' });
    }
  };

  const fetchLocationList = async () => {
    await LocationService.getLocations().then((res) => {
      setLocationList(
        res.map((item) => {
          return {
            id: item.id,
            name: item.name + ' - ' + item.zipcode,
            value: item.id,
          };
        }),
      );
      if (res.length === 1 && !id) {
        setFee(prevFee => ({
          ...prevFee,
          location: [res[0].id],
          to_all_locations: false,
        }));
      }
    });
  };

  const fetchFeeStructure = async () => {
    await FeeStructureService.getFees(id).then((res) => {
      setFee({
        ...res,
        ...(res.enter_before_minute != null && {enter_before_minute: res.enter_before_minute.toString().padStart(2, '0')}),
        ...(res.leave_before_minute != null && {leave_before_minute: res.leave_before_minute.toString().padStart(2, '0')}),
        location: res.location.map(item => item.id),
        to_all_locations: res.to_all_locations,
      });
    });
  };

  useEffect(() => {
    FeeStructureService.init(api);
    LocationService.init(api);
    fetchLocationList();
    if (id) {
      fetchFeeStructure();
    }
  }, [api, id]);

  useEffect(() => {
    validateTimes();
  }, [fee]);

  const numberRegex = new RegExp('^[0-9]+$');
  const verifyNumber = (value) => {
    return numberRegex.test(value) && value.length >= 1;
  };

  const amountRegex = new RegExp('^\\d+(\\.\\d{0,2})?$');
  const verifyAmount = (value) => {
    return amountRegex.test(value) && value.length >= 1;
  };

  const convertToMinutes = (hour, minute, ampm) => {
    minute = parseInt(minute, 10)
    let totalMinutes = hour * 60 + minute;
    if (ampm === 'PM' && hour !== 12) totalMinutes += 12 * 60;
    if (ampm === 'AM' && hour === 12) totalMinutes -= 12 * 60;
    return totalMinutes;
  };

  const validateTimes = () => {
    let newErrors = { ...errors };

    // Validate Enter Before time
    const enterBeforeFields = [fee.enter_before_hour, fee.enter_before_minute, fee.enter_before_ampm];
    const enterBeforeFilledFields = enterBeforeFields.filter(field => field !== '' && field != null).length;
    if (enterBeforeFilledFields === 1 || enterBeforeFilledFields === 2) {
      newErrors.enter_before = 'error';
    } else {
      newErrors.enter_before = '';
    }

    // Validate Leave Before time
    const leaveBeforeFields = [fee.leave_before_hour, fee.leave_before_minute, fee.leave_before_ampm];
    const leaveBeforeFilledFields = leaveBeforeFields.filter(field => field !== '' && field != null).length;
    if (leaveBeforeFilledFields === 1 || leaveBeforeFilledFields === 2) {
      newErrors.leave_before = 'error';
    } else {
      newErrors.leave_before = '';
    }

    if (enterBeforeFilledFields === 3 && leaveBeforeFilledFields === 3) {
      const enterBeforeMinutes = convertToMinutes(fee.enter_before_hour, fee.enter_before_minute, fee.enter_before_ampm);
      const leaveBeforeMinutes = convertToMinutes(fee.leave_before_hour, fee.leave_before_minute, fee.leave_before_ampm);

      if (leaveBeforeMinutes <= enterBeforeMinutes) {
        newErrors.time_comparison = 'error';
      } else {
        newErrors.time_comparison = '';
      }
    } else {
      newErrors.time_comparison = '';
    }

    setErrors(newErrors);
    return !(newErrors.enter_before === 'error' || newErrors.leave_before === 'error');
  };

  const handleChange = (prop, val) => {
    setFee({ ...fee, [prop]: val });
  };

  const hasError = () => {
    return Object.values(errors).includes('error');
  }

  const registerClick = async () => {
    if (hasError()) {
      return;
    }
    let error = false;
    const newErrors = {};
    ['amount', 'duration', 'location'].forEach((field) => {
      if (field === 'location') {
        if (!fee.to_all_locations && !fee['location'].length) {
          newErrors['location'] = 'error';
          error = true;
        }
      } else if (field === 'amount') {
        if (fee[field] === '' || !amountRegex.test(fee[field])) {
          newErrors[field] = 'error';
          error = true;
        }
      } else if (fee[field] === '' || !numberRegex.test(fee[field])) {
        newErrors[field] = 'error';
        error = true;
      }
    });

    if (!validateTimes()) {
      error = true;
    }

    setErrors({ ...newErrors });
    if (error) return;

    const update = {
      ...fee,
      ...(fee.enter_before_minute && {enter_before_minute: parseInt(fee.enter_before_minute, 10)}),
      ...(fee.leave_before_minute && {leave_before_minute: parseInt(fee.leave_before_minute, 10)}),
    }

    try {
      if (id) await FeeStructureService.updateFee(update);
      else await FeeStructureService.storeFee(update);
      navigate('/admin/fee-structure');
    } catch (e) {
      alert(e);
    }
  };

  const handleCancel = () => {
    navigate('/admin/fee-structure');
  };

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <MonetizationOnIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{FeeLabel}</h4>
          </CardHeader>
          <CardBody>
            <form>
              <TextField
                error={errors.amount === 'error'}
                margin='dense'
                id='amount'
                label='Amount ($) *'
                type='text'
                fullWidth
                value={fee.amount}
                onChange={(event) => {
                  if (verifyAmount(event.target.value)) {
                    setErrors({...errors, amount: 'success'});
                  } else {
                    setErrors({...errors, amount: 'error'});
                  }
                  setFee({...fee, amount: event.target.value});
                }}
                helperText={errors.amount === 'error' ? 'Please enter a valid amount (up to 2 decimal places)' : ''}
              />
              <TextField
                error={errors.duration === 'error'}
                margin='dense'
                id='duration'
                label='Duration *'
                type='text'
                fullWidth
                value={fee.duration}
                onChange={(event) => {
                  if (verifyNumber(event.target.value)) {
                    setErrors({...errors, amount: 'success'});
                  } else {
                    setErrors({...errors, amount: 'error'});
                  }
                  setFee({...fee, duration: event.target.value});
                }}
              />

              <FormControl className={classes.selectFormControl} margin='dense' fullWidth>
                <InputLabel>Duration Type *</InputLabel>
                <Select
                  value={fee.type}
                  onChange={(e) => {
                    handleChange('type', e.target.value);
                  }}
                  error={errors.type === 'error'}
                  label='Duration Type *'
                >
                  <MenuItem
                    disabled
                    value=''
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Type *
                  </MenuItem>

                  {type.map((type) => (
                    <MenuItem
                      value={`${type.name}`}
                      key={`${type.name}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${type.title || type.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{width: '100px'}}>Enter before:</div>
                  <div style={{display: 'flex', gap: '10px'}}>
                    <FormControl className={classes.selectFormControl} style={{minWidth: '92px'}}>
                      <InputLabel>Hour</InputLabel>
                      <Select
                        label='Hour'
                        value={fee.enter_before_hour || ''}
                        onChange={(e) => handleChange('enter_before_hour', e.target.value)}
                        error={errors.enter_before === 'error'}
                      >
                        <MenuItem value="">Hour</MenuItem>
                        {[12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((hour) => (
                          <MenuItem value={hour} key={hour}>{hour}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.selectFormControl} style={{minWidth: '92px'}}>
                      <InputLabel>Minute</InputLabel>
                      <Select
                        label='Minute'
                        value={fee.enter_before_minute || ''}
                        onChange={(e) => handleChange('enter_before_minute', e.target.value)}
                        error={errors.enter_before === 'error'}
                      >
                        <MenuItem value="">Minute</MenuItem>
                        {['00', '15', '30', '45'].map((minute) => (
                          <MenuItem value={minute} key={minute}>{minute}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.selectFormControl} style={{minWidth: '96px'}}>
                      <InputLabel>AM/PM</InputLabel>
                      <Select
                        label='AM/PM'
                        value={fee.enter_before_ampm || ''}
                        onChange={(e) => handleChange('enter_before_ampm', e.target.value)}
                        error={errors.enter_before === 'error'}
                      >
                        <MenuItem value="">AM/PM</MenuItem>
                        <MenuItem value="AM">AM</MenuItem>
                        <MenuItem value="PM">PM</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                {errors.enter_before === 'error' && (
                  <div style={{color: 'red'}}>Please fill all fields for Enter Before time or leave them all empty</div>
                )}

                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{width: '100px'}}>Leave before:</div>
                  <div style={{display: 'flex', gap: '10px'}}>
                    <FormControl className={classes.selectFormControl} style={{minWidth: '92px'}}>
                      <InputLabel>Hour</InputLabel>
                      <Select
                        label='Hour'
                        value={fee.leave_before_hour || ''}
                        onChange={(e) => handleChange('leave_before_hour', e.target.value)}
                        error={errors.leave_before === 'error'}
                      >
                        <MenuItem value="">Hour</MenuItem>
                        {[12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((hour) => (
                          <MenuItem value={hour} key={hour}>{hour}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.selectFormControl} style={{minWidth: '92px'}}>
                      <InputLabel>Minute</InputLabel>
                      <Select
                        label='Minute'
                        value={fee.leave_before_minute || ''}
                        onChange={(e) => handleChange('leave_before_minute', e.target.value)}
                        error={errors.leave_before === 'error'}
                      >
                        <MenuItem value="">Minute</MenuItem>
                        {['00', '15', '30', '45'].map((minute) => (
                          <MenuItem value={minute} key={minute}>{minute.toString().padStart(2, '0')}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.selectFormControl} style={{minWidth: '96px'}}>
                      <InputLabel>AM/PM</InputLabel>
                      <Select
                        label='AM/PM'
                        value={fee.leave_before_ampm || ''}
                        onChange={(e) => handleChange('leave_before_ampm', e.target.value)}
                        error={errors.leave_before === 'error'}
                      >
                        <MenuItem value="">AM/PM</MenuItem>
                        <MenuItem value="AM">AM</MenuItem>
                        <MenuItem value="PM">PM</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                {errors.leave_before === 'error' && (
                  <div style={{color: 'red'}}>Please fill all fields for Leave Before time or leave them all empty</div>
                )}
                {errors.time_comparison === 'error' && (
                  <div style={{color: 'red'}}>Leave Before time must be after Enter Before time</div>
                )}
              </div>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!fee.to_all_locations}
                    onChange={() => {
                      setFee({
                        ...fee,
                        to_all_locations: event.target.checked,
                        location: event.target.checked ? [] : fee.location,
                      });
                    }}
                  />
                }
                label='Applies to All Locations'
              />

              {!fee.to_all_locations && (
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel htmlFor='multiple-select' className={classes.selectLabel}>
                    Choose Specific Location to Apply Fee Structure
                  </InputLabel>
                  <Select
                    multiple
                    value={fee.location}
                    onChange={handleLocationChange}
                    MenuProps={{className: classes.selectMenu}}
                    classes={{select: classes.select}}
                    inputProps={{
                      name: 'multipleSelect',
                      id: 'multiple-select',
                    }}
                    renderValue={(selected) => selected.map(id =>
                      locationList.find(location => location.id === id)?.name
                    ).join(', ')}
                    label='Choose Specific Location to Apply Fee Structure'
                  >
                    {locationList &&
                      locationList.map((location) => (
                        <MenuItem
                          value={location.id}
                          key={location.id}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                        >
                          {location.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.location === 'error' && (
                    <div style={{color: 'red', marginTop: '8px'}}>Please select at least one location</div>
                  )}
                </FormControl>
              )}

              <div className={classes.formCategory}>
                <small>*</small> Required fields
              </div>

              <Button color='danger' onClick={handleCancel} className={classes.registerButton}>
                Cancel
              </Button>

              <Button color='success' disabled={hasError()} onClick={registerClick} className={classes.registerButton}>
                {FeeLabel}
              </Button>

            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

FeeForm.propTypes = {
  id: PropTypes.string,
};

export default FeeForm;
