import Axios from 'axios';
const Utils = require('../utils/Utils');

let api = Axios;
const init = (axiosApi) => {
  api = axiosApi;
};

const fetchReservations = async (locationId, requestOpts) => {
  try {
    const response = await api.post(`/admin/reservations/${locationId}`, requestOpts);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const fetchReservation = async (id) => {
  try {
    const response = await api.get(`/admin/reservations/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const upsertReservation = async (reservation) => {
  try {
    const response = await api.post('/admin/reservations', reservation);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const deleteReservation = async (id) => {
  try {
    const response = await api.delete(`/admin/reservations/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const ReservationsService = {
  init,
  fetchReservations,
  fetchReservation,
  upsertReservation,
  deleteReservation,
}

export default ReservationsService;
