import Axios from 'axios';
const Utils = require('../utils/Utils');

let api = Axios;
const init = async (axiosApi) => {
  api = axiosApi;
};

const getParkingDisplays = async () => {
  try {
    const response = await api.get('/admin/parking-displays');
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getParkingDisplay = async (id) => {
  try {
    const response = await api.get(`/admin/parking-displays/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const addParkingDisplay = async (data) => {
  try {
    const response = await api.post(`/admin/parking-displays`, data);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const deleteParkingDisplay = async (id) => {
  try {
    const response = await api.delete(`/admin/parking-displays/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const updateParkingDisplay = async (data) => {
  try {
    const response = await api.put(`/admin/parking-displays/${data.id}`, data);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const ParkingDisplayService = {
  init,
  getParkingDisplays,
  getParkingDisplay,
  addParkingDisplay,
  updateParkingDisplay,
  deleteParkingDisplay,
};

export default ParkingDisplayService;
