import Axios from 'axios';
const Utils = require('../utils/Utils');

let api = Axios;
const init = (axiosApi) => {
  api = axiosApi;
};

const getLocations = async () => {
  try {
    const response = await api.get('/locations');
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getLocationList = async (pageSize, page, search, orderBy, orderDirection) => {
  try {
    const response = await api.get(
      '/admin/locations',
      { params: { pageSize, page, search, orderBy, orderDirection } },
    );
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getLocationWithSpots = async (locationId, inclOccupancy = false) => {
  try {
    const response = await api.get(`/admin/locations/${locationId}/spots${inclOccupancy ? '?inclOccupancy=true' : ''}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const transformLocationToSpots = (location) => {
  const transformedSpots = [];
  for (const lot of location.parking_lots) {
    for (const zone of lot.parking_zones || []) {
      for (const spot of zone.parking_spots || []) {
        transformedSpots.push({
          ...spot,
          lot: {
            id: lot.id,
            name: lot.name
          },
          zone: {
            id: zone.id,
            name: zone.name
          }
        });
      }
    }

    for (const spot of lot.parking_spots || []) {
      transformedSpots.push({
        ...spot,
        lot: {
          id: lot.id,
          name: lot.name
        },
        zone: null
      });
    }
  }

  return transformedSpots;
}

const storeLocation = async (data) => {
  try {
    const response = await api.post('/admin/locations', data);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getLocation = async (id) => {
  try {
    const response = await api.get(`/admin/locations/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const updateLocation = async (data) => {
  try {
    const response = await api.put('/admin/locations/', data);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const LocationService = {
  init,
  getLocations,
  getLocationList,
  getLocationWithSpots,
  transformLocationToSpots,
  storeLocation,
  getLocation,
  updateLocation,
};

export default LocationService;
