import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import MembershipService from '../../services/MembershipService';
import {AuthContext} from '../Auth/AuthContext';
import {Permissions} from '../Auth/Permissions';

import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';
import GridItem from "../../components/Grid/GridItem"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardIcon from "../../components/Card/CardIcon"
import GroupIcon from "@mui/icons-material/Group"
import CardBody from "../../components/Card/CardBody"
import Button from "../../components/CustomButtons/Button"
import PlanOption from './PlanOption';
import makeStyles from '@mui/styles/makeStyles';
import useAPI from 'utils/useAPI';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from "@mui/material/Alert"

const useStyles = makeStyles(styles);

export default function Memberships({userGroupId, refreshData}) {
  const navigate = useNavigate();
  const api = useAPI();
  const { hasPermission } = useContext(AuthContext);
  const classes = useStyles();
  const [memberships, setMemberships] = useState(null);
  const [planOptionsData, setPlanOptionsData] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState([]);
  const [planErrors, setPlanErrors] = useState({});
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    MembershipService.init(api);
    fetchMemberships();
  }, [api]);

  const fetchMemberships = async () => {
    const memberships = await MembershipService.getGroupMemberships(userGroupId);
    setMemberships(memberships);
  }

  useEffect(() => {
    fetchMemberships();
  }, [refreshData]);

  useEffect(() => {
    if (!memberships) {
      return;
    }
    const pod = {};
    for (const membership of memberships) {
      pod[membership.id] = {};
      const plans = membership.plans;
      for (const plan of plans) {
        pod[membership.id][plan.plan_option_id] = {
          planSelected: true,
          inclEV: !!plan.incl_ev_charging,
          numVehicles: plan.num_vehicles,
          assignedSpots: plan.assigned_spots, // [{id, name, etc.},]
        }
      }
    }
    setPlanOptionsData(pod);
  }, [memberships]);

  useEffect(() => {
    if (planOptionsData) {
      setInitialized(true);
    }
  }, [planOptionsData]);

  const handleCancel = async () => {
    navigate('/admin/groups');
  };

  const handlePlanOptionChange = (membershipId, planOptionId, data) => {
    setPlanOptionsData(prevState => {
      const membershipData = { ...prevState[membershipId] } || {};
      membershipData[planOptionId] = data;
      return {
        ...prevState,
        [membershipId]: membershipData
      }
    });
  }

  const handleSave = async () => {
    let planErrs = {};
    let errs = [];
    let foundErrors = false;
    for (const membershipId of Object.keys(planOptionsData)) {
      const allPlansData = planOptionsData[membershipId];
      for (const planOptionId of Object.keys(allPlansData)) {
        const membership = memberships.find(m => m.id === Number(membershipId))
        const planOption = membership
          .membership_option.plan_options.find(po => po.id === Number(planOptionId));
        const planData = allPlansData[planOptionId]
        if (planData.planSelected) {
          console.log('checking data', planData, 'against', planOption);
          if (!planData.numVehicles || planData.numVehicles === '') {
            foundErrors = true;
            planErrs[`${membershipId}-${planOptionId}`] = {
              numVehicles: true,
            };
            errs.push('Must specify number of vehicles');
          } else if (Number(planData.numVehicles) < planData.assignedSpots.length) {
            foundErrors = true;
            planErrs[`${membershipId}-${planOptionId}`] = {
              numVehicles: true,
            };
            errs.push('Too few vehicles for the number of assigned spots');
          } else {
            const origPlan = membership.plans.find(p => p.plan_option_id === Number(planOptionId));
            const origNumVehicles = origPlan?.num_vehicles || 0;
            const change = Number(planData.numVehicles) - origNumVehicles;
            if (change > planOption.availablePlans[planData.inclEV ? 'ev' : 'nonEV']) {
              foundErrors = true;
              planErrs[`${membershipId}-${planOptionId}`] = {
                numVehicles: true,
              };
              errs.push(`Not enough ${planData.inclEV ? 'EV' : 'non-EV'} plans available`);
            }
          }
        }
      }
    }
    setPlanErrors(planErrs);
    setSnackbarText(errs);
    if (foundErrors) {
      setShowSnackbar(true);
    } else {
      setShowSnackbar(false);
      MembershipService.updateMembershipPlans(userGroupId, planOptionsData)
        .then((memberships) => {
          setMemberships(memberships);
          setSnackbarText(['Updates applied']);
          setShowSnackbar(true);
        })
        .catch((err) => alert(err));
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
    setSnackbarText([]);
  };

  if (!initialized) {
    return null;
  }

  const canEditMemberships = hasPermission(Permissions.ADD_UPDATE_USERS);

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <GroupIcon />
          </CardIcon>
          <div className={classes.cardIconTitle}>Memberships</div>
        </CardHeader>
        <CardBody>
          <Snackbar open={showSnackbar} autoHideDuration={60000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
              {snackbarText.map((error, index) => <div key={index}>{error}</div>)}
            </Alert>
          </Snackbar>
          {memberships.length === 0 ?
            <div className={classes.center}>No memberships available</div>
            :
            <form>
              {memberships.map((membership) => (
                <React.Fragment key={membership.id}>
                  <div style={{fontWeight: "bold", fontSize: 20}}>{membership.membership_option.name}</div>
                  {membership.membership_option.plan_options.map((planOption) => (
                    <PlanOption
                      key={planOption.id}
                      planOption={planOption}
                      initialData={planOptionsData[membership.id]?.[planOption.id] || {}}
                      onDataChange={(data) => handlePlanOptionChange(membership.id, planOption.id, data)}
                      errors={planErrors[`${membership.id}-${planOption.id}`]}
                      disabled={!canEditMemberships}
                    />
                  ))}
                </React.Fragment>
              ))}

              <div>
                <span className={classes.formCategory}><small>*</small> Required field</span>
                <Button color='danger' onClick={handleCancel} className={classes.registerButton}
                        style={{marginLeft: 6}}>
                  Cancel
                </Button>
                <Button color='success' onClick={handleSave} className={classes.registerButton}
                        disabled={!canEditMemberships}>
                  Update
                </Button>
              </div>

            </form>
          }

        </CardBody>
      </Card>
    </GridItem>
  );
}

Memberships.propTypes = {
  userGroupId: PropTypes.number.isRequired,
  refreshData: PropTypes.bool,
}
