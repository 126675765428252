import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { getConfig, Modules, isModuleDisabled } from 'config';
import moment from 'moment';

import MUIDataTable from 'mui-datatables';
import makeStyles from '@mui/styles/makeStyles';
import buttons from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardIcon from '../../components/Card/CardIcon';
import {Alert, Grid, MenuItem, Select, Snackbar, Typography, Tooltip, IconButton, FormControl, InputLabel, Checkbox, ListItemText} from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from '@mui/icons-material/Delete';

import useAPI from '../../utils/useAPI';
import { AuthContext } from '../Auth/AuthContext';
import { Permissions } from '../Auth/Permissions';
import ReservationService from '../../services/ReservationService';
import LocationService from '../../services/LocationService';
import Loading from '../../components/Loading';

const useStyles = makeStyles((theme) => ({
  ...buttons,
  customToolbarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    marginRight: theme.spacing(1),
    fontSize: 16,
  },
  noUnderline: {
    textDecoration: 'none',
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 600,
      color: 'black',
    },
  },
}));

export default function Reservations() {
  const api = useAPI();
  const navigate = useNavigate();
  const classes = useStyles();
  const { hasPermission } = useContext(AuthContext);
  const { reservationExternalIdLabel } = getConfig();

  const [locations, setLocations] = useState(null);
  const [carouselOptions, setCarouselOptions] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [reservations, setReservations] = useState(null);
  const [showExternalId, setShowExternalId] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);

  const PAGE_SIZES = [10, 25, 50, 100];
  const DEFAULT_PAGE_SIZE = 25;
  const DEFAULT_REQ_OPTS = {
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    sortBy: 'start_time',
    sortDir: 'desc',
    search: null,
    filters: []
  }
  const [requestOpts, setRequestOpts] = useState(DEFAULT_REQ_OPTS);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (!hasPermission(Permissions.VIEW_RESERVATIONS) || isModuleDisabled(Modules.Reservations)) {
      navigate('/');
    }
  }, [])

  useEffect(() => {
    ReservationService.init(api);
    LocationService.init(api);
    fetchLocations();
  }, [api])

  const fetchLocations = async () => {
    try {
      const locations = await LocationService.getLocations();
      setLocations(locations);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    if (locations?.length > 0) {
      setSelectedLocationId(locations[0].id);
    }
  }, [locations]);

  const handleLocationChange = (event) => {
    const locationId = event.target.value;
    setSelectedLocationId(locationId);
  };

  useEffect(() => {
    if (locations && selectedLocationId) {
      const selectedLoc = locations.find(l => l.id === selectedLocationId);
      const carousels = selectedLoc.carousels;
      setCarouselOptions(carousels.map(c => c.nickname));
    }
  }, [selectedLocationId]);

  useEffect(() => {
    if (selectedLocationId !== '') {
      fetchReservations();
    }
  }, [selectedLocationId]);

  const locationHasCarousels = () => {
    return carouselOptions?.length > 0;
  }

  const fetchReservations = async () => {
    try {
      const res = await ReservationService.fetchReservations(selectedLocationId, requestOpts);
      const transformed = res.results.map(transformReservation);
      setReservations(transformed);
      setShowExternalId(transformed.some(r => !!r.external_id));
      setTotalCount(res.total);
    } catch (err) {
      alert(err);
    }
  }

  useEffect(() => {
    console.log()
  }, [reservations])

  useEffect(() => {
    if (selectedLocationId !== '') {
      fetchReservations();
    }
  }, [requestOpts]);

  const transformReservation = (r) => {
    const now = Date.now();
    const startTime = new Date(r.start_time).getTime();
    const endTime = new Date(r.end_time).getTime();
    let status = r.status;
    const isConfirmed = status === 'CONFIRMED';
    if (isConfirmed && startTime <= now && endTime > now) {
      status = 'ACTIVE';
    } else if (isConfirmed && endTime < now) {
      status = 'EXPIRED';
    }
    return {
      id: r.id,
      user: r.user,
      carousel: r.carousel?.nickname,
      external_id: r.external_id,
      start_time: moment(r.start_time).format('MM/DD/YYYY hh:mm A'),
      end_time: moment(r.end_time).format('MM/DD/YYYY hh:mm A'),
      status,
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null);
  }

  const handleDeleteReservation = async (id) => {
    try {
      if (confirm(`Are you sure you want to delete reservation ${id}?`)) {
        await ReservationService.deleteReservation(id);
        setSnackbarMessage('Reservation deleted');
        fetchReservations();
      }
    } catch (err) {
      alert(err);
    }
  }

  let columns = [
    {
      label: 'Actions',
      name: 'actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const row =  tableMeta.tableData[tableMeta.rowIndex];
          const selectedLocation = locations.find(l => l.id === selectedLocationId);
          return (
            <>
              <Tooltip title={'Edit Reservation'}>
                <IconButton onClick={() => navigate(`/reservations/edit/${row.id}`,
                  {state: {location: selectedLocation}})}
                            size='large'>
                  <EditIcon color='primary' />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Delete Reservation'}>
                <IconButton onClick={() => handleDeleteReservation(row.id)} size='large'>
                  <DeleteIcon color='primary' />
                </IconButton>
              </Tooltip>
            </>
          )
        }
      }
    },
    {
      label: 'Reservation ID',
      name: 'id',
      options: {
        filter: false,
      },
    }
  ]

  if (showExternalId) {
    columns.push({
      label: reservationExternalIdLabel,
      name: 'external_id',
      options: {
        filter: false,
        sort: false,
      }
    })
  }

  columns = columns.concat([
    {
      label: 'User',
      name: 'user',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <a
            href="#"
            onClick={() => {
              navigate(`/users/edit/${value.id}`);
            }}
            className={classes.noUnderline}
          >
            {value.name}
          </a>
        }
      }
    },
    ...(locationHasCarousels() ? [{
      label: 'Carousel',
      name: 'carousel',
      options: {
        sort:false,
        filter: true,
        filterOptions: {
          names: carouselOptions,
        },
      },
    }] : []),
    {
      label: 'Start Time',
      name: 'start_time',
      options: {
        filter: false,
      }
    },
    {
      label: 'End Time',
      name: 'end_time',
      options: {
        filter: false,
      }
    },
    {
      label: 'Status',
      name: 'status',
      options: {
        sort: false,
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          update: (filterList, filterPos, index) => {
            filterList[index].splice(filterPos, 1);
            return filterList;
          }
        },
        filterOptions: {
          logic(status, filters) {
            if (filters.length) return !filters.includes(status);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const optionValues = ['ACTIVE', 'CONFIRMED', 'EXPIRED', 'CANCELED'];
            return (
              <FormControl>
                <InputLabel htmlFor='select-single-chip'>
                  Status
                </InputLabel>
                <Select
                  id={'select-single-chip'}
                  value={filterList[index]}
                  renderValue={selected => selected.join(', ')}
                  onChange={event => {
                    const newValue = [event.target.value]; // Only 1 value allowed at a time
                    onChange(newValue, index, column);
                  }}
                >
                  <MenuItem key={'select-one'} value={''}>
                    <Checkbox
                      color='primary'
                      disabled={true}
                      checked={false}
                    />
                    <ListItemText primary={'Select one option'} />
                  </MenuItem>

                  {optionValues.map(item => {
                    return <MenuItem key={item} value={item}>
                      <Checkbox
                        color='primary'
                        checked={filterList[index]?.indexOf(item) > -1}
                      />
                      <ListItemText primary={item} />
                    </MenuItem>
                  })}
                </Select>
              </FormControl>
            );
          },
        },
      }
    },
  ]);

  const STATUS_COL_IDX = columns.findIndex(col => col.name === 'status');

  const updateRequestOptsOnFilterChange = (tableState) => {
    const getFilter = (colName) => {
      const col = tableState.filterList[tableState.columns.findIndex(c => c.name === colName)];
      return col.length > 0 ? { column: colName, values: col } : null;
    };

    const carouselFilter = getFilter('carousel');
    const statusFilter = getFilter('status');
    const filters = [carouselFilter, statusFilter].filter(Boolean);
    setRequestOpts({ ...requestOpts, filters });
  }

  const updateRequestOptsOnSearchChange = (tableState) => {
    setRequestOpts({
      ...requestOpts,
      search: tableState.searchText,
    })
  }

  const options = {
    serverSide: true,
    filter: true,
    filterType: 'checkbox',
    print: false,
    download: false,
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
    count: totalCount,
    rowsPerPage: requestOpts.pageSize,
    rowsPerPageOptions: PAGE_SIZES,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
        case 'changeRowsPerPage':
        case 'sort':
          setRequestOpts({
            ...requestOpts,
            page: tableState.page,
            pageSize: tableState.rowsPerPage,
            sortBy: tableState.sortOrder.name,
            sortDir: tableState.sortOrder.direction,
          })
          break;
        case 'filterChange':
        case 'resetFilters':
          updateRequestOptsOnFilterChange(tableState);
          break;
        case 'search':
          updateRequestOptsOnSearchChange(tableState);
          break;
        default:
          //console.log('action skipped:', action);
      }
    },
    setRowProps: (row) => {
      const status = row[STATUS_COL_IDX];
      if (status === 'EXPIRED') {
        return {
          style: { backgroundColor: '#f7f7f7' }
        };
      } else if (status === 'CANCELED') {
        return {
          style: { backgroundColor: '#FFF4F3' }
        };
      } else if (status === 'ACTIVE') {
        return {
          style: { backgroundColor: '#DBF9DB' }
        };
      }
    },
    customToolbar: () => {
      return (
        <>
          {selectedLocationId &&
            <Tooltip title='Add Reservation'>
              <IconButton
                onClick={() => {
                  const selectedLocation = locations.find(l => l.id === selectedLocationId);
                  navigate(`/reservations/add`, {state: {location: selectedLocation}});
                }}
                size='large'
              >
                <AddIcon />
              </IconButton>
            </Tooltip>}
        </>
      );
    },
  };

  return (
    <GridContainer>
      {locations && locations.length > 1 ?
        <Grid container alignItems="center" className={classes.customToolbarContainer}>
          <Grid item>
            <Typography variant="h6" className={classes.label}>
              Select Location:
            </Typography>
          </Grid>
          <Grid item>
            <Select
              label="Select Location"
              value={selectedLocationId}
              onChange={handleLocationChange}
            >
              {locations.map((loc) => {
                return <MenuItem key={loc.id} value={loc.id}>{loc.name}</MenuItem>
              })}
            </Select>
          </Grid>
        </Grid> : null}

      <GridItem xs={12}>
        <Card>
          <Snackbar open={!!snackbarMessage} autoHideDuration={60000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          {reservations ?
            <MUIDataTable
              title={
                <div>
                  <CardIcon color='rose'>
                    <CalendarMonthIcon style={{ color: 'white' }} />{' '}
                  </CardIcon>
                  <Typography variant='h6'>Reservations</Typography>
                </div>
              }
              data={reservations}
              columns={columns}
              options={options}
            /> :
            <Loading/>
          }
        </Card>
      </GridItem>
    </GridContainer>
  );
}
