import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import VehicleService from '../../services/VehicleService';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, Menu, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import { QRCodeSVG } from 'qrcode.react';
import { useNavigate } from 'react-router-dom';

const TokenList = ({ vehicleId, make, model }) => {
  const navigate = useNavigate();
  const [tokens, setTokens] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedToken, setSelectedToken] = useState(null);

  useEffect(() => {
    fetchTokens();
  }, [vehicleId]);

  const fetchTokens = async () => {
    try {
      const response = await VehicleService.getVehicleTokens(vehicleId);
      setTokens(response);
    } catch (error) {
      console.error('Error fetching tokens:', error);
    }
  };

  const handleAddRow = async () => {
    if (confirm(`Are you sure you want to add a reusable token to the ${make} ${model}?`)) {
      try {
        const tokens = await VehicleService.addVehicleToken(vehicleId);
        setTokens(tokens);
      } catch (err) {
        alert(err);
      }
    }
  };

  const handleDelete = async (tokenId) => {
    if (confirm(`Are you sure you want to delete token ${tokenId} from the ${make} ${model}?`)) {
      try {
        const tokens = await VehicleService.deleteVehicleToken(tokenId);
        setTokens(tokens);
      } catch (err) {
        alert(err);
      }
    }
  };

  const showQRCode = (token) => {
    navigate('/vehicles/reusable-token', { state: { title:`${make} ${model}`, token: token.token } });
  }

  const handleDownloadClick = (event, token) => {
    setAnchorEl(event.currentTarget);
    setSelectedToken(token);
  };

  const handleDownloadClose = () => {
    setAnchorEl(null);
    setSelectedToken(null);
  };

  const handleDownload = (format) => {
    if (!selectedToken) return;
    downloadQRCode(selectedToken, format);
    handleDownloadClose();
  };

  const downloadQRCode = (token, format) => {
    const qrCodeSvg = ReactDOMServer.renderToStaticMarkup(
      <QRCodeSVG value={token.token} size={256} />
    ).replace('<svg', '<svg xmlns="http://www.w3.org/2000/svg" ');

    const createdAt = new Date(token.created_at);
    const formattedDate = createdAt.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit'
    }).replace(/\//g, '');
    const formattedTime = createdAt.toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).replace(/:/g, '');

    const fileName = `Vehicle_${vehicleId}_${formattedDate}_${formattedTime}.${format}`;

    if (format === 'svg') {
      const svgBlob = new Blob([qrCodeSvg], { type: 'image/svg+xml;charset=utf-8' });
      triggerDownload(svgBlob, fileName);
    } else {
      const canvas = document.createElement('canvas');
      canvas.width = 256;
      canvas.height = 256;
      const ctx = canvas.getContext('2d');

      const svgBlob = new Blob([qrCodeSvg], { type: 'image/svg+xml;charset=utf-8' });
      const url = URL.createObjectURL(svgBlob);

      const img = new Image();
      img.onload = () => {
        if (format === 'jpeg') {
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
        }
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(
          (blob) => {
            if (blob) {
              triggerDownload(blob, fileName);
            }
            URL.revokeObjectURL(url);
          },
          `image/${format}`,
          format === 'jpeg' ? 0.95 : undefined
        );
      };

      img.onerror = (e) => console.error('Image load error:', e);
      img.src = url;
    }
  };

  const triggerDownload = (blob, fileName) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
      <Table size="small" sx={{ tableLayout: 'fixed', maxWidth: '67%', }}>
        <TableHead>
          <TableRow sx={{backgroundColor: 'whitesmoke'}}>
            <TableCell sx={{ width: '100px' }}>
              <IconButton onClick={handleAddRow} size="small">
                <AddIcon />
              </IconButton>
              Add
            </TableCell>
            <TableCell sx={{ width: '50px' }}>ID</TableCell>
            <TableCell sx={{ width: '250px' }}>Token</TableCell>
            <TableCell sx={{ width: '150px' }}>Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tokens.map((token) => (
            <TableRow key={token.id}>
              <TableCell sx={{ width: '100px' }}>
                <Tooltip title="Show QR Code" arrow>
                  <IconButton onClick={() => showQRCode(token)}>
                    <VisibilityIcon/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download QR Code" arrow>
                  <IconButton onClick={(event) => handleDownloadClick(event, token)}>
                    <GetAppIcon/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete QR Code" arrow>
                  <IconButton onClick={() => handleDelete(token.id)} size="small">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell sx={{ width: '50px' }}>{token.id}</TableCell>
              <TableCell sx={{ width: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {token.token}
              </TableCell>
              <TableCell sx={{ width: '150px' }}>{new Date(token.created_at).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDownloadClose}
      >
        <MenuItem onClick={() => handleDownload('svg')}>Download as SVG</MenuItem>
        <MenuItem onClick={() => handleDownload('png')}>Download as PNG</MenuItem>
        <MenuItem onClick={() => handleDownload('jpeg')}>Download as JPEG</MenuItem>
      </Menu>
    </TableContainer>
  );
};

TokenList.propTypes = {
  vehicleId: PropTypes.number.isRequired,
  make: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
};

export default TokenList;
