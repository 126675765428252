import Axios from 'axios';
const Utils = require('../utils/Utils');

let api = Axios;

const getFeeStructure = async (pageSize, page, search, orderBy, orderDirection) => {
  try {
    const response = await api.get(
      '/admin/fees',
      { params: { pageSize, page, search, orderBy, orderDirection } },
    );
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getFees = async (id) => {
  try {
    const response = await api.get(`/admin/fees/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const storeFee = async (data) => {
  try {
    const response = await api.post('/admin/fees', data);
    console.log('response', response);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const updateFee = async (data) => {
  try {
    const response = await api.put('/admin/fees/', data);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const deleteFee = async (id) => {
  try {
    const response = await api.delete(`/admin/fees/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const FeeStructureService = {
  init,
  getFeeStructure,
  getFees,
  storeFee,
  updateFee,
  deleteFee,
};

export default FeeStructureService;
