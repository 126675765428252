import Axios from 'axios';
const Utils = require('../utils/Utils');

let api = Axios;
const init = (axiosApi) => {
  api = axiosApi;
};

const fetchChargers = async (locationId) => {
  try {
    const response = await api.get(`/admin/ev-chargers/${locationId}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const EVChargerService = {
  init,
  fetchChargers,
}

export default EVChargerService;
