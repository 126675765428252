import React, { useState, useEffect, useContext } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';
import makeStyles from '@mui/styles/makeStyles';
import useAPI from 'utils/useAPI';
import { TextField, Autocomplete, Grid, Typography, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import UserService from '../../services/UserService';
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardIcon from 'components/Card/CardIcon.js';
import GroupIcon from '@mui/icons-material/Group';
import EditIcon from '@mui/icons-material/Edit';
import Alert from "@mui/material/Alert"
import {AuthContext} from '../Auth/AuthContext';
import {Permissions} from '../Auth/Permissions';

const useStyles = makeStyles(styles);

function GroupForm({ id, onFormChange }) {
  const navigate = useNavigate();
  const api = useAPI();
  const { hasPermission } = useContext(AuthContext);
  const classes = useStyles();
  const [group, setGroup] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const [userInput, setUserInput] = useState('');
  const [userOptions, setUserOptions] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const verifyLength = (value, length) => {
    return value.length >= length;
  };

  const fetchGroup = async (id) => {
    const res = await UserService.getGroupWithUsers(id);
    setGroup(res);
  };

  const handleChange = (prop, val) => {
    setGroup({ ...group, [prop]: val });
    if (verifyLength(val, 1)) {
      setErrors({ ...errors, [prop]: 'success' });
    } else {
      setErrors({ ...errors, [prop]: 'error' });
    }
  };

  const handleAddUser = async () => {
    try {
      const updatedGroup = await UserService.addUserToGroup(selectedUser.id, group.id);
      setGroup(updatedGroup);
      setSelectedUser(null);
      setUserOptions([]);
      onFormChange();
    } catch (err) {
      alert(err);
    }
  };

  async function handleDeleteUser(userId) {
    const isConfirmed = confirm('Removing a user from this group will move the user into their own group with no vehicles. The user can then be added to a different group if needed.');
    if (!isConfirmed) {
      return;
    }

    try {
      const updatedGroup = await UserService.deleteUserFromGroup(userId, group.id)
      setGroup(updatedGroup);
      setSnackbarMessage('User removed');
      setShowSnackbar(true);
    } catch (err) {
      alert(err);
    }
  }

  const handleSave = async () => {
    let error = 0;
    let newErrors = errors;

    if (group?.name === '') {
      newErrors['name'] = 'error';
      error = 1;
    }
    setErrors({ ...newErrors });
    if (error) return;

    const data = {
      id: group.id,
      name: group.name,
    };
    try {
      await UserService.updateGroupName(data);
      setSnackbarMessage('Group saved');
      setShowSnackbar(true);
    } catch (err) {
      alert(err);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
    setSnackbarMessage('');
  };


  const handleCancel = async () => {
    navigate('/admin/groups');
  };

  useEffect(() => {
    UserService.init(api);
    fetchGroup(id);
  }, [api]);

  useEffect(() => {
    if (!!group && group?.id) {
      setLoading(false);
    }
  }, [group]);

  useEffect(() => {
    let active = true;

    const fetchUserOptions = async () => {
      const response = await UserService.getUsers({
        search: userInput.trim(),
        filters: [],
        orderBy: null,
        orderDirection: null,
        page: 1,
        pageSize: 50,
      });
      const filteredResults = response.results.filter(user2 => !group.users.some(user1 => user1.id === user2.id));
      if (active) {
        setUserOptions(filteredResults);
      }
    };

    if (userInput.trim().length > 2) {
      setUsersLoading(true);
      fetchUserOptions();
    } else {
      setUserOptions([]);
    }

    return () => {
      active = false;
    };
  }, [userInput]);

  useEffect(() => {
    setUsersLoading(false);
  }, [userOptions])

  if (loading) {
    return <Loading />;
  }

  const baseColumns = [
    { name: "id", label: "ID", options: { display: 'excluded' } },
    { name: "name", label: "Name" },
    { name: "email", label: "Email" },
  ];

  const editColumn = {
    name: 'Edit',
    label: '',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
            <button
              style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/users/edit/${tableMeta.rowData[0]}`);
              }}
            >
              <EditIcon color='primary'/>
            </button>
          </div>
        );
      },
    },
  };

  const removeColumn = {
    name: 'Remove',
    label: '',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', paddingLeft: '12px' }}>
            <button
              style={{ background: 'transparent', border: 'none', color: 'red', fontSize: '20px', cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                handleDeleteUser(tableMeta.rowData[0]);
              }}
            >
              ✖
            </button>
          </div>
        );
      },
    },
  };

  const userTableColumns = !hasPermission(Permissions.ADD_UPDATE_USERS) ? baseColumns :
      group.users.length > 1
    ? [...baseColumns, editColumn, removeColumn]
    : [...baseColumns, editColumn];

  const userTableOptions = {
    selectableRows: "none",  // Remove checkbox column
    filter: false,           // Hide filter icon
    download: false,         // Hide download icon
    print: false,            // Hide print icon
    viewColumns: false,      // Hide columns view icon
    search: false,           // Hide search bar
    responsive: "standard",  // Avoid auto-stacking of columns in mobile view
    elevation: 0,
    pagination: false,
  };

  const customTheme = createTheme({
    components: {
      MUIDataTableToolbar: {
        styleOverrides: {
          titleText: {
            marginBottom: -30,
            marginLeft: -20,
            paddingLeft: 0,
          },
        },
      },
    },
  });

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <GroupIcon />
          </CardIcon>
          <div className={classes.cardIconTitle}>Update Group</div>
        </CardHeader>
        <CardBody>
          <form>
            <TextField
              error={errors.name === 'error'}
              margin='dense'
              id='name'
              label='Name *'
              type='text'
              fullWidth
              value={group.name}
              disabled={!hasPermission(Permissions.ADD_UPDATE_USERS)}
              onChange={(event) => {
                handleChange('name', event.target.value);
              }}
            />

            <div style={{marginBottom: 25}}>
              <span className={classes.formCategory}><small>*</small> Required field</span>
              <Button color='danger' onClick={handleCancel} className={classes.registerButton} style={{marginLeft: 6}}>
                Cancel
              </Button>
              <Button color='success' onClick={handleSave} className={classes.registerButton}
                      disabled={!hasPermission(Permissions.ADD_UPDATE_USERS)}>
                Update
              </Button>
            </div>

            <div style={{ maxWidth: '800px' }}>
              <ThemeProvider theme={customTheme}>
              <MUIDataTable
                title={"Users in Group"}
                data={group.users}
                columns={userTableColumns}
                options={userTableOptions}
              />
              </ThemeProvider>
            </div>

            <Grid container alignItems='center' spacing={2} marginTop={1} maxWidth={'800px'}>
              <Grid item>
                <Typography variant='body1' style={{fontSize: '16pt', fontWeight: 400}}>Add User:</Typography>
              </Grid>
              <Grid item xs>
                <Autocomplete
                  value={selectedUser}
                  onChange={(event, newValue) => setSelectedUser(newValue)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => `${option.name} | ${option.email}`}
                  options={userOptions}
                  loading={usersLoading}
                  disabled={!hasPermission(Permissions.ADD_UPDATE_USERS)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search"
                      variant="outlined"
                      onChange={(e) => setUserInput(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!selectedUser || !hasPermission(Permissions.ADD_UPDATE_USERS)}
                  onClick={handleAddUser}
                >
                  Add User
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardBody>
      </Card>
    </GridItem>
  );
}

GroupForm.propTypes = {
  id: PropTypes.number,
  onFormChange: PropTypes.func,
};

export default GroupForm;
