import React, {useContext, useEffect, useState} from 'react';
import {format} from 'date-fns';
import MUIDataTable from 'mui-datatables';
import makeStyles from '@mui/styles/makeStyles';
import buttons from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';

import useAPI from '../../utils/useAPI';
import { useLocation, useNavigate } from 'react-router-dom';
import LocationService from '../../services/LocationService';
import CarouselService from '../../services/CarouselService';
import ParkingQueueService from '../../services/ParkingQueueService';
import { ParkingSpot, ParkingSpotLabel } from '../SurfaceParking/ParkingSpot';
import { GetVehicleLabel, getVehicleLabelText, SUVIcon, VehicleType } from '../Vehicle/VehicleUtils';
import { AuthContext } from '../Auth/AuthContext';
import { Permissions } from '../Auth/Permissions';
import { getConfig, Modules, isModuleDisabled } from 'config';
import RefreshIcon from '@mui/icons-material/Refresh';
import Loading from '../../components/Loading';
import DialogTemplate from './DialogTemplate';
import Check from "@mui/icons-material/Check";

import {
  Alert,
  Box,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material"
import GridContainer from "../../components/Grid/GridContainer"
import GridItem from "../../components/Grid/GridItem"
import Card from "../../components/Card/Card"
import CardIcon from "../../components/Card/CardIcon"
import Button from "../../components/CustomButtons/Button"

import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AddIcon from "@mui/icons-material/Add"
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import BoltSharpIcon from '@mui/icons-material/BoltSharp';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
  ...buttons,
  customToolbarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    marginRight: theme.spacing(1),
    fontSize: 16,
  },
  horizAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  horizAlignCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemLink: {
    textDecoration: 'none',
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 600,
      color: 'black',
    },
  },
  '@keyframes blink': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  actionText: {
    fontSize: 16,
    fontWeight: 400,
    color: 'red',
    animation: '$blink 1s linear infinite',
  },
}));

export function ParkingQueue() {
  const api = useAPI();
  const config = getConfig();
  const classes = useStyles();
  const { hasPermission, isSameUser } = useContext(AuthContext);
  const addlVehicleId = config.addlVehicleId;

  const navigate = useNavigate();
  const location = useLocation();
  const [locations, setLocations] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [spots, setSpots] = useState(null);
  const [carousels, setCarousels] = useState([]);
  const [queue, setQueue] = useState(null);
  const [itemForParkingSpotDialog , setItemForParkingSpotDialog] = useState(null);
  const [itemForCarouselDialog , setItemForCarouselDialog] = useState(null);
  const [queueItemForCarouselParkRetrieve, setQueueItemForCarouselParkRetrieve] = useState(null);
  const [statusForCarouselParkRetrieve, setStatusForCarouselParkRetrieve] = useState(null);
  const [queueItemForSpot, setQueueItemForSpot] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [carouselParkRetrieveDialogOpen, setCarouselParkRetrieveDialogOpen] = useState(false);

  const hasQueuePermission = hasPermission(Permissions.QUEUE_VEHICLE);

  const QueueAction = {
    PARK: 'PARK',
    RETRIEVE: 'RETRIEVE',
  }

  useEffect(() => {
    if (!hasPermission(Permissions.VIEW_PARKING_QUEUE) || isModuleDisabled(Modules.ParkingQueue)) {
      navigate('/');
    }
  }, [])

  useEffect(() => {
    LocationService.init(api);
    CarouselService.init(api);
    ParkingQueueService.init(api);
    fetchLocations();
  }, [api]);

  const fetchLocations = async () => {
    try {
      const locations = await LocationService.getLocations();
      setLocations(locations);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    if (locations?.length > 0) {
      setSelectedLocationId(location?.state?.locationId || locations[0].id);
    }
  }, [locations]);

  const handleLocationChange = (event) => {
    const locationId = event.target.value;
    setSelectedLocationId(locationId);
  };

  const loadAllData = () => {
    fetchCarousels();
    fetchParkingQueue();
    fetchParkingSpots();
  }

  useEffect(() => {
    if (selectedLocationId) {
      loadAllData();
    }
  }, [selectedLocationId]);

  const formatQueuedTime = (queuedTime) => {
    const date = new Date(queuedTime);
    const today = new Date();
    const isToday = date.toDateString() === today.toDateString();
    const timeFormat = "hh:mm:ss a";
    const dateTimeFormat = `${timeFormat} (M/d)`;
    return isToday ? format(date, timeFormat) : format(date, dateTimeFormat);
  };

  const getAddlQueueItemProperties = (item) => {
    return {
      queued_time_formatted: formatQueuedTime(item.queued_time),
      vehicle_search: `${item.vehicle.color || ''} ${item.vehicle.make} ${item.vehicle.model} ${item.vehicle.plate || ''} ${item.vehicle.state || ''} ${item.vehicle.type} ${item.vehicle.addl_vehicle_id || ''}`,
      driver_search: `${item.driver.name}`,
    }
  }

  const sortAndIndexQueue = (q) => {
    const sortedQueue = q.sort((a, b) => new Date(a.queued_time) - new Date(b.queued_time));
    return sortedQueue.map((item, index) => ({
      ...item,
      position: index + 1,
      ...(getAddlQueueItemProperties(item)),
      has_reservation: !!item.reservation,
    }));
  }

  const fetchParkingQueue = async () => {
    try {
      const { queue } = await ParkingQueueService.fetchQueue(selectedLocationId);
      setQueue(sortAndIndexQueue(queue));
    } catch (err) {
      alert(err);
    }
  }

  const fetchParkingSpots = async () => {
    try {
      const loc = await LocationService.getLocationWithSpots(selectedLocationId, true);
      let spots = LocationService.transformLocationToSpots(loc);
      spots = spots.map(s => ParkingSpot(s));
      setSpots(spots);
    } catch (err) {
      alert(err);
    }
  }

  const fetchCarousels = async () => {
    try {
      const carousels = await CarouselService.getLocationCarousels(selectedLocationId);
      setCarousels(carousels);
    } catch (err) {
      alert(err);
    }
  }

  /**
   * creates a "P" parking icon with a diagonal slash through it
   */
  const RetrieveFromSurfaceSpotIcon = () => {
    return (
      <Box position="relative" display="inline-flex" fontSize="large">
        <LocalParkingIcon color={'error'} />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          sx={{
            "&:after": {
              content: '""',
              position: 'absolute',
              left: '0',
              top: '50%',
              right: '0',
              borderTop: '2px solid',
              borderColor: 'darkred',
              transform: 'rotate(-45deg)',
            }
          }}
        />
      </Box>
    );
  };

  const isInProcess = (item) => !!item.processor_id;
  const currentUserIsProcessing = (item) => isInProcess(item) && isSameUser(item.processor_id);
  const isLocked = (item) => isInProcess(item) && !currentUserIsProcessing(item);

  const centerHeader = ({index, ...column}) => {
    return (
      <th key={index} style={{ textAlign: 'center' }}>
        {column.label}
      </th>
    )
  }

  const checkboxRenderer = (b) => {
    return (
      <div style={{ textAlign: 'center' }}>
        {b ? <Check /> : ""}
      </div>
    );
  }

  const columns = [
    {
      label: 'Position',
      name: 'position',
      options: {
        filter: false,
        searchable: false,
      }
    },
    {
      label: 'Queued At',
      name: 'queued_time_formatted',
      options: {
        sort: false,
        filter: false,
      }
    },
    ...(!isModuleDisabled(Modules.Reservations) ? [{
      label: 'Reservation?',
      name: 'has_reservation',
      options: {
        sort: true,
        filter: true,
        customHeadRender: centerHeader,
        customBodyRender: checkboxRenderer,
      }
    }] : []),
    {
      label: 'Action',
      name: 'action',
      options: {
        sort: false,
        filter: true,
        filterOptions: {
          names: ['PARK', 'RETRIEVE'],
        },
        customBodyRenderLite: (dataIndex) => {
          const item = queue[dataIndex];
          const itemIsLocked = isLocked(item);
          const sessionStatus = item.parking_session?.status;
          let title, action, disableDialog, preventDeletion;
          if (sessionStatus === 'READY_TO_STOW' || sessionStatus === 'STOWING') {
            title = 'Stowing';
            action = <div className={classes.actionText}>Stowing</div> ;
            disableDialog = true;
            preventDeletion = true;
          } else if (sessionStatus === 'CUST_QUEUED_TO_RETRIEVE'
            || sessionStatus === 'CUST_QUEUED_TO_REPARK'
            || sessionStatus === 'RETRIEVING_WAITING_FOR_CAR'
            || sessionStatus === 'REPARKING_WAITING_FOR_CAR') {
            title = 'Retrieving';
            action = <div className={classes.actionText}>Retrieving</div> ;
            disableDialog = true;
            preventDeletion = true;
          } else if (sessionStatus === 'RETRIEVING_READY_TO_LEAVE') {
            title = 'Ready to leave';
            action = <div className={classes.actionText}>Ready to leave</div> ;
            preventDeletion = true;
          } else if (sessionStatus === 'REPARKING_CAR_AVAILABLE') {
            title = 'Car waiting to repark';
            action = <div className={classes.actionText}>Waiting to repark</div> ;
            preventDeletion = true;
          } else if (item.action === QueueAction.PARK) {
            title = (item.carousel ? 'Park in Carousel' : 'Park in Spot');
            action = (item.carousel ? <UploadIcon /> : <LocalParkingIcon color={'success'} />);
          } else {
            title = (item.carousel ? 'Retrieve from Carousel' : 'Retrieve from Spot');
            action = (item.carousel ? <DownloadIcon /> : <RetrieveFromSurfaceSpotIcon />);
          }

          const getParkRetrieveClickHandler = (item) => {
            if (!hasQueuePermission || disableDialog) {
              return undefined;
            }
            if (!item.carousel_id && !item.spot_id) {
              return () => alert('Please select a carousel or surface spot first.');
            }
            return () => itemIsLocked ? stealLock(item) : openParkRetrieveDialog(item);
          };
          const getDeleteClickHandler = (item) => {
            return hasQueuePermission && !itemIsLocked && !preventDeletion
              ? (() => deleteQueueItem(item))
              : undefined;
          };

          return (
            <div>
              <Tooltip title={title}>
                <IconButton
                  disabled={!hasQueuePermission}
                  onClick={getParkRetrieveClickHandler(item)}
                  size='large'>
                  {action}
                </IconButton>
              </Tooltip>
              {hasQueuePermission && !itemIsLocked && !preventDeletion ?
                <Tooltip title='Delete'>
                  <IconButton
                    onClick={getDeleteClickHandler(item)}
                    size='large'>
                    <DeleteIcon color={'error'} />
                  </IconButton>
                </Tooltip> : null
              }
            </div>
          )
        },
      }
    },
    {
      label: 'Vehicle',
      name: 'vehicle',
      options: {
        sort: false,
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const item = queue[dataIndex];
          return (
            <GetVehicleLabel vehicle={item.vehicle}/>
          )
        },
      }
    },
    ...(addlVehicleId ? [
      {
        label: addlVehicleId,
        name: 'addl_vehicle_id',
        options: {
          sort: true,
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const item = queue[dataIndex];
            return item.vehicle.addl_vehicle_id;
          },
        }
      }] : []),
    {
      label: 'Driver',
      name: 'driver',
      options: {
        sort: false,
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const item = queue[dataIndex];
          return item.driver.name;
        },
      }
    },
      ...(carousels.length > 0 ? [{
      label: 'Carousel',
      name: 'carousel',
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const item = queue[dataIndex];
          const sessionStatus = item.parking_session?.status;
          const isStowing = (sessionStatus === 'READY_TO_STOW' || sessionStatus === 'STOWING');
          const vehicleFitsInCarousels = item.vehicle.type === VehicleType.Sedan || item.vehicle.type === VehicleType.SUV;
          const getClickHandler = (item) => {
            if (hasQueuePermission && item.action === QueueAction.PARK && !isInProcess(item) && !isStowing) {
              return () => openCarouselDialog(item)
            }
            return undefined;
          };
          return (
            <div className={item.action === QueueAction.PARK ? classes.itemLink : ''} onClick={getClickHandler(item)}>
              {item.carousel ?
                <span>{item.carousel.nickname}&#160;
                {item.carousel.is_ev ? <BoltSharpIcon /> : ''}
                  {item.carousel.is_suv ? <SUVIcon /> : ''}</span> :
                (hasQueuePermission && item.action === QueueAction.PARK && vehicleFitsInCarousels && !isInProcess(item) && !isStowing ?
                  <Button size={'sm'} round color={'primary'}>Use Carousel</Button> : null)
              }
            </div>
          )
        }
      }
    }] : []),
    ...(spots?.length > 0 ? [{
      label: 'Surface Spot',
      name: 'parking_spot',
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const item = queue[dataIndex];
          const sessionStatus = item.parking_session?.status;
          const isStowing = (sessionStatus === 'READY_TO_STOW' || sessionStatus === 'STOWING');
          const getClickHandler = (item) => {
            if (hasQueuePermission && item.action === QueueAction.PARK && !isInProcess(item) && !isStowing) {
              return () => openParkingSpotDialog(item)
            }
            return undefined;
          };
          return (
            <div className={item.action === QueueAction.PARK ? classes.itemLink : ''} onClick={getClickHandler(item)}>
              {item.parking_spot ? <ParkingSpotLabel aSpot={item?.parking_spot} /> :
                (hasQueuePermission && item.action === QueueAction.PARK && !isInProcess(item) && !isStowing ?
                  <Button size={'sm'} round color={'primary'}>Use Spot</Button> : null)}
            </div>
          )
        }
      }
    }] : []),
    {
      name: 'queued_time',
      options: {
        "filter":false,
        "sort":false,
        "display":false,
        "viewColumns":false
      }
    },
    {
      name: 'vehicle_search',
      options: {
        "filter":false,
        "sort":false,
        "display":false,
        "viewColumns":false
      }
    },
    {
      name: 'driver_search',
      options: {
        "filter":false,
        "sort":false,
        "display":false,
        "viewColumns":false
      }
    },
  ];

  const options = {
    serverSide: false,
    filter: true,
    filterType: 'checkbox',
    print: false,
    download: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20],
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
    customSearch: (searchQuery, currentRow) => {
      let isFound = false;
      currentRow.forEach(col => {
        if (col && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
          isFound = true;
        }
      });
      return isFound;
    },
    customToolbar: () => {
      return (
        <>
          <Tooltip title='Refresh Queue'>
            <IconButton
              onClick={fetchParkingQueue}
              size='large'
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          {selectedLocationId && hasQueuePermission &&
            <Tooltip title='Add Vehicle to Queue'>
              <IconButton
                onClick={() => {
                  const selectedLocation = locations.find(l => l.id === selectedLocationId);
                  navigate(`/admin/parking-queue/add/${selectedLocationId}`, {state: {location: selectedLocation}});
                }}
                size='large'
              >
                <AddIcon />
              </IconButton>
            </Tooltip>}
        </>
      );
    },
  };

  const openParkRetrieveDialog = async (item) => {
    if (item.carousel) {
      try {
        const response = await ParkingQueueService.processCarouselQueueItem(item.id);
        setQueueItemForCarouselParkRetrieve(item);
        setStatusForCarouselParkRetrieve(response.status);
      } catch (err) {
        alert(err);
        closeParkDialog(true);
      }
    } else {
      setQueueItemForSpot(item);
    }
  }

  // Parking session statuses whose queue items should be refreshed on a timer
  const IN_PROCESS_SESSION_STATUSES = [
    'READY_TO_STOW',
    'STOWING',
    'RETRIEVING_WAITING_FOR_CAR',
    'RETRIEVING_READY_TO_LEAVE',
    'REPARKING_WAITING_FOR_CAR',
    'REPARKING_CAR_AVAILABLE'
  ];

  useEffect(() => {
    // STK-237 don't refresh when dialog is open or else dialog will glitch
    if (itemForCarouselDialog || itemForParkingSpotDialog || queueItemForSpot) {
      return;
    }
    let carouselParkingTimer, queueRefreshTimer;
    if (queueItemForCarouselParkRetrieve) {
      carouselParkingTimer = setInterval(async () => {
        try {
          const response = await ParkingQueueService.processCarouselQueueItem(queueItemForCarouselParkRetrieve.id);
          setStatusForCarouselParkRetrieve(response.status);
        } catch (err) {
          alert(err);
          closeParkDialog(true);
        }
      }, 2000);
    } else if (queue) {
      const inProcessItem = queue.find(q => IN_PROCESS_SESSION_STATUSES.includes(q.parking_session?.status));
      if (inProcessItem) {
        queueRefreshTimer = setInterval(() => fetchParkingQueue(), 2000);
      }
    }
    return () => {
      if (carouselParkingTimer) clearInterval(carouselParkingTimer);
      if (queueRefreshTimer) clearInterval(queueRefreshTimer);
    }
  }, [queueItemForCarouselParkRetrieve, queue, itemForCarouselDialog, itemForParkingSpotDialog, queueItemForSpot]);

  const sendNextCarouselCommand = (cmd) => async () => {
    if (queueItemForCarouselParkRetrieve) {
      try {
        const response = await ParkingQueueService.processCarouselQueueItem(queueItemForCarouselParkRetrieve.id, cmd);
        setStatusForCarouselParkRetrieve(response.status);
        fetchParkingQueue();
      } catch (err) {
        alert(err);
        closeParkDialog(true);
      }
    } else {
      console.warn('Ignoring call to sendNextCarouselCommand with no item queued for carousel parking');
    }
  }

  const stealLock = async (item) => {
    if (confirm(`This item is being processed by someone else. Do you want to steal the lock?`)) {
      try {
          await ParkingQueueService.processCarouselQueueItem(item.id, CarouselCommands.STEAL_LOCK);
      } catch (err) {
        alert(err);
      }
      fetchParkingQueue();
      fetchParkingSpots()
    }
  }

  const closeParkDialog = (refresh) => {
    setQueueItemForCarouselParkRetrieve(null);
    setStatusForCarouselParkRetrieve(null);
    setQueueItemForSpot(null);
    if (refresh) {
      loadAllData();
    }
  }

  const parkVehicleInSpot = async () => {
    try {
      await ParkingQueueService.parkQueueItemInSpot(queueItemForSpot.id);
    } catch (err) {
      alert(err);
    }
    closeParkDialog();
    loadAllData();
  }

  const retrieveVehicleFromSpot = async () => {
    try {
      const response = await ParkingQueueService.retrieveQueueItemFromSpot(queueItemForSpot.id);
      setSnackbarMessage(`${response.vehicle_name} successfully retrieved`);
    } catch (err) {
      alert(err);
    }
    closeParkDialog();
    loadAllData();
  }

  const openCarouselDialog = (item) => {
    setItemForCarouselDialog(item);
  }

  const closeCarouselDialog = () => {
    setItemForCarouselDialog(null);
  }

  const updateCarousel = async (newCarouselId) => {
    try {
      const updatedItem = await ParkingQueueService.updateQueueItemCarousel(itemForCarouselDialog.id, newCarouselId);
      setQueue((orig) => {
        return orig.map(item => {
          if (item.id === updatedItem.id) {
            return {
              ...updatedItem,
              position: item.position,
              ...(getAddlQueueItemProperties(updatedItem)),
            }
          }
          return item;
        })
      })
      setSnackbarMessage('Carousel updated')
    } catch (err) {
      alert(err);
    }
    closeCarouselDialog();
  }

  const openParkingSpotDialog = (item) => {
    setItemForParkingSpotDialog(item);
  }

  const closeParkingSpotDialog = () => {
    setItemForParkingSpotDialog(null);
  }

  const updateParkingSpot = async (newSpotId) => {
    try {
      const updatedItem = await ParkingQueueService.updateQueueItemParkingSpot(itemForParkingSpotDialog.id, newSpotId);
      setQueue((orig) => {
        return orig.map(item => {
          if (item.id === updatedItem.id) {
            return {
              ...updatedItem,
              position: item.position,
              ...(getAddlQueueItemProperties(updatedItem)),
            }
          }
          return item;
        })
      })
      setSnackbarMessage('Parking spot updated')
    } catch (err) {
      alert(err);
    }
    closeParkingSpotDialog();
  }

  const deleteQueueItem = async (item) => {
    if (confirm(`Are you sure you want to delete the ${getVehicleLabelText(item.vehicle)} from the queue?`)) {
      try {
        await ParkingQueueService.deleteQueueItem(item.id);
        setQueue(q => q.filter(qi => qi.id !== item.id).map((item, index) => ({
          ...item,
          position: index + 1,
        })));
        setSnackbarMessage('Vehicle removed from queue');
      } catch (err) {
        alert(err);
      }
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null);
  }

  const CarouselDialog = () => {
    const [newValue, setNewValue] = useState(itemForCarouselDialog?.carousel_id);
    const title = `${itemForCarouselDialog?.carousel_id ? 'Edit' : 'Change to'} Carousel`;

    const disableCarousel = (carousel) => {
      if (carousel.available_spaces === 0) {
        return true;
      }

      const vehicleType = itemForCarouselDialog.vehicle.type;
      if (vehicleType === VehicleType.Oversize) {
        return true; // shouldn't even be in the carousel dialog
      }
      if (!carousel.is_suv && vehicleType === VehicleType.SUV) {
        return true;
      }
      return false;
    }

    const getCarouselDescription = (carousel) => {
      if (carousel.available_spaces === 0) {
        return ' (full)';
      }

      const vehicleType = itemForCarouselDialog.vehicle.type
      if ((!carousel.is_suv && vehicleType === VehicleType.SUV) || vehicleType === VehicleType.Oversize) {
        return ' (too small for vehicle)';
      }
      if (carousel.vehicle_assignments.some(
        a => a.vehicle_id === itemForCarouselDialog.vehicle.id)) {
        return ' (assigned to this carousel)';
      }
      return ` (${carousel.unassigned_available_spaces} available)`;
    }

    const content = itemForCarouselDialog ? <>
      <InputLabel style={{ fontSize: 20, marginBottom: 20 }}>
        <GetVehicleLabel vehicle={itemForCarouselDialog.vehicle} alignCenter={true}/>
      </InputLabel>
      <Select
        value={newValue || ''}
        style={{minWidth: 200}}
        onChange={(e) => setNewValue(Number(e.target.value))}
      >
        {carousels.map((carousel) => (
          <MenuItem key={carousel.id}
                    value={carousel.id}
                    disabled={disableCarousel(carousel)}>
            {carousel.nickname}&#160;
            {carousel.is_ev ? <BoltSharpIcon /> : ''}
            {carousel.is_suv ? <SUVIcon /> : ''}
            {getCarouselDescription(carousel)}
          </MenuItem>
        ))}
      </Select>
    </> : null;
    const actionFn = () => updateCarousel(newValue);
    const actionDisabled = (newValue === itemForCarouselDialog?.carousel_id);
    return <DialogTemplate
      isOpen={!!itemForCarouselDialog}
      closeFn={closeCarouselDialog}
      title={title}
      content={content}
      actionText={'Update Carousel'}
      actionFn={actionFn}
      actionsDisabled={actionDisabled}/>
  }

  const ParkingSpotDialog = () => {
    const [newValue, setNewValue] = useState(itemForParkingSpotDialog?.spot_id);
    const title = `${itemForParkingSpotDialog?.spot_id ? 'Edit' : 'Change to'} Parking Spot`;
    const content = itemForParkingSpotDialog ? <>
      <InputLabel style={{ marginRight: 10, fontSize: 20, marginBottom: 20 }}>
        <GetVehicleLabel vehicle={itemForParkingSpotDialog.vehicle}/>
      </InputLabel>
      <Select
        value={newValue || ''}
        onChange={(e) => setNewValue(Number(e.target.value))}
        style={{minWidth: 200}}
      >
        {spots.filter(spot =>
          !!((spot.is_bicycle && itemForParkingSpotDialog.vehicle.type === VehicleType.Bicycle) ||
          (!spot.is_bicycle && itemForParkingSpotDialog.vehicle.type !== VehicleType.Bicycle))
        ).map(spot => (
          <MenuItem key={spot.id}
                    value={spot.id}
                    disabled={(spot.active_parking_session.length > 0) // disable if there is a car parked in the spot
                      // or the spot is already in the queue
                      || !!(spot.id !== itemForParkingSpotDialog?.spot_id && queue.some(q => q?.parking_spot?.id === spot.id))
                      // or the spot is for a bicycle and this is a car
                      || !!(spot.is_bicycle && itemForParkingSpotDialog.vehicle.type !== VehicleType.Bicycle)}>
            {spot.label}&#160;
            {spot.icons && spot.icons.map(icon => icon)}&#160;
            {spot.active_parking_session.length > 0 ? ' (occupied)' :
              spot.id !== itemForParkingSpotDialog.spot_id && queue.some(q => q?.parking_spot?.id === spot.id) ? ' (in queue)' :
                (spot?.plan?.membership?.user_group?.id === itemForParkingSpotDialog.vehicle.user_group_id) ? ' (assigned to this spot)' : ''
            }
          </MenuItem>
        ))}
      </Select>
    </> : null;
    const actionFn = () => updateParkingSpot(newValue);
    const actionDisabled = (newValue === itemForParkingSpotDialog?.spot_id);
    return <DialogTemplate
      isOpen={!!itemForParkingSpotDialog}
      closeFn={closeParkingSpotDialog}
      title={title}
      content={content}
      actionText={'Update Spot'}
      actionFn={actionFn}
      actionsDisabled={actionDisabled}/>;
  }

  const CarouselStatuses = ParkingQueueService.QueueCarouselStatuses;
  const CarouselCommands = ParkingQueueService.QueueCarouselCommands;

  const ParkOrRetrieveInCarouselDialog = () => {
    const [dialogContent, setDialogContent] = useState({});

    useEffect(() => {
      let content, actionText, actionFn;
      let showCancel = true;
      const cancelFn = sendNextCarouselCommand(CarouselCommands.CANCEL);
      let closeFn;
      if (queueItemForCarouselParkRetrieve && statusForCarouselParkRetrieve) {
        const carousel = queueItemForCarouselParkRetrieve.carousel;
        const vehicle = queueItemForCarouselParkRetrieve.vehicle;
        const isPark = queueItemForCarouselParkRetrieve.action === QueueAction.PARK;
        let title = <>
          {isPark ? 'Parking' : 'Retrieving'}
          <br/>
          {getVehicleLabelText(vehicle)}
          <br/>
          {isPark ? 'in' : 'from'} {carousel.nickname}
        </>;
        switch (statusForCarouselParkRetrieve) {
          case CarouselStatuses.WAITING:
            content = 'Please wait...';
            break;
          case CarouselStatuses.CONFIRM_AT_GATE:
            content = 'Drive up to the gate';
            actionText = 'I am at the gate';
            actionFn = sendNextCarouselCommand(CarouselCommands.AT_GATE);
            break;
          case CarouselStatuses.WAIT_FOR_GATE:
            content = 'Gate is opening...'
            break;
          case CarouselStatuses.DRIVE_ONTO_PALLET:
            content = 'Please drive onto the pallet';
            actionText = 'Cancel Parking';
            actionFn = sendNextCarouselCommand(CarouselCommands.CANCEL);
            showCancel = false;
            break;
          case CarouselStatuses.READY_TO_STOW:
            content = 'Ensure bay is clear, then click button to stow';
            actionText = ['Stow Car'];
            actionFn = [sendNextCarouselCommand(CarouselCommands.STOW)];
            closeFn = () => closeParkDialog(true);
            if (!isPark) { // repark
              actionText.push('Leave instead');
              actionFn.push(sendNextCarouselCommand(CarouselCommands.LEAVE_INSTEAD));
              showCancel = false;
            }
            break;
          case CarouselStatuses.READY_TO_RETRIEVE:
            content = 'Leave, or just get something from car?';
            actionText = ['Leave', 'Get something'];
            actionFn = [sendNextCarouselCommand(CarouselCommands.RETRIEVE_TO_LEAVE), sendNextCarouselCommand(CarouselCommands.RETRIEVE_TO_REPARK)];
            closeFn = () => closeParkDialog(true);
            break;
          case CarouselStatuses.STOWING:
          case CarouselStatuses.RETRIEVING:
            content = `The car is being ${statusForCarouselParkRetrieve === CarouselStatuses.STOWING ? 'stowed' : 'retrieved'}...`;
            showCancel = false;
            closeFn = () => closeParkDialog(true);
            setTimeout(() => closeParkDialog(true), 4000);
            break;
          case CarouselStatuses.READY_TO_LEAVE:
            content = 'Car is ready, please drive off the carousel.';
            closeFn = () => closeParkDialog(true);
            showCancel = false;
            break;
          case CarouselStatuses.RETRIEVED:
          case CarouselStatuses.SESSION_CANCELED:
          case CarouselStatuses.RETRIEVE_CANCELED:
          case CarouselStatuses.NO_QUEUE_ITEM:
            closeParkDialog(true);
            break;
          default:
            console.log('Unknown status', statusForCarouselParkRetrieve);
            content = `Unknown status: ${statusForCarouselParkRetrieve}`;
            actionText = 'Done';
            actionFn = () => closeParkDialog(true);
        }

        setDialogContent({
          title,
          content,
          actionText,
          actionFn,
          actionDisabled: !actionText,
          showCancel,
          cancelFn,
          closeFn,
        });
        setCarouselParkRetrieveDialogOpen(true);
      } else {
        setCarouselParkRetrieveDialogOpen(false);
      }
    }, [queueItemForCarouselParkRetrieve, statusForCarouselParkRetrieve]);

    return <DialogTemplate
          isOpen={carouselParkRetrieveDialogOpen}
          closeFn={dialogContent.closeFn}
          title={dialogContent.title}
          content={dialogContent.content}
          actionText={dialogContent.actionText}
          actionFn={dialogContent.actionFn}
          actionsDisabled={dialogContent.actionDisabled}
          noTransition={true}
          hideBackdrop={true}
          showCancel={dialogContent.showCancel}
          cancelFn={dialogContent.cancelFn}
        />;
  }

  const ParkInSpotDialog = () => {
    const isPark = queueItemForSpot?.action === QueueAction.PARK;
    const content = queueItemForSpot ? <>
      Confirm
      <b><GetVehicleLabel vehicle={queueItemForSpot.vehicle}/></b>
      { isPark ? 'is parked in spot' : 'has been retrieved from' }
      <b><ParkingSpotLabel aSpot={queueItemForSpot?.parking_spot} centered={true}/></b>
    </> : null;
    return <DialogTemplate
      isOpen={!!queueItemForSpot}
      closeFn={closeParkDialog}
      title={isPark ? 'Park Vehicle' : 'Retrieve Vehicle'}
      content={content}
      actionText={'Confirm'}
      actionFn={isPark ? parkVehicleInSpot : retrieveVehicleFromSpot}
      actionsDisabled={false}/>
  }

  return (
    <GridContainer>
      {locations && locations.length > 1 ?
        <Grid container alignItems="center" className={classes.customToolbarContainer}>
          <Grid item>
            <Typography variant="h6" className={classes.label}>
              Select Location:
            </Typography>
          </Grid>
          <Grid item>
            <Select
              label="Select Location"
              value={selectedLocationId}
              onChange={handleLocationChange}
            >
              {locations.map((loc) => {
                return <MenuItem key={loc.id} value={loc.id}>{loc.name}</MenuItem>
              })}
            </Select>
          </Grid>
        </Grid> : null}

      {hasQueuePermission &&
        <>
          <ParkOrRetrieveInCarouselDialog />
          <ParkingSpotDialog />
          <CarouselDialog />
          <ParkInSpotDialog />
        </>
      }

      <GridItem xs={12}>
        <Card>
          <Snackbar open={!!snackbarMessage} autoHideDuration={60000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          {queue ?
            <MUIDataTable
              title={
                <div>
                  <CardIcon color='rose'>
                    <FormatListNumberedIcon style={{ color: 'white' }} />{' '}
                  </CardIcon>
                  <Typography variant='h6'>Parking Queue</Typography>
                </div>
              }
              data={queue}
              columns={columns}
              options={options}
            />:
            <Loading/>
          }
        </Card>
      </GridItem>
    </GridContainer>
  );
}
