import React, { useState, useCallback } from 'react';
import Webcam from 'react-webcam';
import PropTypes from "prop-types"
import Button from '../../components/CustomButtons/Button';
import { TextField } from '@mui/material';

/**
 * Capture a still photo from a webcam stream
 * @param isOpen whether or not to show the component
 * @param uploadFn a function that takes an object: {blob, description}
 */
const WebcamCapture = ({isOpen = false, uploadFn}) => {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [description, setDescription] = useState("");

  const videoConstraints = {
    facingMode: 'environment',
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef]);

  const uploadImage = async () => {
    const blob = await fetch(imgSrc).then(res => res.blob());
    uploadFn({
      blob,
      description,
    });
  };

  const handleDescriptionChange = (event) => {
    const inputValue = event.target.value;
    const maxLength = 64;
    const allowedCharsRegex = /^[a-zA-Z0-9 ,.:;'"\-_<>?/!@#$%^&*()[\]{}+=]*$/;
    if (inputValue === '' || (allowedCharsRegex.test(inputValue) && inputValue.length <= maxLength)) {
      setDescription(inputValue);
    }
  };

  return (
    isOpen ?
    <>
    {!imgSrc ?
      <>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat='image/jpeg'
          screenshotQuality={1}
          videoConstraints={videoConstraints}
          style={{ width: '100%' }}
        />
        <Button color='primary' onClick={capture}>Capture photo</Button>
      </> : null}
      {imgSrc && (
        <div style={{marginTop: 20}}>
          <img src={imgSrc} alt="Captured"/>
          <TextField
            margin='dense'
            id='description'
            label='Description'
            type='text'
            fullWidth
            value={description}
            onChange={handleDescriptionChange}
          />
          <Button color='primary' onClick={uploadImage}>Upload</Button>
          <Button onClick={capture}>Retake</Button>
        </div>
      )}
    </> : null
  );
};

WebcamCapture.propTypes = {
  isOpen: PropTypes.bool,
  uploadFn: PropTypes.func.isRequired,
};

export default WebcamCapture;
