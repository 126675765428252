import Axios from 'axios';
const Utils = require('../utils/Utils');

let api = Axios;

const init = async (axiosApi) => {
  api = axiosApi;
};

const getPins = async (pageSize, page, q, orderBy, orderDirection) => {
  try {
    const response = await api.get(
      '/admin/pins',
      { params: { pageSize, page, q, orderBy, orderDirection } },
    );
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getPin = async (id) => {
  try {
    const response = await api.get(`/admin/pins/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const storePin = async (data) => {
  try {
    const response = await api.post('/admin/pins', data);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const updatePin = async (data) => {
  try {
    const response = await api.put('/admin/pins', data);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const deletePin = async (id) => {
  try {
    const response = await api.delete(`/admin/pins/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const releasePin = async (id) => {
  try {
    const response = await api.delete(`/admin/pins/${id}/release`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const PinService = {
  init,
  getPins,
  getPin,
  storePin,
  updatePin,
  deletePin,
  releasePin,
};

export default PinService;
