import React, { useRef } from 'react';
// core components
import GridItem from 'components/Grid/GridItem.js';
import { useParams } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import makeStyles from '@mui/styles/makeStyles';
import GridContainer from 'components/Grid/GridContainer.js';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';

import Loading from 'components/Loading';
import CarouselService from 'services/CarouselService';
import useAPI from 'utils/useAPI';
import {QRCodeSVG} from 'qrcode.react';
import { Box } from "@mui/material";
import ReactToPrint from 'react-to-print';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  inputLabel: {
    color: '#008BE8',
    font: 'normal normal bold 16px/19px Lato',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  sidetext: {
    color: '#324152',
    float: 'left',
    paddingLeft: '10px',
  },
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    paddingBottom: '5%',
    marginBottom: '5%',
  },
  media: {
    height: 140,
  },
});

function CarouselQR() {
  const classes = useStyles();
  const api = useAPI();
  const navigate = useNavigate();
  const [carousel, setCarousel] = React.useState(null);
  const [openChangeQRDialog, setOpenChangeQRDialog] = React.useState(false);
  let params = useParams();
  const componentRef = useRef();

  const showChangeQRDialog = () => {
    setOpenChangeQRDialog(true);
  };

  const closeChangeQRDialog = (agreed) => {
    setOpenChangeQRDialog(false);
    if (agreed === true) {
      CarouselService.regenerateToken(carousel.id).then((res) => {
        setCarousel(res);
      });
    }
  };

  const fetchCarousel = async () => {
    CarouselService.getCarouselByToken(params.token).then((res) => {
      if (res) {
        setCarousel(res);
      } else {
        navigate('/admin/carousels');
      }
    });
  };

  React.useEffect(() => {
    CarouselService.init(api);
    fetchCarousel();
  }, []);

  if (!carousel) {
    return <Loading />;
  }

  return (
    <GridContainer justify='center'>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <Paper elevation={5} className={classes.root}>
          <CardContent  ref={componentRef} align="center">
            <CardHeader title={carousel.nickname} />
            <List>
              {carousel && (
                <>
                  <Box sx={{ display: 'none', displayPrint: 'block', height:20 }}>
                    {/*  Add print content here */}
                  </Box>
                  <QRCodeSVG size={200} value={carousel.token} />
                </>
              )}
            </List>
          </CardContent>
          <CardActions>
            <ReactToPrint
              trigger={() => <Button color="primary" variant="contained" fullWidth> Print </Button>}
              content={() => componentRef.current}
            />
            <Button color="primary" variant="contained" onClick={showChangeQRDialog} fullWidth> Change QR Code </Button>
            <Dialog
              open={openChangeQRDialog}
              onClose={closeChangeQRDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure you want to generate a new QR code ?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                 This will invalidate the current code and you will need to print the new one.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeChangeQRDialog}>Cancel</Button>
                <Button onClick={()=>closeChangeQRDialog(true)} autoFocus>Proceed</Button>
              </DialogActions>
            </Dialog>
          </CardActions>
        </Paper>
      </GridItem>
    </GridContainer>
  );
}

export default CarouselQR;
