import React, {useContext} from 'react';

import makeStyles from '@mui/styles/makeStyles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import IconButton from '@mui/material/IconButton';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.js';
import QRReaderService from 'services/QRReaderService';
import useAPI from 'utils/useAPI';
import buttons from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';
import {useNavigate} from 'react-router-dom';
import tableStyles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import MaterialTable, {MTableToolbar} from 'material-table';
import { Modules, isModuleDisabled } from 'config';
import {AuthContext} from '../Auth/AuthContext';
import {Permissions} from '../Auth/Permissions';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const api = useAPI();
  const navigate = useNavigate();
  const tableRef = React.createRef();
  const { hasPermission } = useContext(AuthContext);

  const [data, setData] = React.useState([]);

  function transformQRReaders(data) {
    return data.map((qrreader) => {
      return {
        id: qrreader.id,
        name: qrreader.name,
        location: qrreader.location.name,
        carousel: qrreader.carousel?.nickname,
        device_key: qrreader.device_key,
      };
    });
  }

  async function fetchData() {
    const res = await QRReaderService.getQRReaders();
    if (res) {
      setData(transformQRReaders(res));
    }
  }

  React.useEffect(() => {
    if (!hasPermission(Permissions.SYSTEM_ADMINISTRATION) || isModuleDisabled(Modules.QRReaders)) {
      navigate('/');
    }
    QRReaderService.init(api);
    fetchData();
  }, [api]);

  const classes = useStyles();
  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title='QR Readers'
            tableRef={tableRef}
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'Location', field: 'location' },
              { title: 'Carousel', field: 'carousel' },
              { title: 'Device Key', field: 'device_key', render: (rowData) => {
                return <>{rowData.device_key} <IconButton
                  color='inherit'
                  size='small'
                  onClick={() => {
                    navigator.clipboard.writeText(rowData.device_key);
                  }}
                >
                  <ContentCopyIcon/>
                </IconButton></>
                } },
            ]}
            data={data}
            actions={[
              {
                icon: 'add',
                tooltip: 'Add QR Reader',
                isFreeAction: true,
                onClick: () => {
                  navigate('/qr-reader/add');
                },
              },
              {
                icon: 'edit',
                iconProps: { fontSize: 'small' },
                tooltip: 'Update QR Reader',
                onClick: (event, rowData) => {
                  navigate(`/qr-reader/edit/${rowData.id}`);
                },
              },
              (rowData) => ({
                icon: 'delete',
                iconProps: { fontSize: 'small', style: { color: 'red' } },
                tooltip: 'Delete QR Reader',
                onClick: async () => {
                  if (confirm(`Are you sure you want to remove ${rowData.name}?`)) {
                    await QRReaderService.deleteQRReader(rowData.id);
                    fetchData();
                  }
                },
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: 'center', paddingRight: '0%' }}>
                  <CardHeader color='primary' icon>
                    <CardIcon color='rose'>
                      <QrCodeScannerIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              actionsColumnIndex: 0,
              debounceInterval: 500,
              sorting: true,
              pageSize: 10,
              headerStyle: { padding: '16px' },
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
