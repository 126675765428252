/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

// @mui/icons-material
import CreateIcon from '@mui/icons-material/Create';
import PropTypes from 'prop-types';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormattedInput from 'components/Pattern/FormattedInput';
import { useNavigate } from 'react-router-dom';
import useAPI from 'utils/useAPI';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import TicketService from 'services/TicketService';
import ConfigurationService from 'services/ConfigurationService';
import { FormControl, Switch, FormControlLabel, Checkbox } from '@mui/material';
import { DropzoneDialog } from 'mui-file-dropzone';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { CircularProgress } from '@mui/material';
import { toUSNationalPhoneNumber } from '../../utils/Utils';

const useStyles = makeStyles(styles);

const vehicleSizeList = require('../../assets/json/VehicleTypes');

function TicketForm({ id = null }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const api = useAPI();
  const color = require('../../assets/json/Colors');

  const [configurationList, setConfigurationList] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [labelTitle] = React.useState(id ? 'Update Ticket' : 'Add Ticket');

  const [ticket, setTicket] = React.useState({
    phone: '',
    name: '',
    vehicleType: '',
    color: '',
    plate: '',
    charging: false,
    total_amount: null,
    fee_override: false,
    configuration: [],
    attachments: [],
    notes: '',
  });
  const [errors, setErrors] = React.useState({
    phone: '',
    name: '',
    vehicleType: '',
    color: '',
    plate: '',
    total_amount: '',
  });

  const fetchConfiguration = () => {
    ConfigurationService.getConfigurations()
      .then((res) => {
        setConfigurationList(res.results);
      })
      .catch();
  };

  const fetchTicket = async () => {
    await TicketService.getTicket(id).then((res) => {
      console.log(res);
      setTicket({
        ...ticket,
        phone: toUSNationalPhoneNumber(res.phone),
        name: res.name,
        vehicleType: res.vehicle_type,
        color: res.color,
        plate: res.plate,
        notes: res.notes,
        fee_override: !!res.fee_override,
        charging: res.charging || false,
        total_amount: res.total_amount,
        attachments: res.attachments || [],
        configuration:
          (res.configIds && res.configIds.map((item) => `${item.configuration_id}`)) || [],
      });
      setLoading(false);
    });
  };

  React.useEffect(() => {
    TicketService.init(api);
    ConfigurationService.init(api);
    fetchConfiguration();
    if (id) {
      fetchTicket();
    } else {
      setLoading(false);
    }
  }, [api]);

  const handleChange = (prop) => (event) => {
    console.log('Changed', prop);
    setTicket({ ...ticket, [prop]: event.target.value });
    if (verifyLength(event.target.value, 1)) {
      setErrors({ ...errors, [prop]: 'success' });
    } else {
      setErrors({ ...errors, [prop]: 'error' });
    }
  };
  const handleCheckbox = (prop) => (event) => {
    if (event.target.checked) {
      // charging bicycle
      ticket.total_amount = '12';
    } else {
      // parking bicycle
      ticket.total_amount = '';
    }

    setTicket({ ...ticket, [prop]: event.target.checked });
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const handleFileChange = (files) => {
    setFiles(files);
  };

  const handleDelete = (index) => {
    const filteredItems = ticket.attachments
      .slice(0, index)
      .concat(ticket.attachments.slice(index + 1, ticket.attachments.length));

    setTicket({
      ...ticket,
      attachments: filteredItems,
    });
  };

  const handleSave = async () => {
    console.log(ticket);
    let error = 0;
    let newErrors = errors;
    console.log('!11111');
    ['phone', 'vehicleType', 'color'].forEach((field) => {
      if (ticket[field] === '') {
        newErrors[field] = 'error';
        error = 1;
      }
    });
    console.log('!222222');
    if (ticket['vehicleType'] !== 'BICYCLE') {
      if (ticket['plate'] === '') {
        newErrors['plate'] = 'error';
        error = 1;
      }
    }
    console.log('!33333', ticket, newErrors);
    setErrors({ ...newErrors });
    if (error) return;
    console.log('!444444');
    if (!ticket.fee_override) {
      ticket.total_amount = null;
    }
    let path = `/admin/ticket`;

    ticket.attachments = ticket.attachments.map((item) => item.path);
    // ticket.file = files;
    const formData = new FormData();
    for (let key in ticket) {
      if (key && ticket[key] !== null && ticket[key] !== undefined && ticket[key]) {
        console.log(typeof ticket[key], key, ticket[key]);
        if (
          typeof ticket[key] === 'string' ||
          typeof ticket[key] === 'number' ||
          typeof ticket[key] === 'boolean'
        ) {
          formData.append(key, ticket[key]);
        } else if (typeof ticket[key] === 'object' && ticket[key].length) {
          for (let arrkey in ticket[key]) {
            formData.append(`${key}[${arrkey}]`, ticket[key][arrkey]);
          }
        }
      }
    }
    files.forEach((file) => {
      formData.append('file', file);
    });
    console.table(Object.fromEntries(formData));
    console.log(Object.fromEntries(formData));
    // formData.append("data", JSON.stringify(ticket));
    console.log(formData);
    setSaving(true);
    await TicketService.storeTicket(formData, id)
      .then(() => {
        setSaving(false);
        navigate(path);
      })
      .catch(() => {
        setSaving(false);
      });
  };

  const cancelRoute = () => {
    window.history.back();
    // let path = `/admin/carousels`;
    // navigate(path);
  };

  if (loading) return <div>Loading...</div>;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <CreateIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{labelTitle}</h4>
          </CardHeader>
          <CardBody>
            <form>
              <FormattedInput
                name='phone'
                format='+1 (###) ### ####'
                mask=' '
                label='Phone'
                value={ticket.phone}
                onChange={handleChange('phone')}
              />
              <TextField
                error={errors.name === 'error'}
                margin='dense'
                id='name'
                label='Name'
                type='text'
                value={ticket.name}
                fullWidth
                onChange={handleChange('name')}
              />

              <FormControl fullWidth margin='dense' className={classes.formControl}>
                <InputLabel id='vehicle_type'>Vehicle Type *</InputLabel>
                <Select
                  error={errors.vehicleType === 'error'}
                  labelId='vehicle_type'
                  id='vehicle_type'
                  fullWidth
                  value={ticket.vehicleType}
                  onChange={handleChange('vehicleType')}
                  label='Vehicle Type *'
                >
                  <MenuItem
                    disabled
                    value=''
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Vehicle Type
                  </MenuItem>

                  {vehicleSizeList &&
                    vehicleSizeList.map((item) => (
                      <MenuItem
                        value={`${item.name}`}
                        key={`${item.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {`${item.name}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <TextField
                error={errors.plate === 'error'}
                margin='dense'
                id='plate'
                label='Plate *'
                value={ticket.plate}
                type='text'
                fullWidth
                onChange={handleChange('plate')}
              />

              <FormControl fullWidth margin='dense' className={classes.formControl}>
                <InputLabel id='color'>Color *</InputLabel>
                <Select
                  labelId='color'
                  id='color'
                  fullWidth
                  value={ticket.color}
                  onChange={handleChange('color')}
                  label='Color *'
                  error={errors.color === 'error'}
                >
                  <MenuItem
                    disabled
                    value=''
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Color *
                  </MenuItem>

                  {color.map((color) => (
                    <MenuItem
                      value={`${color.name}`}
                      key={`${color.name}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${color.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin='dense' className={classes.formControl}>
                <InputLabel id='color'>Additional Fees</InputLabel>
                <Select
                  multiple
                  labelId='configuration'
                  id='configuration'
                  fullWidth
                  value={ticket.configuration}
                  onChange={(e) => {
                    setTicket({ ...ticket, configuration: e.target.value });
                  }}
                  label='Additional Fees'
                >
                  <MenuItem
                    multiple={true}
                    disabled
                    value=''
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Additional Fees
                  </MenuItem>

                  {configurationList.map((item) => (
                    <MenuItem
                      value={`${item.id}`}
                      key={`${item.name}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${item.name} - $${item.amount}`}
                    </MenuItem>
                  ))}
                </Select>
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!ticket.fee_override}
                        onChange={handleCheckbox('fee_override')}
                      />
                    }
                    label='Fee override'
                  />
                  {ticket.fee_override && (
                    <TextField
                      error={errors.total_amount === 'error'}
                      margin='dense'
                      id='total_amount'
                      label='Override Amount '
                      type='text'
                      fullWidth
                      value={ticket.total_amount || ''}
                      onChange={handleChange('total_amount')}
                    />
                  )}
                </>
              </FormControl>
              <GridItem xs={12} sm={12} md={6}>
                <FormControlLabel control={<> </>} label={'Service'} labelPlacement='top' />
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!ticket.charging}
                      onChange={handleCheckbox('charging')}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  }
                  label={'Charge'}
                  labelPlacement='end'
                />
              </GridItem>

              <TextField
                id='outlined-multiline-static'
                label='Notes'
                multiline
                rows={2}
                value={ticket.notes || ''}
                fullWidth
                onChange={handleChange('notes')}
              />

              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
                <FormControlLabel control={<> </>} label={'Attachments'} labelPlacement='start' />
                <DropzoneDialog
                  onSave={handleFileChange}
                  acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                  showPreviews={true}
                  maxFileSize={5000000}
                />
                {/* <label htmlFor="icon-button-file">
                  <input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    capture="camera"
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera />
                  </IconButton>
                </label> */}
              </GridItem>

              <div className={classes.root}>
                {ticket.attachments && ticket.attachments.length && (
                  <ImageList
                    cellHeight={200}
                    cols={2}
                    className={classes.gridList}
                    style={{ margin: 20 }}
                  >
                    {ticket.attachments.map((item, index) => {
                      return (
                        <ImageListItem key={index}>
                          <a href={item.url} target='_blank' rel='noreferrer'>
                            <img src={item.url} alt={item.name} />
                          </a>
                          <ImageListItemBar
                            title={item.name}
                            actionIcon={
                              <IconButton
                                className={classes.iconButton}
                                key='close'
                                aria-label='Close'
                                color='inherit'
                                onClick={() => handleDelete(index)}
                                size='large'
                              >
                                <DeleteIcon />
                              </IconButton>
                            }
                          />
                        </ImageListItem>
                      );
                    })}
                  </ImageList>
                )}
              </div>

              <Button color='success' onClick={handleSave} disabled={saving}>
                {saving ? <CircularProgress size={20} /> : null}
                {' Submit'}
              </Button>
              <Button color='danger' onClick={cancelRoute}>
                Cancel
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

TicketForm.propTypes = {
  id: PropTypes.string,
};

export default TicketForm;
