import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

// @mui/icons-material

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useAPI from 'utils/useAPI';
import ParkingSessionService from 'services/ParkingSessionService';
import buttons from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import FormControl from '@mui/material/FormControl';

const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    buttons,
  },
  textCenter: {
    textAlign: 'center',
  },
  textMuted: {
    color: '#6c757d',
  },
};
const useStyles = makeStyles(styles);

export default function RegularForms() {
  const classes = useStyles();
  const navigate = useNavigate();
  const api = useAPI();
  let params = useParams();
  const [session, setSession] = React.useState({});
  const [discountList, setDiscountList] = React.useState([]);
  const [discount, setDiscount] = React.useState('');
  const fetchDiscountList = async () => {
    await ParkingSessionService.discountList().then((res) => {
      setDiscountList(
        res.results.map((item) => {
          return {
            id: item.id,
            name: `${item.type === 'Amount' ? '$' : ''}${item.value}${
              item.type !== 'Amount' ? '%' : ''
            }`,
            value: item.value,
          };
        }),
      );
    });
  };
  const fetchSession = async () => {
    await ParkingSessionService.getSessionDetail(params.id).then((res) => {
      setSession(res);
      setDiscount(res.discount_id ? res.discount_id : '');
    });
  };

  React.useEffect(() => {
    ParkingSessionService.init(api);
    fetchDiscountList().then(() => {
      fetchSession();
    });
  }, [api]);

  const handleSave = async () => {
    console.log('22', discount);
    if (discount !== null) {
      await ParkingSessionService.applyDiscount({
        parking_session_id: session.id,
        discount_id: discount,
      });
      navigate('/admin/parking-sessions');
    }
  };
  const cancelRoute = () => {
    navigate('/admin/parking-sessions');
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <LocalAtmIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Apply Discount</h4>
          </CardHeader>
          <CardBody className={classes.textCenter}>
            <b>
              {' '}
              Vehicle PIN: {session.user_vehicle_pin}
              <br />
              Vehicle Name: {session.vehicle_name}
              <br />
              Status: {String(session.status).toUpperCase()}
              {/* <Chip
                label={String(session.status).toUpperCase()}

                color={session.status === "retrieved" ? "primary" : "secondary"}
              />{" "} */}
              <br />
              Parked Time: {moment(session.start_time).format('MM/DD/YYYY hh:mm A')}
            </b>
            <form style={{ display: 'block', marginTop: '25px' }}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                  Choose discount
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={discount}
                  onChange={(e) => {
                    setDiscount(e.target.value);
                  }}
                  inputProps={{
                    name: 'simpleSelect',
                    id: 'simple-select',
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose discount *
                  </MenuItem>

                  {discountList.map((discountListItem) => (
                    <MenuItem
                      value={`${discountListItem.id}`}
                      key={`${discountListItem.id}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${discountListItem.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button color='success' onClick={handleSave}>
                Apply Discount
              </Button>
              <Button color='danger' onClick={cancelRoute}>
                Cancel
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
