/* eslint-disable react/display-name */

import React from 'react';

import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Container,
  Grid,
  Avatar,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import useAPI from 'utils/useAPI';
import UserService from 'services/UserService';
import makeStyles from '@mui/styles/makeStyles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import GroupIcon from '@mui/icons-material/Group';
import CardBody from 'components/Card/CardBody';
import FormattedInput from 'components/Pattern/FormattedInput';
import FormControl from '@mui/material/FormControl';
import Loading from '../../components/Loading';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
const { States } = require('../../utils/Utils');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export const ProfileComponent = () => {
  const api = useAPI();
  const classes = useStyles();
  const [saving, setSaving] = React.useState(false);
  const [initializing, setInitializing] = React.useState(true);
  const [user, setUser] = React.useState(null);
  const [showSaved, setShowSaved] = React.useState(false);

  React.useEffect(() => {
    UserService.init(api);
    fetchUser();
  }, [api]);

  const fetchUser = async () => {
    api.get('user').then((res) => {
      let sysUser = res.data;
      // Convert null/undefined keys to empty strings to ensure all text fields are controlled
      for (let key in sysUser) {
        if (sysUser[key] == null) {
          sysUser[key] = '';
        }
      }
      setUser({ ...sysUser });
    });
  };

  React.useEffect(() => {
    if (user?.id && initializing) {
      setInitializing(false);
    }
  }, [user]);


  const handleChange = (prop) => (event) => {
    const newValue = event.target.value;
    if (newValue.split('\n').length <= 2) {
      setUser({ ...user, [prop]: newValue });
    }
  };

  const handleSave = async () => {
    setSaving(true);
    await UserService.updateUser(user);
    fetchUser();
    setSaving(false);
    setShowSaved(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSaved(false);
  };

  if (initializing) {
    return (
      <Loading/>
    );
  }

  return (
    <GridContainer>
      <Snackbar open={showSaved} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          User saved
        </Alert>
      </Snackbar>
      <GridItem xs={12}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <GroupIcon />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <Container className='mb-5'>
              <Grid container wrap='nowrap' spacing={2}>
                <Grid item>
                  <Avatar alt={user.name} src={user.picture} className={classes.large} />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography noWrap>{user.name}</Typography>
                  <Typography variant='body2' noWrap>
                    {user.email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <Box pb={5}>
                  <TextField
                    margin='dense'
                    id='name'
                    label='Name'
                    value={user.name}
                    onChange={handleChange('name')}
                    type='text'
                    fullWidth
                  />
                  <TextField
                    margin='dense'
                    id='address'
                    label='Address'
                    value={user.address}
                    onChange={handleChange('address')}
                    type='text'
                    fullWidth
                    multiline
                    rows={2}
                  />

                  <TextField
                    margin='dense'
                    id='city'
                    label='City'
                    value={user.city}
                    onChange={handleChange('city')}
                    type='text'
                    fullWidth
                  />
                  <FormControl fullWidth margin='dense' className={classes.formControl}>
                    <InputLabel id='state-id'>State</InputLabel>
                    <Select label='State' value={user.state} required onChange={handleChange('state')}>
                      <MenuItem
                        disabled
                        value=''
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Choose State
                      </MenuItem>

                      {States &&
                        States.map((state) => (
                          <MenuItem
                            value={`${state}`}
                            key={`${state}`}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                          >
                            {`${state}`}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <FormattedInput
                    name='zipcode'
                    format='#####'
                    mask=' '
                    label='Zip Code'
                    value={user.zipcode}
                    onChange={handleChange('zipcode')}
                  />

                  <TextField
                    margin='dense'
                    id='email'
                    label='Email'
                    value={user.email}
                    onChange={handleChange('email')}
                    type='email'
                    disabled
                    fullWidth
                  />

                  <FormattedInput
                    name='phone'
                    format='+1 (###) ### ####'
                    mask=' '
                    label='Phone'
                    value={user.phone}
                    onChange={handleChange('phone')}
                  />

                  <Button
                    onClick={handleSave}
                    variant='contained'
                    color='primary'
                    fullWidth
                    className='mt-3'
                    sx={{ mt: 1 }}
                    disabled={saving}
                    startIcon={saving ? <CircularProgress size={24} color="inherit" /> : null}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Container>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ProfileComponent;
