import Axios from 'axios';

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getConfigurations = async (pageSize, page, search, orderBy, orderDirection) => {
  try {
    let cancel;
    const response = await api.get(
      '/admin/configuration',
      { params: { pageSize, page, search, orderBy, orderDirection } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getConfiguration = async (id) => {
  try {
    let cancel;
    const response = await api.get(`/admin/configuration/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const addConfiguration = async (data) => {
  const response = await api.post(`/admin/configuration`, data, {
    cancelToken: new CancelToken((c) => {
      requests.push(c);
    }),
  });
  return response.data;
};

const updateConfiguration = async (data) => {
  const response = await api.put(`/admin/configuration`, data, {
    cancelToken: new CancelToken((c) => {
      requests.push(c);
    }),
  });
  return response.data;
};

const deleteConfiguration = async (id) => {
  try {
    let cancel;
    const response = await api.delete(`/admin/configuration/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const ConfigurationService = {
  init,
  getConfigurations,
  updateConfiguration,
  deleteConfiguration,
  getConfiguration,
  addConfiguration,
};

export default ConfigurationService;
