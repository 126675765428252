import React, { useState, useEffect, useContext } from 'react';
import styles from './PinLogin.module.css';
import { PinContext } from './PinContext';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const PinLogin = () => {
    const [pin, setPin] = useState('');
    const { setPin: updatePinInContext, isInvalidPin, idleReset } = useContext(PinContext);

    const handleButtonClick = (e, value) => {
        e.preventDefault();
        setPin(prevPin => prevPin + value);
    };

    const handleDelete = (e) => {
        e.preventDefault();
        setPin(prevPin => prevPin.slice(0, -1));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updatePinInContext(pin);
    };

    useEffect(() => {
        if (isInvalidPin) {
            setPin('');
        }
    }, [isInvalidPin])

    useEffect(() => {
        setPin('');
    }, [idleReset]);

    return (
        <div className={styles.mainContainer}>
            <form onSubmit={handleSubmit} className={styles.pinContainer}>
                <input
                    className={styles.pinInput}
                    type="password"
                    autoComplete={'off'}
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                    maxLength="6"
                />
                <div className={styles.keypad}>
                    {[...Array(9)].map((_, index) => (
                        <button
                          type="button"
                          key={index + 1}
                          className={styles.key}
                          onClick={(e) => handleButtonClick(e, (index + 1).toString())}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button type="submit" className={styles.key}>
                        &#x2713;
                    </button>
                    <button type="button" className={styles.key}
                            onClick={(e) => handleButtonClick(e, '0')}>
                        0
                    </button>
                    <button type="button" className={styles.key} onClick={handleDelete}>
                        &#x232b;
                    </button>
                </div>
            </form>

            <Dialog open={isInvalidPin}>
                <DialogContent>
                    <DialogContentText style={{fontSize: 20}}>
                        Invalid PIN
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default PinLogin;
