import Axios from 'axios';
const Utils = require('../utils/Utils');

let api = Axios;
const init = (axiosApi) => {
  api = axiosApi;
};

const getGroupMemberships = async (id) => {
  try {
    const response = await api.get(`/admin/groups/${id}/memberships`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const updateMembershipPlans = async (groupId, data) => {
  try {
    const response = await api.put(`/admin/groups/${groupId}/membership-plans`, data);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getPlansForLocation = async (groupId, locationId) => {
  try {
    const response = await api.get(`/admin/groups/${groupId}/membership-plans/${locationId}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const MembershipService = {
  init,
  getGroupMemberships,
  updateMembershipPlans,
  getPlansForLocation,
};

export default MembershipService;
