import Axios from 'axios';
const Utils = require('../utils/Utils');

let api = Axios;

const init = async (axiosApi) => {
  api = axiosApi;
};

const getLocationCarousels = async (locationId) => {
  try {
    const response = await api.get(`/admin/locations/${locationId}/carousels`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getCarousels = async (pageSize, page, q) => {
  try {
    const response = await api.get(
      '/admin/carousels',
      { params: { pageSize, page, q } },
    );
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getCarousel = async (id) => {
  try {
    const response = await api.get(`/admin/carousels/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getCarouselByToken = async (token) => {
  try {
    const response = await api.get(`/admin/carousel-by-token/${token}`);
    return response.data[0];
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const deleteCarousel = async (id) => {
  try {
    const response = await api.delete(`/admin/carousels/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const checkForNonUniqueIpAddr = (err) => {
  if (typeof err !== 'string') {
    return err;
  }
  if (err.indexOf('ER_DUP_ENTRY') > -1 && err.indexOf("for key 'carousels.ip_address'") > -1) {
    return 'API URL is already defined';
  }
  if (err.indexOf('AxiosError: timeout') > -1) {
    return 'Carousel was added but the API URL is unreachable.';
  }
  return err;
}

const storeCarousel = async (data) => {
  try {
    const response = await api.post('/admin/carousels', data);
    return response.data;
  } catch (error) {
    let e = Utils.convertApiError(error)
    e = checkForNonUniqueIpAddr(e);
    throw e;
  }
};

const updateCarousel = async (data) => {
  try {
    const response = await api.post(`/admin/carousels/${data.id}`, data);
    return response.data;
  } catch (error) {
    let e = Utils.convertApiError(error)
    e = checkForNonUniqueIpAddr(e);
    throw e;
  }
};

const regenerateToken = async (id) => {
  try {
    const response = await api.put(`/admin/carousels/regenerate-token/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getCarouselState = async (id) => {
  try {
    const response = await api.get(`/admin/carousels/${id}/state`);
    return response.data; // contains "state" and "isCarOnParkingPallet"
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getCarouselPalletData = async (id) => {
  try {
    const response = await api.get(`/admin/carousels/${id}/location`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const resyncCarousels = async () => {
  try {
    const response = await api.post('/admin/carousels/resync');
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const resetHmi = async (carouselId) => {
  try {
    const response = await api.post(`/admin/carousels/${carouselId}/reset-hmi`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const clearAlarm = async (carouselId) => {
  try {
    const response = await api.post(`/admin/carousels/${carouselId}/clear-alarm`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

/**
 * Simulate a car in either the entrance or mirror carousel bay
 * @param carouselId
 * @param b true to simulate a car, false to remove simulated car
 */
const simulateCarInBay = async (carouselId, b) => {
  try {
    const response = await api.post(`/admin/carousels/${carouselId}/simulate-car-in-bay/${b}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

/**
 * Set the ground pallet for a carousel
 * @param carouselId
 * @param position for single carousels, use 'entrance'; for tandem carousels, use 'entrance' or 'mirror'
 * @param pallet the ground pallet, 1 - N
 */
const setGroundPallet = async (carouselId, position, pallet) => {
  try {
    const response = await api.post(`/admin/carousels/${carouselId}/set-ground-pallet/${position}/${pallet}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

/**
 * Call (move) a pallet to the ground
 * @param carouselId
 * @param position for single carousels, use 'entrance'; for tandem carousels, use 'entrance' or 'mirror'
 * @param pallet the pallet number, 1 - N
 */
const callPallet = async (carouselId, position, pallet) => {
  try {
    const response = await api.post(`/admin/carousels/${carouselId}/call-pallet/${position}/${pallet}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const sendParkingCommand = async (carouselId, command, pin) => {
  try {
    const response = await api.post(`/admin/carousels/${carouselId}/send-parking-cmd/${command}?pin=${pin}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const moveGate = async (carouselId, open) => {
  try {
    const response = await api.post(`/admin/carousels/${carouselId}/${open ? 'open' : 'close'}-gate`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const refreshAttributes = async (carouselId) => {
  try {
    const response = await api.post(`/admin/carousels/${carouselId}/refresh-attributes`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const runHealthCheck = async (carouselId) => {
  try {
    const response = await api.post(`/admin/carousels/${carouselId}/health-check`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const readPin = async (carouselId) => {
  try {
    const response = await api.post(`/admin/carousels/${carouselId}/read-pin`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}


const hasAlert = (carousel, code) => {
  return carousel.alerts && carousel.alerts.filter((alert) => alert.alert_code === code).length > 0;
}
const isHmiInUse = (carousel) => {
  return hasAlert(carousel, 'IN_USE_BY_HMI');
}
const isAlarmDetected = (carousel) => {
  return hasAlert(carousel, 'ALARM_DETECTED')
}

const CarouselService = {
  init,
  getCarousel,
  getCarouselByToken,
  getCarousels,
  deleteCarousel,
  storeCarousel,
  updateCarousel,
  getCarouselState,
  getCarouselPalletData,
  regenerateToken,
  resyncCarousels,
  resetHmi,
  isHmiInUse,
  isAlarmDetected,
  clearAlarm,
  getLocationCarousels,
  simulateCarInBay,
  setGroundPallet,
  moveGate,
  refreshAttributes,
  sendParkingCommand,
  runHealthCheck,
  callPallet,
  readPin,
};

export default CarouselService;
