import React from 'react';
import MUIDataTable from 'mui-datatables';

import makeStyles from '@mui/styles/makeStyles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardIcon from 'components/Card/CardIcon.js';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
import ConfigurationService from 'services/ConfigurationService';
import useAPI from 'utils/useAPI';
import buttons from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';
import tableStyles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import BookIcon from '@mui/icons-material/Book';
import { Typography, Tooltip, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CraeteIcon from '@mui/icons-material/Create';
// import DeleteIcon from "@mui/icons-material/Delete";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function Configuration() {
  const api = useAPI();
  const navigate = useNavigate();
  const [data, setData] = React.useState(null);

  const fetchConfiguration = () => {
    ConfigurationService.getConfigurations()
      .then((res) => {
        let d = transform(res.results);
        setData(d);
      })
      .catch();
  };

  React.useEffect(() => {
    ConfigurationService.init(api);
    fetchConfiguration();
  }, [api]);

  const classes = useStyles();

  function transform(session) {
    const transformedData = session.map((ses) => {
      return {
        id: ses.id,
        name: ses.name,
        amount: ses.amount,
        type: ses.type ? ses.type : 'NA',
        actions: (
          <>
            <div display='flex'>
              <Tooltip title='Update FeeStructure' aria-label=''>
                <IconButton
                  onClick={() => {
                    navigate('/configuration/edit/' + ses.id);
                  }}
                  size='large'
                >
                  <CraeteIcon style={{ color: '#0C3C78' }} />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Delete" aria-label="">
                <IconButton
                  onClick={() => {
                    ConfigurationService.deleteConfiguration(ses.id);
                    fetchConfiguration();
                  }}
                >
                  <DeleteIcon style={{ color: "red" }} />
                </IconButton>
              </Tooltip> */}
            </div>
          </>
        ),
      };
    });
    return transformedData;
  }

  const columns = [
    {
      label: 'Name',
      name: 'name',
    },
    {
      label: 'Amount ($)',
      name: 'amount',
    },
    {
      label: 'Configuration Type',
      name: 'type',
    },
    {
      label: 'Actions',
      name: 'actions',
    },
  ];
  const options = {
    filter: false, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
    customToolbar: () => {
      return (
        <Tooltip title='Add Configuration' aria-label=''>
          <IconButton
            onClick={() => {
              navigate('/configuration/create');
            }}
            size='large'
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
  };
  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color='rose'>
                    <BookIcon style={{ color: 'white' }} />{' '}
                  </CardIcon>
                  <Typography variant='h6'>Configuration</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
            />
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
}
