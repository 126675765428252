/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import Draggable from 'react-draggable';

import { getConfig } from 'config';
const { kioskMode, keyboardWidth } = getConfig();

const OnScreenKeyboard = () => {
  const [layoutName, setLayoutName] = useState('default');
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [bounds, setBounds] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const keyboardRef = useRef(null);
  const activeElementRef = useRef(null);
  const layoutChangeTimeoutRef = useRef(null);
  const hideKeyboardTimeoutRef = useRef(null);

  useEffect(() => {
    if (kioskMode) {
      const centerX = (window.innerWidth - keyboardWidth) / 2;
      setPosition({ x: centerX, y: 0 });
      setBounds({
        left: 100,
        right: window.innerWidth - keyboardWidth - 20,
        top: -400,
        bottom: 0
      });

      // Add event listeners for focus and blur
      document.addEventListener('focusin', handleFocusIn);
      document.addEventListener('focusout', handleFocusOut);

      // Clean up event listeners
      return () => {
        document.removeEventListener('focusin', handleFocusIn);
        document.removeEventListener('focusout', handleFocusOut);
        if (hideKeyboardTimeoutRef.current) {
          clearTimeout(hideKeyboardTimeoutRef.current);
        }
      };
    }
  }, []);

  const handleFocusIn = (event) => {
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
      if (hideKeyboardTimeoutRef.current) {
        clearTimeout(hideKeyboardTimeoutRef.current);
      }
      setIsVisible(true);
      activeElementRef.current = event.target;
    }
  };

  const handleFocusOut = (event) => {
    if (!event.relatedTarget || (event.relatedTarget.tagName !== 'INPUT' && event.relatedTarget.tagName !== 'TEXTAREA')) {
      hideKeyboardTimeoutRef.current = setTimeout(() => {
        setIsVisible(false);
        activeElementRef.current = null;
      }, 200); // 200ms delay before hiding the keyboard
    }
  };

  const simulateInput = useCallback((element, newValue) => {
    const nativeValueSetter = Object.getOwnPropertyDescriptor(
      element instanceof HTMLTextAreaElement
        ? window.HTMLTextAreaElement.prototype
        : window.HTMLInputElement.prototype,
      "value"
    ).set;
    nativeValueSetter.call(element, newValue);

    const inputEvent = new InputEvent('input', { bubbles: true });
    element.dispatchEvent(inputEvent);

    const changeEvent = new Event('change', { bubbles: true });
    element.dispatchEvent(changeEvent);
  }, []);

  /**
   * Ensure the active element does not lose focus after clicking Caps or Shift
   */
  const focusActiveElement = useCallback(() => {
    if (activeElementRef.current && activeElementRef.current !== document.body) {
      const activeEl = activeElementRef.current;
      // Method 1: Direct focus
      activeEl.focus();

      // Method 2: Delayed focus
      setTimeout(() => {
        activeEl.focus();
      }, 0);

      // Method 3: Force blur then focus
      activeEl.blur();
      activeEl.focus();

      // Method 4: Scroll into view then focus
      activeEl.scrollIntoView({behavior: 'smooth', block: 'nearest'});
      setTimeout(() => {
        activeEl.focus();
      }, 100);
    }
  }, []);

  const handleShiftButton = useCallback(() => {
    activeElementRef.current = document.activeElement;
    setLayoutName(prevLayout => prevLayout === 'default' ? 'shift' : 'default');

    // Clear any existing timeout
    if (layoutChangeTimeoutRef.current) {
      clearTimeout(layoutChangeTimeoutRef.current);
    }

    // Set a new timeout
    layoutChangeTimeoutRef.current = setTimeout(() => {
      focusActiveElement();
    }, 50);
  }, [focusActiveElement]);

  useEffect(() => {
    return () => {
      // Clean up the timeout on component unmount
      if (layoutChangeTimeoutRef.current) {
        clearTimeout(layoutChangeTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Attempt to refocus after a short delay
    const refocusTimeout = setTimeout(() => {
      focusActiveElement();
    }, 50);

    return () => clearTimeout(refocusTimeout);
  }, [layoutName, focusActiveElement]);

  const handleKeyPress = useCallback((button) => {
    if (button === '{shift}' || button === '{lock}') {
      handleShiftButton();
      return;
    }

    const activeElement = document.activeElement;
    if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')) {
      const isNumberInput = activeElement instanceof HTMLInputElement && activeElement.type === 'number';
      const currentValue = activeElement.value;
      let newValue;
      let newCursorPosition;

      if (button === '{tab}') {
        // Simulate tab key press
        const tabEvent = new KeyboardEvent('keydown', {
          bubbles: true,
          cancelable: true,
          key: 'Tab',
          shiftKey: false,
        });
        activeElement.dispatchEvent(tabEvent);

        if (!tabEvent.defaultPrevented) {
          // If the event wasn't prevented, manually move focus to the next element
          const allFocusableElements = Array.from(document.querySelectorAll('input, textarea, select, button, a[href], [tabindex]:not([tabindex="-1"])'));
          const currentIndex = allFocusableElements.indexOf(activeElement);
          const nextElement = allFocusableElements[currentIndex + 1] || allFocusableElements[0];
          nextElement.focus();
        }
        return;
      }

      if (isNumberInput) {
        if (button === '{bksp}') {
          newValue = currentValue.slice(0, -1);
        } else if (button === '{space}' || button === '{enter}') {
          return;
        } else {
          newValue = currentValue + button;
        }
      } else {
        const selectionStart = activeElement.selectionStart || 0;
        const selectionEnd = activeElement.selectionEnd || 0;

        if (button === '{bksp}') {
          if (selectionStart === selectionEnd) {
            newValue = currentValue.slice(0, selectionStart - 1) + currentValue.slice(selectionEnd);
            newCursorPosition = selectionStart - 1;
          } else {
            newValue = currentValue.slice(0, selectionStart) + currentValue.slice(selectionEnd);
            newCursorPosition = selectionStart;
          }
        } else if (button === '{space}') {
          newValue = currentValue.slice(0, selectionStart) + ' ' + currentValue.slice(selectionEnd);
          newCursorPosition = selectionStart + 1;
        } else if (button === '{enter}' && activeElement instanceof HTMLTextAreaElement) {
          newValue = currentValue.slice(0, selectionStart) + '\n' + currentValue.slice(selectionEnd);
          newCursorPosition = selectionStart + 1;
        } else if (button === '{enter}' && activeElement instanceof HTMLInputElement) {
          // Disable enter button
          return;
        } else {
          newValue = currentValue.slice(0, selectionStart) + button + currentValue.slice(selectionEnd);
          newCursorPosition = selectionStart + button.length;
        }
      }

      simulateInput(activeElement, newValue);

      if (!isNumberInput && 'setSelectionRange' in activeElement) {
        newCursorPosition = Math.max(0, Math.min(newCursorPosition, newValue.length));
        activeElement.setSelectionRange(newCursorPosition, newCursorPosition);
      }
    }
  }, [simulateInput, handleShiftButton]);

  const handleDrag = (e, ui) => {
    setPosition({ x: ui.x, y: ui.y });
  };

  if (!kioskMode || !isVisible) return null;

  return ReactDOM.createPortal(
    <Draggable
      position={position}
      onDrag={handleDrag}
      bounds={bounds}
    >
      <div style={{
        position: 'fixed',
        bottom: '20px',
        zIndex: 10000,
        width: `${keyboardWidth}px`,
        left: 0,
        right: 0,
        cursor: 'move',
        background: '#f0f0f0',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0,0,0,0.5)',
        padding: '10px',
      }}>
        <div style={{marginBottom: '10px', textAlign: 'center', cursor: 'move'}}>
          Drag here to move keyboard
        </div>
        <Keyboard
          keyboardRef={(r) => (keyboardRef.current = r)}
          layoutName={layoutName}
          onKeyPress={handleKeyPress}
          excludeFromLayout={{
            default: ["@", ".com", "{lock}", "{tab}", "{enter}"],
            shift: ["@", ".com", "{lock}", "{tab}", "{enter}"]
          }}
        />
      </div>
    </Draggable>,
    document.body
  );
};

export default OnScreenKeyboard;
