import Axios from 'axios';
const Utils = require('../utils/Utils');

let api = Axios;

const QueueCarouselStatuses = {
  WAITING: 'WAITING',
  WAIT_FOR_GATE: 'WAIT_FOR_GATE',
  CONFIRM_AT_GATE: 'CONFIRM_AT_GATE',
  DRIVE_ONTO_PALLET: 'DRIVE_ONTO_PALLET',
  READY_TO_STOW: 'READY_TO_STOW',
  STOWING: 'STOWING',
  SESSION_CANCELED: 'SESSION_CANCELED',
  READY_TO_RETRIEVE: 'READY_TO_RETRIEVE',
  RETRIEVING: 'RETRIEVING',
  READY_TO_LEAVE: 'READY_TO_LEAVE',
  RETRIEVED: 'RETRIEVED',
  RETRIEVE_CANCELED: 'RETRIEVE_CANCELED',
  NO_QUEUE_ITEM: 'NO_QUEUE_ITEM',
};

const QueueCarouselCommands = {
  AT_GATE: 'AT_GATE',
  STOW: 'STOW',
  CANCEL: 'CANCEL',
  RETRIEVE_TO_LEAVE: 'RETRIEVE_TO_LEAVE',
  RETRIEVE_TO_REPARK: 'RETRIEVE_TO_REPARK',
  LEAVE_INSTEAD: 'LEAVE_INSTEAD',
  STEAL_LOCK: 'STEAL_LOCK',
}

const init = (axiosApi) => {
  api = axiosApi;
};

const fetchQueue = async (locationId) => {
  try {
    const response = await api.get(`/admin/parking-queues/${locationId}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getQueueItem = async (id) => {
  try {
    const response = await api.get(`/admin/parking-queues/items/${id}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getGroupVehiclesWithSessions = async (id) => {
  try {
    const response = await api.get(`/admin/groups/${id}/vehicles-with-sessions`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

/**
 * Queue the vehicle for the given user, or the valet (for retrieval)
 * @param locationId
 * @param vehicleId
 * @param userId the driver, or null if the current user (valet) is retrieving the vehicle
 */
const queueVehicle = async (locationId, vehicleId, userId = null) => {
  try {
    const response = await api.post(`/admin/parking-queues/${locationId}`, {
      vehicleId,
      userId,
    });
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getSpotName = (spot) => {
  if (!spot) {
    return '';
  }
  const lot = spot?.parking_lot?.name;
  const zone = spot?.parking_zone?.name;
  return `${lot ? lot+':' : ''}${zone ? zone+':' : ''}${spot?.name}`;
}

const updateQueueItemParkingSpot = async (itemId, newSpotId) => {
  try {
    const response = await api.put(`/admin/parking-queues/items/${itemId}`, {
      spotId: newSpotId,
    });
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const updateQueueItemCarousel = async (itemId, newCarouselId) => {
  try {
    const response = await api.put(`/admin/parking-queues/items/${itemId}`, {
      carouselId: newCarouselId,
    });
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const deleteQueueItem = async (itemId) => {
  try {
    const response = await api.delete(`/admin/parking-queues/items/${itemId}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const parkQueueItemInSpot = async (itemId) => {
  try {
    const response = await api.post(`/admin/parking-queues/items/${itemId}/park-spot`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

/**
 * This function should be called repeatedly during carousel valet parking/retrieval (e.g. every 2 secs or so). The responses indicate
 * what to communicate to the valet, and what action, if any, is required.
 * @param itemId the queue item id
 * @param cmd an optional QueueCarouselCommands, either AT_GATE (if the server asked to confirm the car is waiting at the carousel gate), or
 * STOW (when the car is on the pallet and ready to stow)
 * @return one of the QueueCarouselStatuses
 */
const processCarouselQueueItem = async (itemId, cmd = null) => {
  try {
    const response = await api.post(`/admin/parking-queues/items/${itemId}/process-carousel`, { cmd });
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const retrieveQueueItemFromSpot = async (itemId) => {
  try {
    const response = await api.post(`/admin/parking-queues/items/${itemId}/retrieve-spot`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const ParkingQueueService = {
  init,
  fetchQueue,
  getQueueItem,
  getGroupVehiclesWithSessions,
  queueVehicle,
  getSpotName,
  updateQueueItemParkingSpot,
  updateQueueItemCarousel,
  deleteQueueItem,
  parkQueueItemInSpot,
  processCarouselQueueItem,
  retrieveQueueItemFromSpot,
  QueueCarouselStatuses,
  QueueCarouselCommands,
}

export default ParkingQueueService;
