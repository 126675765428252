import React from 'react';
import { useLocation } from 'react-router-dom';
import GridItem from 'components/Grid/GridItem.js';
import CardContent from '@mui/material/CardContent';
import makeStyles from '@mui/styles/makeStyles';
import GridContainer from 'components/Grid/GridContainer.js';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import CardHeader from '@mui/material/CardHeader';
import {QRCodeSVG} from 'qrcode.react';

const useStyles = makeStyles({
  inputLabel: {
    color: '#008BE8',
    font: 'normal normal bold 16px/19px Lato',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  sidetext: {
    color: '#324152',
    float: 'left',
    paddingLeft: '10px',
  },
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    paddingBottom: '5%',
    marginBottom: '5%',
  },
  media: {
    height: 140,
  },
});

export default function VehicleQR() {
  const classes = useStyles();
  const location = useLocation();
  const { title, token } = location.state;

  return (
    <GridContainer justify='center'>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <Paper elevation={5} className={classes.root}>
          <CardContent align="center">
            <CardHeader title={title} />
            <List>
              <QRCodeSVG size={200} value={token} />
            </List>
          </CardContent>
        </Paper>
      </GridItem>
    </GridContainer>
  );
}
