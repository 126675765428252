/* eslint-disable react/display-name */

import React from 'react';
import GitInfo from 'react-git-info/macro';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import {AuthContext} from '../Auth/AuthContext';

export const AboutComponent = () => {
  const gitInfo = GitInfo();
  const { roles, permissions } = React.useContext(AuthContext);
  const rows = [
    { name: 'Branch', val: gitInfo.branch },
    { name: 'Tags', val: gitInfo.tags },
    { name: 'Hash', val: gitInfo.commit.shortHash },
    { name: 'Date', val: moment(gitInfo.commit.date).utc().format('MM/DD/YYYY HH:mm:ss') },
    { name: 'Role', val: roles.map(r => r.name).join('\n') },
    { name: 'Permissions', val: permissions.join('\n') },
  ];

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ width: 300, borderCollapse: 'collapse', margin: 'auto' }}
        aria-label='simple table'
      >
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align='left'>
                <b>{row.name}</b>
              </TableCell>
              <TableCell align='left'>{row.val}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AboutComponent;
