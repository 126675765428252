import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import DashboardService from 'services/DashboardService';
import useAPI from 'utils/useAPI';
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';
import CustomLinearProgress from 'components/CustomLinearProgress/CustomLinearProgress.js';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';

const useStyles = makeStyles(styles);

export default function MainDashboard() {
  const classes = useStyles();
  const api = useAPI();
  const [, setuserCount] = React.useState('');
  const [, setvehicleCount] = React.useState('');
  const [, setparkedVehicleCount] = React.useState('');

  const fetchDashboardData = async () => {
    DashboardService.getDashboardData().then((res) => {
      setparkedVehicleCount(res.parked_vehicle);
      setvehicleCount(res.vehicle_count);
      setuserCount(res.user_count);
    });
  };
  React.useEffect(() => {
    DashboardService.init(api);
    fetchDashboardData();
  }, [api]);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color='success' icon>
              <CardIcon color='danger'>
                <LocalParkingIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Parked Vehicles - {'30'} {'/100 '}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify='center'>
                <GridItem xs={false}>{'0%'}</GridItem>
                <GridItem xs={8} sm={10} md={10} lg={10}>
                  <div style={{ marginTop: '10px' }}>
                    <CustomLinearProgress variant='determinate' color='primary' value={30} />
                  </div>
                </GridItem>
                <GridItem xs={2} sm={1} md={1} lg={1}>
                  <div>{'100%'}</div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color='success' icon>
              <CardIcon color='success'>
                <CollectionsBookmarkIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Subscribed Plans - {'40'} {'/100 '}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify='center'>
                <GridItem xs={false}>{'0%'}</GridItem>
                <GridItem xs={8} sm={10} md={10} lg={10}>
                  <div style={{ marginTop: '10px' }}>
                    <CustomLinearProgress variant='determinate' color='primary' value={40} />
                  </div>
                </GridItem>
                <GridItem xs={2} sm={1} md={1} lg={1}>
                  <div>{'100%'}</div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
