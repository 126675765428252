import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const Loading = () => (
  <div className='spinner'>
    <Box display='flex' m='auto' p={10} height={500} alignItems='center' justifyContent='center'>
      <CircularProgress size='3rem' />
    </Box>
  </div>
);

export default Loading;
