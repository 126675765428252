export const Permissions = {
  ACCESS_ADMIN_APP: 'access_admin_app',
  VIEW_USERS: 'view_users',
  VIEW_SURFACE_SPOTS: 'view_surface_spots',
  VIEW_CAROUSELS: 'view_carousels',
  VIEW_CAMERAS: 'view_cameras',
  VIEW_LOCATIONS: 'view_locations',
  VIEW_PARKING_SESSIONS: 'view_parking_sessions',
  VIEW_PARKING_QUEUE: 'view_parking_queue',
  QUEUE_VEHICLE: 'queue_vehicle',
  VIEW_EV_CHARGERS: 'view_ev_chargers',
  VIEW_RESERVATIONS: 'view_reservations',
  RECEIVE_ADMIN_NOTIFICATIONS: 'receive_admin_notifications',
  UPDATE_PARKING_SESSIONS: 'update_parking_sessions',
  PARK_OWN_VEHICLE: 'park_own_vehicle',
  PARK_ANY_VEHICLES: 'park_any_vehicles',
  UPDATE_OWN_USER: 'update_own_user', // except role
  ADD_UPDATE_USERS: 'add_update_users',
  ADD_UPDATE_OWN_VEHICLE: 'add_update_own_vehicle',
  ADD_UPDATE_VEHICLES: 'add_update_vehicles',
  SYSTEM_ADMINISTRATION: 'system_administration',
  SEND_SMS: 'send_sms',
  APPLY_DISCOUNT: 'apply_discount',
  PARKING_SUPPORT: 'parking_support'
}
