import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import UserService from 'services/UserService';
import useAPI from 'utils/useAPI';
import UserForm from './UserForm';
import ParkingSessions from 'views/Tables/ParkingSessions';
import VehicleTable from "../Vehicle/VehicleTable"
import Loading from '../../components/Loading';
import {AuthContext} from '../Auth/AuthContext';
import {Permissions} from '../Auth/Permissions';

export default function EditUser() {
  const api = useAPI();
  const { hasPermission } = useContext(AuthContext);
  const { id } = useParams();
  const [user, setUser] = React.useState('');

  const fetchUsers = async () => {
    await UserService.getUser(id).then((res) => {
      setUser(res);
    });
  };

  React.useEffect(() => {
    UserService.init(api);
    fetchUsers();
  }, [api]);

  if (user === '') {
    return <Loading />;
  }

  return (
    <GridContainer>
      <UserForm id={Number(id)} />
      <VehicleTable user={user}/>
      {hasPermission(Permissions.VIEW_PARKING_SESSIONS) &&
          <GridItem xs={12}>
            <ParkingSessions userId={Number(id)} />
          </GridItem>
      }
    </GridContainer>
  );
}
